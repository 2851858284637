<script>

import { library } from '@fortawesome/fontawesome-svg-core';
import { faPen, faArrowAltCircleRight, faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment } from '@fortawesome/free-solid-svg-icons';
library.add(faPen,faArrowAltCircleRight,faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment)
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import $ from 'jquery'; 


//import { VideoPlayer } from 'vue-video-player'

//general functions
import MixinGeneralFunct from '../mixins/GeneralFunctions';

import axios from 'axios';

export default {
	name: 'Alerts',
	mixins: [ MixinGeneralFunct ],
	components: {
		"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
	},
	props: ["myUserReg","myEventReg","myEventCounts"],
	data() {
		return {
			prefix:'',
						
			accData: [],
			secData: [],
			auth: [],
			menuItems: [],
			cookiesTime: 259200,
			
			eventIsRegistered:false,
			eventRegCode:'',
			selectedAccessCode:'',
			
			loadInterval:'',
			
			isLogged:true,
			
			alert:[],
			alertEdit:[],
			
			myEvent:[],
			
			
			optionsTypes:[],
			
			alertData: [],
			
			helpboxData: [],
			
			alertsCount:0,
			alerts:[],
			
			
			timeToRefresh:10000,
			
			firstLoading:true,
			tmpQuestions:[],
	
			countPages:0,
			selectedPage:null,
			selectedRecords:null,
			limit_from:0,
			limit_to:0,
			
			isSelectedQuestion:false,
			curSelectedQuestionID:'',
			
			selectedCol:'created',
			selectedSort:'desc',
		
			filterExtended:true,
			showFilter:false,
			
			options: {
				type: Object,
				default() {
					return {};
				}
			}
			
		}
	},
	
	mounted:function(){
		this.loadAlerts();
	},
	
	methods: {
				
		//zoznam otazok
		loadAlerts:function(){
			
			var self = this;
			axios.get('/app/includeData/Alerts/getAlertsData.php',{
				params:{
					//code:c,
					//selectedIds: this.selectedIds
				}
			})
			.then(function (response) {
				//console.log("loadAlerts");
				//console.log(response.data);
				
				//hide loader
				$('#preloader').fadeOut(200);
				
				self.alertsCount = response.data.basicDataCount;
				self.alerts = response.data.basicData;
				self.alertData = response.data.alertData;
				
				//self.myUser = response.data.myUser;
			
			})
				.catch(function (error) {
				console.log(error);
			});
		},
		
		//tu dopln type
		checkSave:function(){
			if(!this.alert.title){this.alert.titleError = true; }else{this.alert.titleError = false;}
			if(!this.alert.type){this.alert.typeError = true; }else{this.alert.typeError = false;}
			
			if(!this.alert.titleError && !this.alert.typeError){
				return true;
			}else{
				this.createMessageBoxTrans('warning','Alert','placeAlert');
				return false;
			}
		},
		
		//tu dopln type
		checkSaveEdit:function(){
			if(!this.alertEdit.title){this.alertEdit.titleError = true; }else{this.alertEdit.titleError = false;}
			if(!this.alertEdit.type){this.alertEdit.typeError = true; }else{this.alertEdit.typeError = false;}
			
			if(!this.alertEdit.titleError && !this.alertEdit.typeError){
				return true;
			}else{
				this.createMessageBoxTrans('warning','Alert','placeAlert');
				return false;
			}
		},
		
		//add alert
		newAlert:function(){
			if(this.checkSave()){
				var bodyFormData = new FormData();
				bodyFormData.set('myAlert',JSON.stringify(this.alert));
				
				var self = this;
				axios({
					method: 'post',
					url: '/app/includeData/Alerts/controlAlertAdd.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log('success2', response.data);
						if(response.data.resCode==1){
							self.loadAlerts();
							self.createMessageBoxTrans('success','Alert','recordWasAdded');
							self.alert.title = '';
							self.closeModalBoxSecure('myModalNewAlert');
						}else if(response.data.resCode==0){
							self.createMessageBoxTrans('warning','Alert','dataCantBeSaved');
						}else if(response.data.resCode==2){
							self.createMessageBoxTrans('danger','Alert','dataWasNotSaved');
						}else if(response.data.resCode==3){
							self.createMessageBoxTrans('danger','Alert','accessDenied');
						}
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
			}
		},
		
		//save alert
		saveAlert:function(){
			if(this.checkSaveEdit()){
				var bodyFormData = new FormData();
				//var dataAlert = JSON.stringify(this.alertEdit);
				bodyFormData.set('myAlert',JSON.stringify(this.alertEdit));
				
				var self = this;
				axios({
					method: 'post',
					url: '/app/includeData/Alerts/controlAlertSave.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log('saveAlert', response.data);
						if(response.data.resCode==1){
							self.loadAlerts();
							self.createMessageBoxTrans('success','Alert','dataWasSaved');
							//self.setCookie("creatorName", self.alertEdit.creatorName, self.cookiesTime);
							self.alertEdit = [];
							//self.countCharAlert = self.countCharQuestionLimit;
							self.closeModalBoxSecure('myModalEditAlert');
							//self.resetLoadingInterval();
						}else if(response.data.resCode==0){
							self.createMessageBoxTrans('warning','Alert','dataCantBeSaved');
						}else if(response.data.resCode==2){
							self.createMessageBoxTrans('danger','Alert','dataWasNotSaved');
						}else if(response.data.resCode==3){
							self.createMessageBoxTrans('danger','Alert','accessDenied');
						}
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
			}
		},
				
		//remove alert
		removeAlert:function(alertId){
			
			if(alertId){
				
				var bodyFormData = new FormData();
				bodyFormData.set('alertId',alertId);
				
				var self = this;
				axios({
					method: 'post',
					url: '/app/includeData/Alerts/controlAlertRemove.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log('removeAlert', response.data);
						if(response.data.resCode==1){
							self.loadAlerts();
							self.createMessageBoxTrans('success','Alert','dataWasRemoved');
							self.closeModalBoxSecure('myModalRemoveAlert');
						}else if(response.data.resCode==0){
							self.createMessageBoxTrans('warning','Alert','dataCantBeRemoved');
						}else if(response.data.resCode==2){
							self.createMessageBoxTrans('danger','Alert','dataWasNotRemoved');
						}else if(response.data.resCode==3){
							self.createMessageBoxTrans('danger','Alert','accessDenied');
						}
						
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
				
			}
			
		},
		
		//zmena stavu
		changeState:function(alertId,state){
						
			var bodyFormData = new FormData();
			bodyFormData.set('alertId',alertId);
			bodyFormData.set('state',state);
			
			var self = this;
			axios({
				method: 'post',
				url: '/app/includeData/Alerts/controlAlertChangeState.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log('changeState', response.data);
					if(response.data.resCode==1){
						self.createMessageBoxNoTrans('success','Alert','OK');
						self.loadAlerts();
					}else if(response.data.resCode==0){
						self.createMessageBoxTrans('warning','Alert','dataCantBeSaved');
					}else if(response.data.resCode==2){
						self.createMessageBoxTrans('danger','Alert','dataWasNotSaved');
					}else if(response.data.resCode==3){
						self.createMessageBoxTrans('danger','Alert','accessDenied');
					}
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
	
		},
				
		//asi pojde prec
		//radenie zoznamu
		sortMe:function(s,t) {
			//if s == current sort, reverse
			//if(s === this.selectedCol) {
			//this.selectedSort = 'desc';
			//  this.selectedSort = this.selectedSort==='asc'?'desc':'asc';
			//}
			this.selectedCol = s;
			this.selectedSort = t;
			
			this.setCookie("cSortCol", s, this.cookiesTime);
			this.setCookie("cSortDir", t, this.cookiesTime);
		},
		
		//messagebox v1.1
		createMessageBoxNoTrans: function(kind,object,message) {
			var content = "";
			content += "<div class='alert alert-"+kind+"' role='alert'>";
			content += message;
			content += "</div>";
			
			var rand = this.getRandomInt(600000);
			$('#msg').append("<div class='msg"+rand+"'></div>");
			$('.msg'+rand).html("").append(content).fadeIn(100).delay(6000).fadeOut(300);
		},
		
		//messagebox v1.1
		createMessageBoxTrans: function(kind,object,message) {
			var content = "";
			content += "<div class='alert alert-"+kind+"' role='alert'>";
			content += this.$t('message.'+message);
			content += "</div>";
			
			var rand = this.getRandomInt(600000);
			$('#msg').append("<div class='msg"+rand+"'></div>");
			$('.msg'+rand).html("").append(content).fadeIn(100).delay(6000).fadeOut(300);
		},
		
		//modal new alert
		loadModalNewAlert:function(){
			
			var self = this;
			axios({
				method: 'post',
				url: '/app/includeData/Alerts/getDefaultData.php'
			})
			.then(response => {
				if (response.data.error) {
					console.log('error', response.data.error)
				} else {
					//console.log('success2', response.data);
					self.alert = response.data.alert;
					self.optionsTypes = response.data.optionsTypes;
					$('#myModalNewAlert').addClass('modal');
					$('#myModalNewAlert').modal({backdrop: "static"});
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
		},
		
		//modal edit alert
		loadModalEditAlert:function(alertId){
			
			var bodyFormData = new FormData();
			bodyFormData.set('alertId',alertId);
			
			var self = this;
			axios({
				method: 'post',
				url: '/app/includeData/Alerts/getAlertData.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log('loadModalEditAlert', response.data);
					if(response.data.resCode==1){
						self.alertEdit = response.data.alert;
						self.optionsTypes = response.data.optionsTypes;
						$('#myModalEditAlert').addClass('modal');
						$('#myModalEditAlert').modal({backdrop: "static"});
					}else if(response.data.resCode==3){
						self.createMessageBoxTrans('danger','Alert','accessDenied');
					}
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
		},
		
		//modal remove alert
		loadModalRemoveAlert:function(alertId){
			
			var bodyFormData = new FormData();
			bodyFormData.set('alertId',alertId);
			
			
			var self = this;
			axios({
				method: 'post',
				url: '/app/includeData/Alerts/getAlertData.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log('success2', response.data);
					if(response.data.resCode==1){
						self.alertEdit = response.data.alert;
						$('#myModalRemoveAlert').addClass('modal');
						$('#myModalRemoveAlert').modal({backdrop: "static"});
					}else if(response.data.resCode==3){
						self.createMessageBoxTrans('danger','Alert','accessDenied');
					}
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
		},
		
		// zatvaranie modalbox
		closeModalBoxSecure:function(myform){
			$("#"+myform).modal("hide");
			$("#"+myform).removeClass("modal");
		}
				
	},	
	
			
	watch: {
		myUserReg() {
			this.loadAlerts();
		},
		myEventReg() {
			this.loadAlerts();
		}
	},

	computed:{
		
	},
	
}






</script>

<template>
	
	<div id="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
	
	<section v-if="alertData.resCode==1" class="">
	
		<div v-on:click="loadModalNewAlert()" :title="$t('message.AddAlert')" class="addAlertBtn box-flex"><font-awesome-icon :icon="['fas', 'pen']" /></div>
		
						
		<section id="obsah">
			<div class="container">
				
				<div class="row">
				
					<div class="col-12 col-md-10 offset-md-1">
							
						<div class="row">
							<div class="col-6 col-md-6 row-menu-radenie">
								
							</div>
							
							<div class="col-6 col-md-6 row-menu-radenie text-right">
								<span class="q-count">{{ $t('message.Count') }}: {{alerts.length}}</span>
							</div>
						</div>
						
						
						<div class="row">
							<div v-for="(item) in alerts" v-bind:key="item"  class="col-12 row-question" :class="{'new':item.isNewQuestion,'published':item.state==1,'unpublished':item.state==2,'selectedQ':item.selected==1}">
								
								<div v-if="item.state==2" class="row">
									<div class="col-12">
										<p class="q-unpublished">{{ $t('message.Unpublished') }}</p>
									</div>
								</div>
								
								<div class="row">
									<div class="col-4 col-md-3">
									
										<p><span class="alertType" :class="item.typeName" >{{ $t('message.'+item.typeName) }}</span></p>
									</div>
									
									<div class="col-8 col-md-9">
									
										<p>{{item.title}}</p>
									</div>
								</div>
								
								<div class="row">
									<div class="col-7 col-md-6">
										<span class="q-time"><span v-if="item.diffTimeName!='nowTime'">{{ $t('message.before') }}</span> {{ $tc('message.'+item.diffTimeName,item.diffTime) }}</span>
									</div>
									<div class="col-5 col-md-6 text-right">
										
										<span v-if="item.isAllowedChangeState" class="togglebutton">
											<label>
												<input v-model='item.isActive' type="checkbox" v-on:change="changeState(item.id,item.state)">
												<span class="toggle"></span>
											</label>
										</span>
									
										<span v-if="item.isAllowedEdit" class="q-edit" v-on:click="loadModalEditAlert(item.id)" :title="$t('message.Edit')"><font-awesome-icon :icon="['fas', 'edit']" /></span>
										
										<span v-if="item.isAllowedEdit" class="q-remove" v-on:click="loadModalRemoveAlert(item.id)" :title="$t('message.Remove')"><font-awesome-icon :icon="['fas', 'trash-alt']" /></span>
										
									</div>
								</div>
								
								
							</div>
						</div>
										
								
								
					</div>
				
				</div>
				
			</div>
		</section>
		
	</section>
	
	<section v-else-if="alertData.resCode==2">
		<div class="container">
		<div class="row">
			<div class="col-12 text-center">
				<h5 style="padding: 50px 0;">Pre zobrazenie tejto sekcie musíte byť prihlásený ako správca a mať pridelené príslušné oprávnenia</h5>
			</div>
		</div>
		</div>
	</section>
		
	<section v-else-if="alertData.resCode==3">
		<div class="container">
		<div class="row">
			<div class="col-12 text-center">
				<h5 style="padding: 50px 0;">Chýbajú vstupné údaje</h5>
			</div>
		</div>
		</div>
	</section>
			
	<section v-else-if="alertData.resCode==0">
		<div class="container">
		<div class="row">
			<div class="col-12 text-center">
				<h5 style="padding: 50px 0;">K záznamu nemáte prístup</h5>
			</div>
		</div>
		</div>
	</section>
	
	
	
	
	
	<div id="myModalEditAlert" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog" style="">
			<div class="modal-content">
				
				<div class="modal-header">
					<h6 class="modal-title"><span>{{ $t('message.editAlert') }}</span></h6>
					<div class="modal-button-close" v-on:click="closeModalBoxSecure('myModalEditAlert')" ><font-awesome-icon :icon="['fas', 'times']" /></div>
					
				</div>
		
				<div class="modal-body">
					<div class="row">
						<div class="col-12">
						
						
							<div class="form-row">
								<div class="form-group form-group-no-margin col-md-12 col-12">
									<textarea class="form-control form-control-sm" :class="{'is-invalid':alertEdit.titleError}" rows="3" v-model="alertEdit.title" v-on:keyup="checkSaveEdit()" ></textarea>
									
								</div>
							</div>
							
							<div class="row">
								<div class="col-8">
									
									<span v-show="alertEdit.titleError" class="error-alert-text text-danger">{{ $t('message.EnterAlert') }}</span>
								</div>
								
							</div>
							
							<div class="row">
								<div class="col-md-8 col-8">
									
									<select v-model='alertEdit.type' class="form-control form-control-sm" :class="{'is-invalid':alert.typeError}" v-on:change="checkSaveEdit()">
										<option  value="">{{ $t('message.selectType') }}</option>
										<option v-for="option in optionsTypes" :key="option" :value="option.value">{{ $t('message.'+option.title) }}</option>
									</select>
									<div v-show="alert.typeError" class="error-input">Vyberte typ</div>
									
								</div>
								<div class="col-md-4 col-4 text-center">
									<button v-if="alertEdit.id" :disabled="alertEdit.titleError" v-on:click="saveAlert()" class="btn btn-warning btn-sm">{{ $t('message.Save') }}</button>
								</div>
								
							</div>
							
						
									
						</div>
					</div>	
				</div>
				
			</div>
		</div>
	</div>
	
	<div id="myModalRemoveAlert" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog" style="">
			<div class="modal-content">
				
				<div class="modal-header">
					<h6 class="modal-title"><span>{{ $t('message.doYouWantRealyRemove') }}?</span></h6>
					<div class="modal-button-close" v-on:click="closeModalBoxSecure('myModalRemoveAlert')" ><font-awesome-icon :icon="['fas', 'times']" /></div>
				</div>
		
				<div class="modal-body">
					<div class="row">
						<div class="col-12">
						
							<div class="row">
								<div class="col-md-12 col-12">
									{{alertEdit.title}}
								</div>
							</div>
							
							<div class="row">
								<div class="col-12 text-right">
									<button v-if="alertEdit.id" v-on:click="removeAlert(alertEdit.id)" class="btn btn-danger btn-sm">{{ $t('message.Remove') }}</button>
								</div>
							</div>

						</div>
					</div>	
				</div>
				
			</div>
		</div>
	</div>
	
	
	
	<div id="myModalNewAlert" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog" style="">
			<div class="modal-content">
				
				<div class="modal-header">
					<h6 class="modal-title"><span>{{ $t('message.Warning') }}</span></h6>
					<div class="modal-button-close" v-on:click="closeModalBoxSecure('myModalNewAlert')" ><font-awesome-icon :icon="['fas', 'times']" /></div>
					
				</div>
		
				<div class="modal-body">
					<div class="row">
						<div class="col-12">
						
						
							<div class="form-row">
								<div class="form-group form-group-no-margin col-md-12 col-12">
									<textarea class="form-control form-control-sm" :class="{'is-invalid':alert.titleError}" rows="3" v-model="alert.title" v-on:keyup="checkSave()" ></textarea>
									
								</div>
							</div>
							
							<div class="row">
								<div class="col-8">
									
									<span v-show="alert.titleError" class="error-alert-text text-danger">{{ $t('message.EnterNotice') }}</span>
								</div>
								<div class="col-4 text-right">
									
								</div>
							</div>
							
							<div class="row">
								<div class="col-md-8 col-8">
																						
									<select v-model='alert.type' class="form-control form-control-sm" :class="{'is-invalid':alert.typeError}" v-on:change="checkSave()">
										<option  value="">{{ $t('message.selectType') }}</option>
										<option v-for="option in optionsTypes" :key="option" :value="option.value">{{ $t('message.'+option.title) }}</option>
									</select>
									<div v-show="alert.typeError" class="error-input">Vyberte typ</div>
													
									
								</div>
								<div class="col-md-4 col-4 text-center">
									<button :disabled="alert.titleError" v-on:click="newAlert()" class="btn btn-success btn-sm">{{ $t('message.Send') }}</button>
								</div>
								
							</div>
							
						</div>
					</div>	
				</div>
				
			</div>
		</div>
	</div>
	
	
</template>


