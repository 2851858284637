<script>

//import { library } from '@fortawesome/fontawesome-svg-core';
//import { faPen, faArrowAltCircleRight, faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment } from '@fortawesome/free-solid-svg-icons';
//library.add(faPen,faArrowAltCircleRight,faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment)
//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import $ from 'jquery'; 


//import { VideoPlayer } from 'vue-video-player'

//general functions
import MixinGeneralFunct from '../mixins/GeneralFunctions';

import axios from 'axios';

export default {
	name: 'PageWasNotFound',
	mixins: [ MixinGeneralFunct ],
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
	},
	props: ["myUserReg","myEventReg","myEventCounts"],
	data() {
		return {
			event:[],
		}
	},
	
	mounted:function(){
		
		//this.loadDefaultValues();
		$('#preloader').fadeOut(200);
	},
	
	methods: {
		
		//na fontende aj preview aj tabula, zober povodne idcka a idcka po aktualizacii a tie naviac zobraz na par sekund inou farbou, alebo ako nove.
		
		//default data
		
		loadDefaultValues:function(){
			var self = this;
			axios.get('/app/includeData/Uvod/getDefaultData.php')
			.then(function (response) {
				//console.log("loadDefData");
				//console.log(response.data);
				
				//hide loader
				
				
				
				self.event = response.data.event;
				
			})
				.catch(function (error) {
				console.log(error);
			});
		}	
		
	},	
	
			
	watch: {
		//myEventReg() {
		//	this.loadDefaultValues();
		//}
	},

	computed:{
		
	},
	
}

</script>

<template>
	
	<div id="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
	
		
			<section id="registracia" class="box-flex">
				<div class="container">
					<div class="row">
						<div class="col-12 box-flex">
							<h2>Stránka nebola nájdená</h2>
						</div>
					</div>
						
						
					
					
				</div>
			</section>
		

</template>


