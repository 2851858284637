<script>

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import Popper from 'popper.js'
import 'bootstrap/dist/js/bootstrap.min.js'
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars, faVideo, faUserFriends, faListAlt, faFileAlt, faGlobe, faPlayCircle, faHome, faPen, faArrowAltCircleRight, faSignOutAlt, faSignInAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faEye, faEyeSlash, faUser } from '@fortawesome/free-solid-svg-icons';
library.add( faBars, faVideo, faUserFriends, faListAlt, faFileAlt, faGlobe, faPlayCircle, faHome, faPen, faArrowAltCircleRight, faSignOutAlt, faSignInAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faEye, faEyeSlash, faUser)
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import $ from 'jquery';

import myLangs from './components/LangSwitch.vue'

//import myQuestions from './components/Questions.vue'

//general functions
import MixinGeneralFunct from './mixins/GeneralFunctions';

import axios from 'axios';

var sha512 = require('js-sha512');

export default {
	name: 'App',
	mixins: [ MixinGeneralFunct ],
	components: {
		myLangs,
		//myQuestions,
		"font-awesome-icon":FontAwesomeIcon
	},
	
	directives: {
		focus: {
			// directive definition
			mounted(el) {
				el.focus()
			}
		}
	},
	data() {
		return {
			prefix:'',
			user:[],
			checkRegisterUser:null,
			checkRegisterEvent:null,
			eventCounts:[{streams:0,documents:0,expo:0,program:0,speakers:0,records:0}],
			
			cookiesTime: 259200,
			event:[],
			selectedAccessCode:null,
			alerts:[],
			
			menuIsOpened:false,
			
			t0:0,
			t1:0,
		
		}
	},
	
	mounted:function(){
		this.loadDefaultValues();
		
	},
	
	methods: {
		
		//default data
		loadDefaultValues:function(){
			var self = this;
			axios.get('/app/includeData/Common/getDefaultDataLogin.php')
			.then(function (response) {
				//console.log("loadDefData");
				//console.log(response.data);
				
				self.user = response.data.user;
				self.event = response.data.event;
				self.eventCounts = response.data.eventItemCounts;
				
				
				if(self.event.isReg){
					self.resetLoadingInterval();
					self.resetAlertInterval();
				}
				
			})
				.catch(function (error) {
				console.log(error);
			});
		},
		
		//modal new question
		loadModalLogin:function(){
			//console.log('ok');
			
			axios({
				method: 'post',
				url: '/app/includeData/Common/getDefaultDataLogin.php'
			})
			.then(response => {
				if (response.data.error) {
					console.log('error', response.data.error)
				} else {
					//console.log('success2', response.data);
					self.user = response.data.user;
					
					$('#myModalLogin').addClass('modal');
					$('#myModalLogin').modal({backdrop: "static"});
				}
			})
			.catch(error => {
				console.log(error.response)
			});
			
		},
		
		checkData:function(){
			if(this.user.email && this.user.password){
				return true;
			}else{
				this.createMessageBoxTrans('warning',null,'fillInAllData');
				return false;
			}
		},
		
		//messagebox v1.1
		createMessageBoxTrans: function(kind,object,message) {
			var content = "";
			content += "<div class='alert alert-"+kind+"' role='alert'>";
			content += this.$t('message.'+message);
			content += "</div>";
			
			var rand = this.getRandomInt(600000);
			$('#msg').append("<div class='msg"+rand+"'></div>");
			$('.msg'+rand).html("").append(content).fadeIn(100).delay(6000).fadeOut(300);
		},
		
		// zatvaranie modalbox
		closeModalBoxSecure:function(myform){
			$("#"+myform).modal("hide");
			$("#"+myform).removeClass("modal");
		},
		
		//zobrazi heslo
		showPswd: function(){
			this.user.showInput = this.user.showInput===true?false:true;
		},
		
		//registracia event
		regEvent:function(){
			if(this.selectedAccessCode){
			
				var self = this;
				axios.get('/app/includeData/Common/controlEventReg.php',{
					params:{
						selectedAccessCode:this.selectedAccessCode
					}
				})
				.then(function (response) {
					
					//console.log(response.data);
					if(response.data.resCode==1){
						self.setCookie("eventRegCode", self.selectedAccessCode, self.cookiesTime);
						self.setCookie("eventReg", 1, self.cookiesTime);
						self.event.isReg=true;
						self.loadDefaultValues();
						
						self.resetLoadingInterval();
						self.resetAlertInterval();
						
						
						self.$router.push({ path: self.$route.path })
						//if(self.$route.path == '/'){
							location.reload(); 
						//}else{
							//location.reload(); 
							//self.$router.push({ path: '/' })
						//}

					}else if(response.data.resCode==2){
						self.createMessageBoxTrans('warning','Event','udalostPreZadanyKlucNeexistuje');
						
					}else if(response.data.resCode==3){
						
						self.createMessageBoxTrans('danger','Event','pristupBolStornovany');
					}
										
				})
					.catch(function (error) {
					console.log(error);
				});
			
			}
		},
		
		//logout event
		logoutEvent:function(){
		
			var self = this;
			axios.get('/app/includeData/Common/controlEventUnReg.php')
			.then(function (response) {
				//console.log("loadDefData");
				//console.log(response.data);
				
				
				if(response.data.isOut){
					clearInterval(self.alertsInterval);
					clearInterval(self.checkInterval);
					self.event.isReg=false;
					
					self.remCookie("eventRegCode");
					self.remCookie("eventReg");
					self.remCookie("creatorName");
					
					self.selectedAccessCode='';
					
					self.loadOnRegisterUser();
					self.loadOnRegisterEvent();
					
					
					self.closeModalBoxSecure('myModalLogOutEvent');
//					self.$router.push({ path: '/' })
				}
				
				//tu pokracuj
				
				
				/*self.user = response.data.user;
				
				self.loadDefaultValues();
				
				self.setCookie("eventRegCode", '', 0);
				self.setCookie("eventReg", '', 0);
				self.setCookie("creatorName", '', 0);
				self.eventIsRegistered=false;
				self.loadDefaultValues();
				self.selectedAccessCode='';
				clearInterval(self.loadInterval);*/
				//setTimeout(function(){ window.location.href = window.location.protocol+'//'+window.location.hostname+'/'; }, 100);
			})
				.catch(function (error) {
				console.log(error);
			});
		
		},
		
		
		
		//prihlasenie admina
		signMe:function(){
							
			if(this.checkData()){
				//var basicData = JSON.stringify(this.basicData);
				var bodyFormData = new FormData();
				//bodyFormData.set('data',basicData);
				bodyFormData.set('email',this.user.email);
				bodyFormData.set('p',sha512(this.user.password));
				//console.log(this.user.password);
				//console.log(sha512(this.user.password));
				this.user.password = '';
				//console.log(this.user.password);
				
				var self = this;
				axios({
					method: 'post',
					url: '/app/includeData/Common/controlLogin.php',
					data: bodyFormData
				})
				.then(response => {
					if(response.data.error){
							console.log('error', response.data.error)
					}else{
						//console.log(response.data);
						
						if(response.data.resCode == 3){
							self.createMessageBoxTrans("warning",null,'badInputData');
						}else if(response.data.resCode == 1){
							self.createMessageBoxTrans("success",null,'successLogin');
							
							self.loadOnRegisterUser(); //pre Stream.vue
							//self.loadOnRegisterEvent(); //pre Uvod.vue
							
							self.loadDefaultValues();
							self.closeModalBoxSecure('myModalLogin');
							//setTimeout(function(){ window.location.href = window.location.protocol+'//'+window.location.hostname+'/'; }, 800);
						}else if(response.data.resCode == 2){
							self.createMessageBoxTrans("danger",null,'UnsuccessLogin');
						}else if(response.data.resCode == 0){
							self.createMessageBoxTrans("danger",null,'badLoginRefreshForm');
						}else if(response.data.resCode == 4){
							self.createMessageBoxTrans("danger",null,'AccountIsNotActivated');
						}else if(response.data.resCode == 5){
							self.createMessageBoxTrans("danger",null,'AccountIsBlocked');
						}else if(response.data.resCode == 6){
							self.createMessageBoxTrans("danger",null,'AccountExpired');
						}else if(response.data.resCode == 7){
							self.createMessageBoxTrans("danger",null,'NumberOfLoginsExceeded');
						}else if(response.data.resCode == 8){
							self.createMessageBoxTrans("danger",null,'EmailDoesNotExist');
						}else if(response.data.resCode == 9){
							self.createMessageBoxTrans("danger",null,'BadPasswordLength');
						}
						
						/*if(response.data.resCode==0 || response.data.resCode>1){
							var lineDrawingd = anime({
								targets: '#login-box',
								translateX: 20,
								duration: 50,
								easing: 'linear',
								direction: 'alternate',
								loop: 4
							});	
							this.loadDefaultValues();
						}*/
						
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
			
			}
			
		},
		
		//odhlasenie reg ucastnika
		logOutMe:function(){
			
			this.t0 = performance.now()
			
			//loader
			$('#preloader').show();
			
			
			var self = this;
			axios.get('/app/includeData/Common/controlLogout.php')
			.then(function (response) {
				//console.log("loadDefData");
				//console.log(response.data);
				self.t1 = performance.now()
				
				//console.log("ExTime " + (self.t1 - self.t0) + " milliseconds.")
				
				//self.setCookie("eventRegCode", '', 0);
				//self.setCookie("eventReg", '', 0);
				//self.setCookie("creatorName", '', 0);
				//self.eventIsRegistered=false;
				
				self.selectedAccessCode='';
				
				self.user = response.data.userData;
				
				//self.loadQuestionsOfMyQuestions();
				
				self.loadOnRegisterUser();
				//self.loadOnRegisterEvent();
				
				//hide loader
				$('#preloader').fadeOut(200);
				
				//self.$router.push({ path: '/' })
				//setTimeout(function(){ window.location.href = window.location.protocol+'//'+window.location.hostname+'/'; }, 100);
			})
				.catch(function (error) {
				console.log(error);
			});
		},
		
		
		//kontrola platnosti registracie eventu
		checkReg:function(){
			
			var self = this;
			axios.get('/app/includeData/Common/checkConn.php')
			.then(function (response) {
				//console.log("checkReg");
				//console.log(response.data);
				
				if(!response.data.isReg){
					self.logoutEvent();
					self.loadOnRegisterEvent();
					//setTimeout(function(){ window.location.href = window.location.protocol+'//'+window.location.hostname+'/'; }, 300);
				}

			})
				.catch(function (error) {
				console.log(error);
			});
		},
		
		//reset check interval
		resetLoadingInterval:function(){
			clearInterval(this.checkInterval);
			this.checkInterval = setInterval(this.checkReg, 10000);
		},
		
		
		//reset alert interval
		resetAlertInterval:function(){
			clearInterval(this.alertsInterval);
			this.alertsInterval = setInterval(this.loadAlerts, 8000);
		},
		
		//funkcia zabezpeci nacitanie otazok bez refreshu stranky
		//loadQuestionsOfMyQuestions:function(){
		//	if(this.myFunkcionToLoadQ){
		//		this.myFunkcionToLoadQ=false;
		//	}else{
		//		this.myFunkcionToLoadQ=true;
		//	}
		//},
		
		loadOnRegisterUser:function(){
			if(this.checkRegisterUser){
				this.checkRegisterUser=false;
			}else{
				this.checkRegisterUser=true;
			}
		},
		
		//funkcia zabezpeci nacitanie registrovaneho obsahu eventu
		//Uvod.vue
		loadOnRegisterEvent:function(){
			if(this.checkRegisterEvent){
				this.checkRegisterEvent=false;
			}else{
				this.checkRegisterEvent=true;
			}
		},
		
		//alerts data
		loadAlerts:function(){
			var self = this;
			axios.get('/app/includeData/Alerts/getValidAlerts.php')
			.then(function (response) {
				//console.log("loading Alerts");
				//console.log(response.data);
				
				self.alerts = response.data.basicData;
				//console.log("self.alerts");
				//console.log(response.data.basicData);
				
				//ziskam uz vymazanie cookies
				var x = self.getCookie("closedAlerts"+response.data.eventId);   
				if (x != ''){
					var oldCookies = x.split(",");
					//console.log("oldCookies")
					//console.log(oldCookies)
					
					
					for(var i = 0; i < oldCookies.length; i++) {
					
						//pole helperIDS je id vsetkych vystrah, pomocne pole, aby som vedel vyhladavat v tomto poli, podla vyskytu vymazem z hlavneho pola cely zaznam
						//po vymazani ho vzdy aktualizujem, aby mi sedeli idcka
						var helperIDS = Array();
						for(var k = 0; k < self.alerts.length; k++){
							helperIDS.push(self.alerts[k].id);
						}
						//console.log('helperIDS')
						//console.log(helperIDS)
					
						//console.log("hladam " + oldCookies[i])
						//console.log("v");
						//console.log(helperIDS);
						
						var a = helperIDS.indexOf(parseInt(oldCookies[i]));
						//console.log(a)
						
						if(a > -1){
							//console.log("mazem id " + oldCookies[i]);
							//console.log("mazem a " + a);
							self.alerts.splice(a, 1);
						}
					}
					
				}
				
				
				
			})
				.catch(function (error) {
				console.log(error);
			});
		},
		
		//close alert
		closeAlert:function(alertId,eventId,index){
			//console.log(alertId);
			var idska = Array();
			var oldCookies = Array();
			var x = this.getCookie("closedAlerts"+eventId);   
			if (x == '') {
				//cookies este neexistuje
				//console.log('cookies este neexistuje');
				idska.push(alertId);
				//console.log(idska);
				this.setCookie("closedAlerts"+eventId, idska.join(), this.cookiesTime);
			}else{
				//cookies uz existuje
				//console.log('cookies uz existuje');
				//console.log(x);
				oldCookies = x.split(",");
				//console.log(oldCookies);
				//var ids = Array();
				for(var i = 0; i < oldCookies.length; i++) {
					idska.push(parseInt(oldCookies[i]));
				}
				//console.log(x);
				idska.push(parseInt(alertId));
				this.setCookie("closedAlerts"+eventId, idska.join(), this.cookiesTime);
			}
			
			//vymazem aktualny zaznam
			this.alerts.splice(index, 1);
			
		},
		
		//zobrazenie menu
		showHideMenu:function(){
			if(this.menuIsOpened){
				this.menuIsOpened=false;
			}else{
				this.menuIsOpened=true;
			}
		},
		
		//modal logout Event
		loadModalLogOutEvent:function(){
			$('#myModalLogOutEvent').addClass('modal');
			$('#myModalLogOutEvent').modal({backdrop: "static"});
		},
		
	},
	
	created(){
		if(localStorage.Lang!=null) this.$i18n.locale=localStorage.Lang; 
	},
}
</script>


<template>

<div id="msg"></div>


<div class="container-fluid">
			
	<div class="row">
				
		<div class="col-10">
			<div class="mainMenu">
				<router-link to="/"><font-awesome-icon :icon="['fas', 'home']" /></router-link>
				<!--<router-link to="/streams" v-if="event.isReg && eventCounts.streams>0">{{ $t('message.LiveStream') }}</router-link>
				<router-link to="/documents" v-if="event.isReg && eventCounts.documents>0">{{ $t('message.Documents') }}</router-link>
				<router-link to="/exhibitors" v-if="event.isReg && eventCounts.expo>0">{{ $t('message.Expo') }}</router-link>
				<router-link to="/program" v-if="event.isReg && eventCounts.program>0">{{ $t('message.Program') }}</router-link>
				<router-link to="/speakers" v-if="event.isReg && eventCounts.speakers>0">{{ $t('message.Speakers') }}</router-link>
				<router-link to="/records" v-if="event.isReg && eventCounts.records>0">{{ $t('message.Records') }}</router-link>
				<router-link to="/alerts" v-if="user.isReg">{{ $t('message.Alerts') }}</router-link>
				<router-link to="/check-tickets-qr" v-if="user.isReg">{{ $t('message.CheckTickets') }}</router-link>
				<router-link to="/check-tickets-line" v-if="user.isReg">{{ $t('message.CheckTickets') }}</router-link>-->
			
				<span v-if="!user.isReg"  v-on:click="loadModalLogin()"><font-awesome-icon :icon="['fas', 'sign-in-alt']" /></span>
				
				
				<span  v-if="user.isReg" class="burger" v-on:click="showHideMenu()">
					<span v-if="!menuIsOpened"><font-awesome-icon :icon="['fas', 'bars']" /></span>
					<span v-else><font-awesome-icon :icon="['fas', 'times']" /></span>
					
					<span class="logOut" v-on:click="logOutMe()"><button class="btn btn-danger btn-sm">{{ $t('message.LogOut') }} <font-awesome-icon :icon="['fas', 'sign-out-alt']" /></button></span>
				</span>
				
				
			</div>
		</div>
		
		<myLangs v-model:propActLang="$i18n.locale"></myLangs>
				
	</div>
	
	<div  v-if="user.isReg" class="menu" :class="{'opened':menuIsOpened}">
		<ul class='main-menu'>
			<li>
				<router-link to="/alerts" v-if="user.isReg" @click="showHideMenu">{{ $t('message.Alerts') }}</router-link>
			</li>	
			
			<li>
				<router-link to="/check-tickets-qr" v-if="user.isReg" @click="showHideMenu">{{ $t('message.CheckTickets') }} - kamera</router-link>
			</li>	
			
			<li>
				<router-link to="/check-tickets-line" v-if="user.isReg" @click="showHideMenu">{{ $t('message.CheckTickets') }} - čítačka</router-link>
			</li>
			
			<li>
				<router-link to="/check-tickets-manual" v-if="user.isReg" @click="showHideMenu">{{ $t('message.CheckTickets') }} - ručne</router-link>
			</li>
			
			<li> 
				<span class="userMenu" v-if="user.isReg" v-on:click="logOutMe();showHideMenu()"><button class="btn btn-danger btn-sm">{{ $t('message.LogOut') }} <font-awesome-icon :icon="['fas', 'sign-out-alt']" /></button></span>
			</li>
		</ul>
	</div>
	
</div>


<section v-if="event.isReg" id="menuBottom">
<div class="container-fluid">
	<div class="row">
		<div class="col-12">
				<div class="row">
									
					<div class="col-2 box-0 box-flex text-center">
						<div v-if="eventCounts.streams>0" class="full">
							<router-link to="/streams" v-if="event.isReg" class="myItem"><div class="icon"><font-awesome-icon :icon="['fas', 'play-circle']" /></div><div class="description">Live</div></router-link>
						</div>
						<div v-else class="empty">
							<div class="icon"><font-awesome-icon :icon="['fas', 'play-circle']" /></div>
							<div class="description">Live</div>
						</div>
					</div>
				
				
					<div class="col-2 box-0 box-flex text-center">
						<div v-if="eventCounts.documents>0" class="full">
							<router-link to="/documents" v-if="event.isReg" class="myItem"><div class="icon"><font-awesome-icon :icon="['fas', 'file-alt']" /></div><div class="description">{{ $t('message.Documents') }}</div></router-link>
						</div>
						<div v-else class="empty">
							<div class="icon"><font-awesome-icon :icon="['fas', 'file-alt']" /></div>
							<div class="description">{{ $t('message.Documents') }}</div>
						</div>
					</div>
					
					
					<div class="col-2 box-0 box-flex text-center">
						<div v-if="eventCounts.expo>0" class="full">
							<router-link to="/exhibitors" v-if="event.isReg" class="myItem"><div class="icon"><font-awesome-icon :icon="['fas', 'globe']" /></div><div class="description">{{ $t('message.Expo') }}</div></router-link>
						</div>
						<div v-else class="empty">
							<div class="icon"><font-awesome-icon :icon="['fas', 'globe']" /></div>
							<div class="description">{{ $t('message.Expo') }}</div>
						</div>
					</div>
				
				
					<div class="col-2 box-0 box-flex text-center">
						<div v-if="eventCounts.program>0" class="full">
							<router-link to="/program" v-if="event.isReg" class="myItem"><div class="icon"><font-awesome-icon :icon="['fas', 'list-alt']" /></div><div class="description">{{ $t('message.Program') }}</div></router-link>
						</div>
						<div v-else class="empty">
							<div class="icon"><font-awesome-icon :icon="['fas', 'list-alt']" /></div>
							<div class="description">{{ $t('message.Program') }}</div>
						</div>
					</div>
				
					
				
					<div class="col-2 box-0 box-flex text-center">
						<div v-if="eventCounts.speakers>0" class="full">
							<router-link to="/speakers" v-if="event.isReg" class="myItem"><div class="icon"><font-awesome-icon :icon="['fas', 'user-friends']" /></div><div class="description">{{ $t('message.Speakers') }}</div></router-link>
						</div>
						<div v-else class="empty">
							<div class="icon"><font-awesome-icon :icon="['fas', 'user-friends']" /></div>
							<div class="description">{{ $t('message.Speakers') }}</div>
						</div>
					</div>
					
				
					<div class="col-2 box-0 box-flex text-center">
						<div v-if="eventCounts.records>0" class="full">
							<router-link to="/records" v-if="event.isReg" class="myItem"><div class="icon"><font-awesome-icon :icon="['fas', 'video']" /></div><div class="description">{{ $t('message.Records') }}</div></router-link>
						</div>
						<div v-else class="empty">
							<div class="icon"><font-awesome-icon :icon="['fas', 'video']" /></div>
							<div class="description">{{ $t('message.Records') }}</div>
						</div>
					</div>
					
					
				</div>	
				
				
		</div>
			
	</div>
</div>
</section>

	<section v-if="event.isReg" id="hlavicka">
		<div class="container-fluid">
			<div class="row">
				<div class="col-12 col-sm-8 col-md-8 box-flex-left">
					<h5>{{event.title}} <a v-if="event.urlQuestions && user.isReg" :href="event.urlQuestions" class="urlToQuestions" target="_blank">{{$t('message.Questions')}}</a></h5>
				</div>
				
				<div class="col-12 col-sm-4 col-md-4 logout text-right">
					<span v-on:click="loadModalLogOutEvent()" class="btn btn-sm btn-danger q-logout">{{ $t('message.LoginToAnotherEvent') }} </span>
				</div>
			</div>
		</div>
	</section>

	<div>
		<router-view :myUserReg="checkRegisterUser" :myEventReg="checkRegisterEvent" :myEventCounts="eventCounts"></router-view>
	</div>

	<section v-if="!event.isReg" id="registracia" class="box-flex">
		<div class="container-fluid">
			
			<div class="row">
				
				<div class="col-12 col-md-6 box-flex">
					<div class="row box-0">
						<div class="input-group col-12">
							<input v-focus class="form-control" v-on:keyup.enter="regEvent" v-model="selectedAccessCode" :placeholder="$t('message.tuZadajtePristupovyKod')">
							<span title="Odoslať" v-on:click="regEvent()" class="my-sent-btn"><font-awesome-icon :icon="['fas', 'arrow-alt-circle-right']" /></span>
						</div>
					</div>
				</div>
				
				<div class="col-12 col-md-6 box-flex">
					
					<svg version="1.1" id="Layer_1_1_" class="intro-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="1200px" height="800px" viewBox="0 0 1200 800" enable-background="new 0 0 1200 800" xml:space="preserve">
<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="599.938" y1="23" x2="599.938" y2="523.6757">
	<stop  offset="0" style="stop-color:#C1E5F4"/>
	<stop  offset="1" style="stop-color:#A3BEE3"/>
</linearGradient>
<path fill="url(#SVGID_1_)" d="M1099.938,405.747c0,39.721-7.998,78.02-22.847,114.008c-0.489,1.196-7.732,13.065-8.242,14.254
	c-50.059,3.692-240.014-4.233-475.035-4.233c-218.632,0-397.669,8.044-462.768,4.738c-0.733-1.689-7.88-13.813-8.581-15.519
	c-14.636-35.77-22.528-73.81-22.528-113.248c0-210.115,223.858-380.436,499.995-380.436
	C876.08,25.311,1099.938,195.632,1099.938,405.747z"/>
<g>
	<g>
		<rect x="345.839" y="112.567" fill="#FFFFFF" width="515.287" height="292.288"/>
		<rect x="334.26" y="98.297" width="538.444" height="14.27"/>
		<rect x="334.26" y="403.65" width="538.444" height="14.27"/>
	</g>
	<g>
		<linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="195.7163" y1="269.6787" x2="262.2813" y2="269.6787">
			<stop  offset="0" style="stop-color:#A3BEE3"/>
			<stop  offset="1" style="stop-color:#C1E5F4"/>
		</linearGradient>
		<path fill="url(#SVGID_2_)" d="M250.438,313.367l-43.938,6.898c-3.024,0-10.784-9.349-10.784-12.373v-83.325
			c0-3.023,2.451-5.475,5.475-5.475h49.247c3.024,0,11.843,6.716,11.843,9.739l-6.368,79.061
			C255.914,310.916,253.462,313.367,250.438,313.367z"/>
		<path fill="#62667C" d="M257.702,320.631h-49.248c-3.023,0-5.475-2.452-5.475-5.475v-83.325c0-3.024,2.452-5.475,5.475-5.475
			h49.248c3.023,0,5.475,2.451,5.475,5.475v83.325C263.177,318.179,260.726,320.631,257.702,320.631z"/>
		<g>
			<circle fill="#434556" cx="233.078" cy="290.787" r="24.64"/>
			<circle fill="#1E1E1E" cx="233.078" cy="290.787" r="7.181"/>
		</g>
		<path fill="#434556" d="M245.767,251.353H220.39c-1.233,0-2.233-1-2.233-2.233v-11.857c0-1.234,1-2.234,2.233-2.234h25.376
			c1.233,0,2.233,1,2.233,2.234v11.857C248,250.353,247,251.353,245.767,251.353z"/>
	</g>
	<g>
		
			<linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="1010.6191" y1="269.6787" x2="1077.1836" y2="269.6787" gradientTransform="matrix(-1 0 0 1 2014.7793 0)">
			<stop  offset="0" style="stop-color:#A3BEE3"/>
			<stop  offset="1" style="stop-color:#C1E5F4"/>
		</linearGradient>
		<path fill="url(#SVGID_3_)" d="M949.438,313.367l43.938,6.898c3.023,0,10.784-9.349,10.784-12.373v-83.325
			c0-3.023-2.451-5.475-5.475-5.475h-49.247c-3.024,0-11.843,6.716-11.843,9.739l6.367,79.061
			C943.963,310.916,946.414,313.367,949.438,313.367z"/>
		<path fill="#62667C" d="M942.175,320.631h49.247c3.023,0,5.475-2.452,5.475-5.475v-83.325c0-3.024-2.451-5.475-5.475-5.475
			h-49.247c-3.024,0-5.475,2.451-5.475,5.475v83.325C936.7,318.179,939.15,320.631,942.175,320.631z"/>
		<g>
			<circle fill="#434556" cx="966.798" cy="290.787" r="24.64"/>
			<circle fill="#1E1E1E" cx="966.798" cy="290.787" r="7.181"/>
		</g>
		<path fill="#434556" d="M954.11,251.353h25.376c1.233,0,2.233-1,2.233-2.233v-11.857c0-1.234-1-2.234-2.233-2.234H954.11
			c-1.233,0-2.233,1-2.233,2.234v11.857C951.877,250.353,952.877,251.353,954.11,251.353z"/>
	</g>
</g>
<g>
	<g>
		<path fill="#ECF2FA" d="M511.819,694.451l-80.966-1.477c-7.386-0.135-13.437-5.906-13.921-13.277l-2.665-40.537
			c-0.499-7.594-6.891-13.446-14.499-13.276l-175.097-4.611L58.812,753.781c-1.546,2.587,0.318,5.872,3.332,5.872h412.412
			c3.754,0,7.356-1.485,10.019-4.132l37.005-36.782C530.484,709.887,524.374,694.68,511.819,694.451z"/>
		<g>
			<g>
				<g>
					<g>
						<path fill="#FDB9B1" d="M378.072,653.27c-0.545-0.309-1.091-0.654-1.637-1.019c-1.445-0.964-11.872-15.168-12.08-21.47
							c0.036-4.75-0.146-9.451-0.26-14.241c-0.246-10.357-0.427-20.932,0.01-31.326c0.457-10.87-1.503-32.306-7.455-34.9
							c-4.115-1.795-7.307-2.296-10.731,0.907c-6.083,5.689-6.165,14.263-6.438,21.927c-0.396,11.105,2.042,23.984,5.69,34.525
							c1.89,5.463,5.55,20.554,4.238,28.692c-0.538,3.323-1.452,6.372-2.081,9.817c-1.091,5.979,4.272,4.419,8.689,6.291
							c5.468,2.317,9.552,6.776,15.912,7.104c3.737,0.191,9.106-2.348,13.014-4.352C382.607,654.422,380.289,654.528,378.072,653.27
							z"/>
						
							<linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="1633.8984" y1="612.4316" x2="1673.293" y2="612.4316" gradientTransform="matrix(0.997 -0.0778 0.0778 0.997 -1329.1896 167.778)">
							<stop  offset="0" style="stop-color:#6B73FF"/>
							<stop  offset="1" style="stop-color:#000DFF"/>
						</linearGradient>
						<path fill="url(#SVGID_4_)" d="M378.36,653.111c-0.626-0.008-1.38-0.496-1.926-0.86c-1.466,1.318-2.378,1.81-4.678,0.537
							c-2.241-1.239-4.467-3.495-6.415-5.028c-4.73-3.727-9.853-9.064-16.682-7.645c-0.45,1.978-0.949,3.975-1.332,6.067
							c-1.091,5.979,4.272,4.419,8.69,6.291c5.468,2.317,7.868,7.722,14.227,8.048c3.737,0.192,15.144-2.101,16.989-5.869
							C386.013,651.846,381.145,653.143,378.36,653.111z"/>
						<path fill="#F8DEBB" d="M352.666,648.242c6.104,2.571,5.849,2.849,8.273,4.626c2.289,1.679,5.327,6.215,7.837,7.479
							c-1.446-0.274-1.613-0.282-6.223-1.629c-6.001-1.755-1.136-4.551-11.441-8.345c-1.063-0.391-3.679-0.978-3.679-0.978
							C346.964,647.107,352.191,647.966,352.666,648.242z"/>
						<path fill="#634541" d="M347.279,648.22c1.553-1.224,3.5-0.52,5.386,0.022c1.121,0.321,3.813,1.233,4.252,1.703
							c-2.772,3.52-1.734,10.031-1.608,11.644c-3.02,1.707-3.338,0.902-4.241,0.317C350.07,657.024,346.776,648.313,347.279,648.22z
							"/>
					</g>
					<g>
						<path fill="#FDB9B1" d="M384.868,662.339c-0.483-0.398-0.962-0.833-1.438-1.285c-1.258-1.198-9.087-16.983-8.208-23.227
							c0.853-4.674,1.481-9.336,2.192-14.075c1.539-10.245,3.178-20.692,5.396-30.857c2.32-10.63,4.074-32.082-1.342-35.662
							c-3.746-2.476-6.804-3.519-10.728-0.952c-6.97,4.56-8.525,12.991-10.113,20.494c-2.3,10.872-2.112,23.978-0.331,34.989
							c0.922,5.706,1.933,21.202-0.759,28.994c-1.101,3.181-2.526,6.027-3.738,9.313c-2.103,5.702,3.448,5.087,7.479,7.69
							c4.988,3.224,8.245,8.318,14.453,9.733c3.648,0.832,9.375-0.746,13.568-2.048C389.138,664.254,386.836,663.96,384.868,662.339
							z"/>
						
							<linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="1652.0703" y1="494.6006" x2="1691.4648" y2="494.6006" gradientTransform="matrix(0.9955 0.0948 -0.0948 0.9955 -1242.6947 6.0973)">
							<stop  offset="0" style="stop-color:#6B73FF"/>
							<stop  offset="1" style="stop-color:#000DFF"/>
						</linearGradient>
						<path fill="url(#SVGID_5_)" d="M385.18,662.231c-0.615-0.114-1.274-0.726-1.749-1.178c-1.671,1.047-2.654,1.373-4.701-0.275
							c-1.994-1.606-3.8-4.211-5.454-6.057c-4.02-4.484-8.147-10.623-15.119-10.399c-0.784,1.871-1.619,3.752-2.355,5.749
							c-2.103,5.702,3.448,5.087,7.479,7.69c4.988,3.224,6.423,8.96,12.632,10.375c3.648,0.832,15.279,0.534,17.744-2.86
							C392.936,662.301,387.917,662.742,385.18,662.231z"/>
						<path fill="#F8DEBB" d="M360.705,653.018c5.572,3.582,5.272,3.812,7.354,5.979c1.966,2.048,4.179,7.039,6.435,8.716
							c-1.378-0.519-1.541-0.556-5.851-2.675c-5.61-2.761-0.336-4.679-9.836-10.188c-0.979-0.568-3.457-1.596-3.457-1.596
							C355.283,650.92,360.285,652.663,360.705,653.018z"/>
						<path fill="#634541" d="M355.403,652.069c1.74-0.938,3.537,0.09,5.302,0.948c1.049,0.51,3.104,1.78,3.457,2.319
							c-3.013,2.067-3.385,9.808-3.538,11.417c-3.268,1.162-3.053,0.181-3.841-0.551
							C356.638,661.223,354.891,652.075,355.403,652.069z"/>
					</g>
					<g>
						<g>
							<path d="M411.582,599.666c-6.381,5.181-13.056,10.673-16.912,18.103c-4.299,8.282-4.498,17.954-5.42,27.046
								c-2.081,20.501-4.107,41.009-5.895,61.538c-0.303,3.476,5.122,3.455,5.422,0c1.637-18.805,3.476-37.59,5.372-56.369
								c0.927-9.18,0.714-19.68,4.579-28.21c3.462-7.644,10.33-13.109,16.688-18.273C418.125,601.3,414.269,597.483,411.582,599.666
								L411.582,599.666z"/>
						</g>
						<g>
							<path d="M313.458,599.666c-6.381,5.181-13.056,10.673-16.912,18.103c-4.299,8.282-4.498,17.954-5.42,27.046
								c-2.081,20.501-4.107,41.009-5.895,61.538c-0.303,3.476,5.122,3.455,5.422,0c1.637-18.805,3.476-37.59,5.372-56.369
								c0.926-9.18,0.714-19.68,4.578-28.21c3.463-7.644,10.33-13.109,16.689-18.273C320.001,601.3,316.145,597.483,313.458,599.666
								L313.458,599.666z"/>
						</g>
						<g>
							<path d="M414.012,603.36c4.339,2.334,4.755,7.813,5.154,12.218c0.552,6.093,1.102,12.185,1.684,18.274
								c1.167,12.204,2.386,24.404,3.51,36.612c0.317,3.446,5.743,3.479,5.422,0c-1.336-14.514-2.811-29.014-4.165-43.526
								c-0.829-8.883,0.449-23.248-8.87-28.261C413.675,597.025,410.934,601.705,414.012,603.36L414.012,603.36z"/>
						</g>
						<g>
							<path d="M325.706,603.36c4.34,2.334,4.755,7.813,5.155,12.218c0.552,6.093,1.102,12.185,1.684,18.274
								c1.167,12.204,2.386,24.404,3.51,36.612c0.317,3.446,5.743,3.479,5.422,0c-1.336-14.514-2.81-29.014-4.164-43.526
								c-0.829-8.883,0.448-23.248-8.871-28.261C325.37,597.025,322.628,601.705,325.706,603.36L325.706,603.36z"/>
						</g>
						<g>
							<path fill="#434556" d="M410.851,590.162l-76.31,7.359c-7.144-0.052-16.89,3.262-21.769,7.4l-17.083,9.4
								c-5.194,4.406-0.83,16.277,10.119,20.032c14.038,4.814,33.719,5.6,57.235,1.388c7.22-1.293,19.536-5.626,24.73-10.033
								l23.653-20.065C419.023,600.717,417.995,590.215,410.851,590.162z"/>
							<path fill="#62667C" d="M407.794,589.599l-72.786-0.532c-7.143-0.052-16.89,3.261-21.769,7.4l-15.428,13.088
								c-5.195,4.407-2.485,12.59,8.464,16.345c14.037,4.814,33.719,5.6,57.235,1.388c7.22-1.294,19.536-5.626,24.73-10.033
								l23.653-20.065C416.773,593.049,414.938,589.651,407.794,589.599z"/>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path fill="#FDB9B1" d="M316.447,468.07c0.445,7.605-6.48,1.702-6.81-0.81s0.317-4.5,2.044-4.714
									C313.408,462.334,316.299,465.541,316.447,468.07z"/>
								<path fill="#FED0CA" d="M343.285,460.949c-0.505,7.669-4.697,21.675-11.334,22.318c-14.56,1.409-13.115-8.6-14.185-13.326
									c-0.253-1.134-0.574-2.411-1.144-3.943c-1.986-5.305-3.723-14.537-0.973-19.155c1.387-2.336,3.748-4.275,6.642-4.502
									c1.286,0.018,7.307-1.298,12.771,1.759C340.526,447.15,343.866,451.972,343.285,460.949z"/>
								<path fill="#FDB9B1" d="M344.856,464.498c0,0.145-0.009,0.298-0.045,0.47v0.009c-0.072,0.389-0.244,0.822-0.479,1.283
									c-0.561,1.121-1.527,2.323-2.531,3.001c-1.41,0.958-2.892,0.867-3.416-1.988c-0.054-0.28-0.09-0.578-0.126-0.913
									c-0.235-2.386,1.862-5.748,3.561-6.154c0.1-0.027,0.19-0.045,0.29-0.045c0.352-0.01,0.668,0.054,0.949,0.198
									c0.081,0.036,0.163,0.082,0.244,0.127c0.642,0.415,1.093,1.211,1.337,2.241v0.027c0,0.009,0,0.009,0.009,0.018
									C344.775,463.287,344.838,463.874,344.856,464.498z"/>
								<path fill="#F7AEA9" d="M312.748,492.063c4.276-1.846,6.47-7.086,26.691-0.708c-2.396-3.321-3.32-9.344-2.794-21.545
									c0.188-4.357-1.571,0-9.543,0.874c-4.723,0.518-12.416-3.575-11.667-0.285C318.043,481.85,314.918,486.998,312.748,492.063z
									"/>
								
									<linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="1602.8594" y1="453.4834" x2="1638.3955" y2="453.4834" gradientTransform="matrix(1 0 0 1 -1294.8955 0)">
									<stop  offset="0" style="stop-color:#B85D40"/>
									<stop  offset="1" style="stop-color:#EF7432"/>
								</linearGradient>
								<path fill="url(#SVGID_6_)" d="M337.903,437.451c-2.857-3.153-6.804-5.688-11.81-5.251
									c-7.383,0.645-11.766,2.989-14.661,6.005c-7.01,7.303-1.828,26.181,1.862,30.848c1.697,2.146,5.34,5.763,10.692,5.763
									c13.832-1.961,12.844-5.005,17.175-13.228C345.886,452.619,342.759,442.074,337.903,437.451z"/>
							</g>
							
								<linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="1598.0947" y1="599.2617" x2="1633.5713" y2="599.2617" gradientTransform="matrix(0.9969 -0.0781 0.0781 0.9969 -1332.1315 18.8162)">
								<stop  offset="0" style="stop-color:#6B73FF"/>
								<stop  offset="1" style="stop-color:#000DFF"/>
							</linearGradient>
							<path fill="url(#SVGID_7_)" d="M307.953,493.206c0.321-0.09,5.939-6.396,16.204-7.2c9.091-0.713,16.706,3.486,19.343,6.73
								L307.953,493.206z"/>
						</g>
						
							<linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="1593.4102" y1="589.3984" x2="1701.4502" y2="589.3984" gradientTransform="matrix(1 0 0 1 -1294.8955 0)">
							<stop  offset="0" style="stop-color:#6B73FF"/>
							<stop  offset="1" style="stop-color:#000DFF"/>
						</linearGradient>
						<path fill="url(#SVGID_8_)" d="M375.184,551.08c-4.872,3.368-9.616-14.325-28.813,0.309
							c-13.08,9.971-36.88,36.643-42.757,42.707c-9.759,11.35-5.732,30.831,14,37.229c4.958,1.607,24.772,2.524,31.061,1.594
							c5.449-0.806,15.01-3.11,22.523-7.935c4.583-2.943,12.327-12.571,20.594-23.908c6.837-9.377,15.282-3.219,14.738-33.596
							C406.332,556.454,389.908,540.898,375.184,551.08z"/>
						
							<linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="1592.9043" y1="526.7578" x2="1592.9043" y2="604.7227" gradientTransform="matrix(0.9999 -0.0145 0.0145 0.9999 -1300.8541 -4.4044)">
							<stop  offset="0" style="stop-color:#F8E388"/>
							<stop  offset="1" style="stop-color:#EF7432"/>
						</linearGradient>
						<path fill="url(#SVGID_9_)" d="M288.031,515.632c-3.83,17.511-1.741,27.256-0.758,40.766
							c0.394,5.426,0.668,21.244,11.039,20.794c9.03-0.392,12.909-12.861,13.785-18.383c1.684-10.619,2.368-21.515,2.055-32.162
							c-0.236-8.047-5.226-23.217-14.609-27.245C295.984,497.874,290.119,506.091,288.031,515.632z"/>
						<linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="330.8354" y1="613.626" x2="330.8354" y2="487.6924">
							<stop  offset="0" style="stop-color:#F8E388"/>
							<stop  offset="1" style="stop-color:#EF7432"/>
						</linearGradient>
						<path fill="url(#SVGID_10_)" d="M303.02,604.057c-5.395-7.495,4.448-27.08,3.152-35.612
							c-3.104-20.426-3.992-19.616-6.152-27.839c-2.092-7.964-9.179-27.643-3.228-35.803c14.212-19.489,32.981-22.879,53.807-7.331
							c12.841,9.586,9.255,20.644,6.611,39.363c-2.647,18.739-0.561,15.247-0.988,19.698c-2.561,26.731,22.078,40.954,4.787,49.927
							C346.099,614.196,313.482,618.591,303.02,604.057z"/>
						<linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="360.8052" y1="497.0361" x2="360.8052" y2="577.4336">
							<stop  offset="0" style="stop-color:#F8E388"/>
							<stop  offset="1" style="stop-color:#EF7432"/>
						</linearGradient>
						<path fill="url(#SVGID_11_)" d="M372.457,555.36c0.009,2.604-0.09,5.378-0.425,8.062c-0.795,6.381-2.919,12.21-7.944,13.782
							c-0.009,0-0.018,0.01-0.027,0.01c-1.301,0.397-2.44,0.243-3.452-0.353c-0.795-0.452-1.509-1.175-2.151-2.115
							c-2.784-4.04-4.167-11.911-4.961-18.392c-0.172-1.382-0.316-2.702-0.443-3.913c-0.136-1.292-0.253-2.449-0.353-3.416
							c-0.063-0.587-0.118-1.094-0.172-1.519c-0.045-0.325-0.082-0.605-0.126-0.822c-2.566-13.619-2.982-21.753-3.235-33.321
							c-0.082-3.832,0.208-6.841,0.732-9.164c0.569-2.521,1.428-4.247,2.386-5.368c0.063-0.081,0.136-0.153,0.208-0.226
							c1.148-1.238,2.44-1.654,3.579-1.555c0.876,0.063,1.672,0.425,2.232,0.949c5.667,5.224,8.803,11.495,10.457,18.129
							c0.786,3.136,1.392,6.471,1.844,9.869c0.832,6.118,1.193,12.463,1.329,18.283c0.027,1.201,0.262,3.696,0.416,6.805
							c0.027,0.769,0.054,1.572,0.081,2.413C372.438,554.104,372.457,554.729,372.457,555.36z"/>
						<path opacity="0.2" fill="#ECF2FA" d="M370.604,525.998c-0.19,1.12-5.182-13.951-16.23-23.398
							c-0.99-0.847-3.678-0.651-3.678-1.79c0-0.117,1.771-2.078,1.797-2.204c0-0.019,0.009-0.036,0.009-0.055
							c0.244-1.139,1.464-2.015,2.675-2.494c0.29,0.335,0.587,0.66,0.895,0.994c0.876,0.063,1.672,0.425,2.232,0.949
							c5.667,5.224,8.803,11.495,10.457,18.129C369.546,519.265,370.152,522.6,370.604,525.998z"/>
						<linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="292.7847" y1="470.4043" x2="358.2588" y2="470.4043">
							<stop  offset="0" style="stop-color:#1E1E1E"/>
							<stop  offset="1" style="stop-color:#63322B"/>
						</linearGradient>
						<path fill="url(#SVGID_12_)" d="M358.259,499.22c-1.048,0.822-2.142,1.591-3.271,2.287c-0.163,0.108-0.326,0.207-0.488,0.307
							c-1.482,0.895-3.019,1.69-4.6,2.386c-0.813,0.371-1.636,0.705-2.476,1.021c-2.874-3.886-3.588-5.359-4.203-6.271
							c0.389,2.195,1.04,4.753,1.274,7.274c-5.206,1.6-10.719,2.423-16.132,2.702c-0.633,0-1.166-11.911-1.672-15.029
							c-0.452,4.338-1.681,15.138-2.413,15.147c-4.537,0.018-8.92-0.316-12.906-0.858c-0.605-0.082,1.455-9.128,1.527-9.526
							c-0.705,0.886-4.076,9.083-4.482,9.083l-0.163-0.027c-0.913-0.153-1.799-0.316-2.639-0.487
							c-0.09-0.019,0.262-0.877,0.75-2.232c0.976-2.738,2.503-7.511,2.079-11.56c-0.298,2.865-1.889,7.284-3.172,10.249
							c-0.795,1.861-1.464,3.154-1.618,3.118c-2.729-0.624-5.061-1.311-6.859-1.998c-1.555-0.587-2.711-1.175-3.371-1.698
							c-0.615-0.488-0.805-0.922-0.488-1.266c14.008-14.984,10.962-36.159,10.294-42.377c-0.723-6.778,1.545-13.71,6.019-19.268
							c0.498-0.624,1.021-1.221,1.573-1.808c5.811-6.154,13.846-8.017,20.84-5.604c4.808,2.043,11.107,7.944,11.839,15.779
							c0.488,5.233,0.813,9.914,1.139,14.162v0.027c0,0.009,0,0.009,0.009,0.018c0.054,0.741,0.108,1.474,0.163,2.196v0.009
							c0.434,5.251,0.922,9.824,1.834,13.981c1.41,6.38,3.805,11.775,8.531,17.099c0.29,0.335,0.587,0.66,0.895,0.994
							C356.758,497.773,357.481,498.497,358.259,499.22z"/>
					</g>
					<g>
						<g>
							<path d="M375.44,605.321c0.683,6.982-0.131,15.605,2.56,22.183c4.282,10.467,15.32,0.139,19.856-3.877
								c2.618-2.318-1.229-6.142-3.834-3.834c-1.913,1.692-8.042,8.995-10.877,6.02c-0.914-0.959-0.856-4.045-0.996-5.225
								c-0.602-5.072-0.79-10.185-1.287-15.267C380.526,601.88,375.1,601.845,375.44,605.321L375.44,605.321z"/>
						</g>
						<g>
							<path d="M282.864,605.321c0.741,6.6,1.759,13.433,4.019,19.706c1.412,3.921,4.604,7.148,9.042,5.635
								c3.285-1.119,1.875-6.358-1.441-5.229c-3.675,1.253-5.98-18.176-6.198-20.112
								C287.902,601.893,282.475,601.854,282.864,605.321L282.864,605.321z"/>
						</g>
						<path fill="#62667C" d="M287.317,609.716c22.988,14.672,74.687,13.093,92.278,0.499c1.455-1.042,4.266-2.407,4.035-4.11
							l-5.766-42.544c-0.989-7.295-8.273-15.155-15.671-13.146c-18.591,5.048-54.682,7.609-69.011-0.502
							c-6.672-3.777-15.864,8.701-14.875,15.997l5.867,40.646C284.404,608.26,285.808,608.753,287.317,609.716z"/>
						<path fill="#434556" d="M286.902,610.414c21.929,13.647,73.382,13.17,90.163,1.455c1.388-0.969,2.913-1.298,2.693-2.882
							l-6.48-41.506c-0.943-6.786-7.892-14.098-14.95-12.229c-17.734,4.696-54.722,5.745-68.391-1.8
							c-6.365-3.514-12.478,7.663-11.535,14.449l5.5,39.573C284.123,609.06,285.462,609.519,286.902,610.414z"/>
					</g>
				</g>
				<path opacity="0.2" fill="#ECF2FA" d="M346.646,478.958c-0.027,0.09-0.054,0.181-0.082,0.271
					c-1.048-4.23-1.464-8.641-2.232-12.97c-0.108-0.642-2.038-23.985-14.521-29.407c-12.813-5.566-20.229,3.181-20.563,3.344
					c0.498-0.624,1.021-1.221,1.573-1.808c5.811-6.154,13.846-8.017,20.84-5.604c4.808,2.043,11.107,7.944,11.839,15.779
					c0.488,5.233,0.813,9.914,1.139,14.162v0.027c0,0.009,0,0.009,0.009,0.018c0.126,0.516,0.189,1.103,0.208,1.727
					c0,0.145-0.009,0.298-0.045,0.47v0.009C345.245,470.228,345.733,474.801,346.646,478.958z"/>
			</g>
			<g>
				<g>
					<g>
						
							<linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="-482.5571" y1="657.2197" x2="-447.3618" y2="657.2197" gradientTransform="matrix(1 0 0 1 684.0332 0)">
							<stop  offset="0" style="stop-color:#6B73FF"/>
							<stop  offset="1" style="stop-color:#000DFF"/>
						</linearGradient>
						<path fill="url(#SVGID_13_)" d="M203.725,647.228c-2.466,1.397-2.122,13.327-2.24,16.561
							c-0.135,3.667,1.275,7.851,5.328,7.728c15.568-0.473,10.85-6.145,15.18-6.598c1.833-0.191,0.453,0.646,2.369,0.361
							c2.565-0.382,6.813-2.423,10.437-7.388c2.299-3.15,3.037-11.886-1.025-12.936c-2.802-0.726-6.233,2.803-15.687-1.396
							C213.814,641.664,206.308,644.313,203.725,647.228z"/>
						<path fill="#1E1E1E" d="M203.841,651.811c2.918,3.65,12.508,1.711,15.097-1.46c2.332-2.856,2.784-7.483,3.403-11.626
							c0.567-3.792-0.447-5.465-4.469-6.389c-3.265-0.751-9.267-0.521-12.064,1.588
							C204.108,635.206,202.119,649.656,203.841,651.811z"/>
						
							<linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="433.4814" y1="598.0293" x2="470.8267" y2="598.0293" gradientTransform="matrix(1 0 0 1 -235.0093 0)">
							<stop  offset="0" style="stop-color:#6B73FF"/>
							<stop  offset="1" style="stop-color:#000DFF"/>
						</linearGradient>
						<path fill="url(#SVGID_14_)" d="M235.67,556.922c1.071,6.475-3.985,34.237-5.823,54.016
							c-0.837,9.011-1.445,23.719-1.811,32.756c-6.641,4.921-22.724,5.743-29.563,0.421c-0.049-6.266,1.266-23.383,1.564-29.622
							c0.377-7.871-0.877-15.71-0.442-23.605c0.528-9.59,2.309-20.475,6.38-29.271c3.781-8.166,10.396-13.116,19.71-13.314
							C229.088,548.229,234.662,550.829,235.67,556.922z"/>
					</g>
					<g>
						
							<linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="1544.4443" y1="663.502" x2="1590.8193" y2="663.502" gradientTransform="matrix(1 0 0 1 -1294.8955 0)">
							<stop  offset="0" style="stop-color:#6B73FF"/>
							<stop  offset="1" style="stop-color:#000DFF"/>
						</linearGradient>
						<path fill="url(#SVGID_15_)" d="M252.079,653.685c-2.465,1.397-2.121,13.327-2.24,16.56c-0.135,3.668-1.394,6.461,2.643,6.84
							c15.591,1.465,10.24-5.683,14.57-6.135c1.833-0.192,2.784,1.585,4.7,1.3c2.564-0.382,5.336-0.769,7.781-1.573
							c5.34-1.756,8.203-2.093,12.379-5.89c2.511-2.283,6.909-7.178,1.265-7.774c-4.348-0.459-8.534,0.721-13.323-0.501
							c-4.845-1.235-8.41-5.358-13.412-6.493C261.882,648.984,254.662,650.77,252.079,653.685z"/>
						<path fill="#1E1E1E" d="M252.655,641.355c-1.444,2.277-2.007,11.788-0.285,13.943c2.917,3.649,12.507,1.711,15.097-1.46
							c2.332-2.856,2.784-7.483,3.403-11.627c0.567-3.792-0.447-5.464-4.469-6.389c-3.265-0.75-9.267-0.521-12.065,1.589
							C252.637,638.693,250.858,641.795,252.655,641.355z"/>
						
							<linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="1543.1338" y1="601.7539" x2="1580.4785" y2="601.7539" gradientTransform="matrix(1 0 0 1 -1294.8955 0)">
							<stop  offset="0" style="stop-color:#6B73FF"/>
							<stop  offset="1" style="stop-color:#000DFF"/>
						</linearGradient>
						<path fill="url(#SVGID_16_)" d="M285.435,561.024c1.071,6.475-3.984,34.237-5.823,54.016
							c-0.837,9.011-1.445,23.719-1.811,32.757c-6.641,4.92-22.723,5.742-29.563,0.42c-0.048-6.266,1.266-23.383,1.565-29.621
							c0.377-7.871-0.878-15.711-0.443-23.605c0.528-9.59,2.309-20.476,6.381-29.271c3.78-8.166,10.817-13.872,20.131-14.07
							C279.274,551.575,284.427,554.932,285.435,561.024z"/>
					</g>
					<g>
						<g>
							<path d="M283.748,599.692c-6.381,5.181-13.055,10.673-16.912,18.103c-4.299,8.282-4.497,17.954-5.42,27.046
								c-2.081,20.501-4.107,41.009-5.895,61.538c-0.303,3.476,5.122,3.455,5.423,0c1.637-18.805,3.476-37.59,5.372-56.369
								c0.927-9.18,0.714-19.68,4.579-28.21c3.462-7.644,10.329-13.109,16.688-18.273
								C290.292,601.326,286.435,597.51,283.748,599.692L283.748,599.692z"/>
						</g>
						<g>
							<path d="M185.624,599.692c-6.38,5.181-13.055,10.673-16.912,18.103c-4.299,8.282-4.497,17.954-5.42,27.046
								c-2.08,20.501-4.107,41.009-5.895,61.538c-0.302,3.476,5.122,3.455,5.423,0c1.637-18.805,3.476-37.59,5.372-56.369
								c0.927-9.18,0.714-19.68,4.579-28.21c3.462-7.644,10.329-13.109,16.688-18.273
								C192.168,601.326,188.311,597.51,185.624,599.692L185.624,599.692z"/>
						</g>
						<g>
							<path d="M286.178,603.387c4.34,2.334,4.755,7.813,5.154,12.218c0.552,6.093,1.102,12.185,1.684,18.274
								c1.167,12.204,2.386,24.404,3.51,36.612c0.317,3.446,5.743,3.479,5.423,0c-1.336-14.514-2.811-29.014-4.165-43.526
								c-0.829-8.883,0.449-23.248-8.87-28.261C285.841,597.052,283.1,601.731,286.178,603.387L286.178,603.387z"/>
						</g>
						<g>
							<path d="M197.873,603.387c4.34,2.334,4.755,7.813,5.154,12.218c0.552,6.093,1.102,12.185,1.684,18.274
								c1.167,12.204,2.386,24.404,3.51,36.612c0.317,3.446,5.743,3.479,5.423,0c-1.336-14.514-2.811-29.014-4.165-43.526
								c-0.829-8.883,0.449-23.248-8.87-28.261C197.536,597.052,194.795,601.731,197.873,603.387L197.873,603.387z"/>
						</g>
						<g>
							<path fill="#434556" d="M283.018,590.189l-76.311,7.358c-7.143-0.052-16.89,3.262-21.769,7.4l-17.083,9.4
								c-5.194,4.406-0.83,16.277,10.12,20.032c14.037,4.814,33.719,5.6,57.235,1.388c7.22-1.293,19.536-5.626,24.73-10.033
								l23.653-20.065C291.189,600.743,290.161,590.241,283.018,590.189z"/>
							<path fill="#62667C" d="M279.961,589.625l-72.786-0.532c-7.144-0.052-16.89,3.261-21.769,7.4l-15.428,13.088
								c-5.194,4.407-2.485,12.59,8.464,16.345c14.038,4.814,33.719,5.6,57.235,1.388c7.22-1.294,19.536-5.626,24.73-10.033
								l23.653-20.065C288.939,593.075,287.104,589.678,279.961,589.625z"/>
						</g>
					</g>
					<g>
						
							<linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="-511.1338" y1="-46.4155" x2="-459.8525" y2="-46.4155" gradientTransform="matrix(-0.8013 -0.5982 -0.5982 0.8013 -233.3555 352.4903)">
							<stop  offset="0" style="stop-color:#6B73FF"/>
							<stop  offset="1" style="stop-color:#000DFF"/>
						</linearGradient>
						<path fill="url(#SVGID_17_)" d="M192.98,596.395c-3.246-3.91-13.625-16.531-19.263-15.9
							c-6.125,0.686-8.992,7.319-8.688,12.052c0.361,5.64,2.552,10.188,5.23,17.246c2.404,6.333,12.597,18.471,22.882,20.837
							C203.765,633.074,201.683,606.876,192.98,596.395z"/>
						<g>
							<g>
								<path fill="#FDB9B1" d="M190.373,466.628c0.445,7.604-6.48,1.702-6.81-0.81s0.317-4.5,2.044-4.714
									C187.333,460.892,190.225,464.099,190.373,466.628z"/>
								<path fill="#FED0CA" d="M218.892,462.274c-0.054,0.688-0.163,1.536-0.344,2.566c0,0-0.009,0.01-0.009,0.019
									c-0.605,3.452-2.016,8.893-5.07,16.746c0,0.01-0.027,0.036-0.073,0.091c-0.108,0.145-0.343,0.442-0.515,0.65
									c-0.108,0.136-0.199,0.235-0.208,0.244c-0.054,0.036-0.127,0.072-0.217,0.1c-0.199,0.063-0.47,0.1-0.795,0.126
									c-1.437,0.127-3.787-0.045-4.185-0.243c-1.229-0.606-2.35-1.175-3.362-1.718c-7.51-3.994-9.3-6.182-10.158-8.929
									c-0.253-0.805-0.425-1.654-0.642-2.612c-0.009-0.026-0.009-0.045-0.018-0.072c-0.253-1.129-0.579-2.403-1.148-3.94
									c-1.184-3.172-2.277-7.735-2.386-11.893c-0.072-2.793,0.307-5.396,1.41-7.258c1.392-2.341,3.75-4.274,6.643-4.51
									c1.292,0.019,7.312-1.292,12.77,1.763c1.708,0.958,3.208,2.079,4.456,3.452c2.503,2.784,3.931,6.562,3.823,11.957
									c0,0.434-0.018,0.867-0.045,1.328C218.882,459.699,219.081,460.322,218.892,462.274z"/>
								<path fill="#FDB9B1" d="M212.377,465.126c0.745,7.582,6.666,0.672,6.6-1.86c-0.066-2.531-1.015-4.396-2.753-4.337
									C214.485,458.986,212.129,462.605,212.377,465.126z"/>
								<path fill="#FDB9B1" d="M188.275,491.368c4.276-1.845,6.47-7.086,26.691-0.708c-2.396-3.32-3.427-12.923-3.497-20.777
									c-0.039-4.36-0.869-0.768-8.841,0.106c-4.723,0.519-12.759-2.375-12.01,0.915
									C193.228,482.354,190.446,486.303,188.275,491.368z"/>
								<path fill="#FED0CA" d="M196.478,454.619c8.867-0.114,15.438-1.57,21.102-11.629c-2.346-4.904-7.731-12.203-15.959-11.485
									c-13.313,1.162-15.037,7.936-17.002,16.878C184.57,448.444,186.739,454.745,196.478,454.619z"/>
								<linearGradient id="SVGID_18_" gradientUnits="userSpaceOnUse" x1="198.6763" y1="472.3115" x2="202.1473" y2="428.0206">
									<stop  offset="0" style="stop-color:#B85D40"/>
									<stop  offset="1" style="stop-color:#EF7432"/>
								</linearGradient>
								<path fill="url(#SVGID_18_)" d="M220.274,450.399c-0.073,0.1-0.136,0.199-0.199,0.298c-0.578,0.958-0.831,2.079-0.587,3.182
									c0.398,1.762,0.208,3.488-0.624,4.935c-0.217,0.379-0.479,0.741-0.795,1.075c-0.262,0.289-0.561,0.561-0.895,0.805
									c-1.04,0.777-1.753,1.952-1.798,3.244c-0.054,1.519-0.786,3.027-1.834,4.311c-0.606,0.741-1.32,1.41-2.061,1.943
									c-1.464,1.058-3.055,1.636-4.248,1.392c-2.467-0.488-8.703,0.904-13.276,0.344c-1.283-0.163-2.431-0.47-3.335-1.021
									c-0.425-0.262-0.813-0.515-1.175-0.777c-1.943-1.355-2.928-2.621-3.299-3.994c-0.361-1.283-1.184-2.44-2.386-3.037
									c-0.036-0.018-0.063-0.036-0.1-0.054c-1.654-0.868-2.16-2.396-1.663-4.384c0.371-1.464-0.09-3.019-1.121-4.111
									c-1.437-1.519-1.582-3.299-0.47-4.854c0.841-1.166,1.139-2.675,0.714-4.058c-0.922-3.027,0.163-5.513,3-6.778
									c1.238-0.542,1.988-1.753,2.178-3.091c0.018-0.108,0.036-0.217,0.063-0.325h0.009c0.579-2.639,3.796-5.251,6.669-4.79
									c1.456,0.244,2.982-0.045,4.013-1.111c2.811-2.901,7.492-1.374,9.769,0.895c1.076,1.066,2.567,1.6,4.058,1.328
									c4.094-0.74,5.667,3.127,5.784,6.146c0.063,1.474,0.85,2.793,2.079,3.606C221.784,443.513,221.801,448.194,220.274,450.399z
									"/>
							</g>
							<path fill="#434556" d="M218.638,491.782h-0.045l-3.633,0.136l-31.821,1.211c0.036-0.01,0.054-0.163,0.063-0.435
								c0.073-2.006-0.027-10.32,8.369-11.396c0.226-0.036,0.452-0.063,0.687-0.082c2.449-0.189,7.375-0.479,11.857-0.361
								c3.019,0.082,5.829,0.353,7.583,0.958c0.081,0.027,0.171,0.063,0.253,0.1c0.145,0.045,0.28,0.108,0.416,0.181
								c0.181,0.072,0.353,0.153,0.515,0.253C217.057,484.651,217.31,490.146,218.638,491.782z"/>
						</g>
						
							<linearGradient id="SVGID_19_" gradientUnits="userSpaceOnUse" x1="1468.9385" y1="592.0059" x2="1581.6172" y2="592.0059" gradientTransform="matrix(1 0 0 1 -1294.8955 0)">
							<stop  offset="0" style="stop-color:#6B73FF"/>
							<stop  offset="1" style="stop-color:#000DFF"/>
						</linearGradient>
						<path fill="url(#SVGID_19_)" d="M278.042,552.002c-5.498-2.203-12.016,1.833-28.853,11.797
							c-14.154,8.377-34.207,21.026-42.212,35.758c-9.817-7.25-21.959-12.221-27.836-6.155c-9.759,11.35-5.732,30.83,14,37.228
							c4.958,1.608,24.772,2.524,31.061,1.595c5.449-0.806,15.01-3.111,22.523-7.936c8.373-5.377,32.048-39.544,37.579-48.496
							C290.101,566.411,284.458,554.571,278.042,552.002z"/>
						<path fill="#434556" d="M157.016,518.58c-3.83,17.51-4.664,28.095-3.681,41.604c0.395,5.425,0.668,21.244,11.039,20.794
							c9.03-0.392,12.909-12.861,13.785-18.383c1.684-10.619,2.368-21.515,2.055-32.162c-0.236-8.047,1.036-28.654-8.347-32.683
							C168.309,496.224,159.103,509.038,157.016,518.58z"/>
						<path fill="#1E1E1E" d="M246.826,606.938c-0.181,0.605-7.356,2.838-17.063,4.845c-7.239,1.491-15.879,2.864-24.067,3.353
							c-1.907,0.117-3.787,0.181-5.622,0.189h-0.072c-9.48,0.027-17.668-1.481-21.31-5.874c-0.027-0.027-0.045-0.054-0.072-0.091
							c-0.172-0.217-0.334-0.434-0.488-0.668c-0.876-1.293-1.437-2.82-1.79-4.51c-0.361-1.699-0.497-3.561-0.515-5.504
							c-0.045-5.884,1.066-12.49,0.823-17.597c-0.054-1.129-0.172-2.195-0.389-3.153c-0.425-1.907-0.868-3.679-1.329-5.351
							c-1.988-7.176-4.257-12.282-6.317-16.529c-1.03-2.106-2.006-4.004-2.874-5.829c-1.266-2.693-2.305-5.233-2.91-8.098
							c-1.609-7.547-3.742-27.836,0.524-37.343c0.28-0.642,0.597-1.238,0.949-1.771c3.344-5.143,10.293-8.459,18.897-10.313
							c1.654-0.361,3.371-0.668,5.134-0.912c8.314-1.193,17.686-1.229,26.651-0.389c1.211,0.108,2.413,0.244,3.606,0.389
							c6.38,0.777,12.454,1.997,17.65,3.552c1.699,0.506,3.073,1.103,4.175,1.808c4.013,2.584,4.5,6.76,4.456,14.496
							c-0.009,3.289-0.127,7.229-0.1,11.966c0.036,6.588-1.058,18.545-2.178,28.449c-0.316,2.774-0.624,5.387-0.922,7.673
							c-0.226,1.826-0.452,3.452-0.642,4.79c-0.226,1.555-0.407,2.721-0.542,3.371c-0.09,0.443-0.163,0.895-0.208,1.347
							c-0.705,6.299,2.467,13.113,4.862,20.136c0.488,1.437,0.94,2.892,1.319,4.347C247.622,598.09,248.101,602.519,246.826,606.938
							z"/>
						<linearGradient id="SVGID_20_" gradientUnits="userSpaceOnUse" x1="249.3726" y1="497.1357" x2="249.3726" y2="577.8057">
							<stop  offset="0" style="stop-color:#6B73FF"/>
							<stop  offset="1" style="stop-color:#000DFF"/>
						</linearGradient>
						<path fill="url(#SVGID_20_)" d="M261.829,556.437c-0.063,0.325-0.126,0.659-0.199,1.003
							c-1.455,6.814-4.682,15.906-9.625,19.15c-0.1,0.063-0.199,0.127-0.298,0.189c-0.741,0.443-1.518,0.76-2.332,0.913
							c-0.922,0.172-1.735,0.145-2.467-0.054c-3.028-0.841-4.555-4.664-5.35-9.2c-0.226-1.266-0.389-2.585-0.524-3.923
							c-0.19-1.997-0.289-4.013-0.361-5.874c-0.136-3.254-0.172-6.037-0.434-7.429c-0.027-0.136-0.054-0.28-0.081-0.416
							c-2.774-14.857-3.362-23.805-3.841-36.476c-0.244-6.335,0.117-10.501,0.795-13.141c0.813-3.136,2.069-4.13,3.308-4.039
							c0.831,0.054,1.636,0.578,2.322,1.265c5.793,5.893,11.152,16.376,12.951,23.742c1.482,6.072,3.606,11.686,5.088,17.469
							c0.904,3.507,1.563,7.067,1.717,10.846C262.552,551.728,262.326,553.879,261.829,556.437z"/>
					</g>
					<g>
						<g>
							<path d="M247.491,605.348c0.683,6.982-0.131,15.605,2.56,22.183c4.282,10.467,15.321,0.139,19.856-3.877
								c2.618-2.318-1.229-6.142-3.834-3.834c-1.913,1.692-8.042,8.995-10.877,6.02c-0.914-0.96-0.856-4.045-0.996-5.225
								c-0.602-5.072-0.79-10.185-1.287-15.267C252.577,601.906,247.151,601.871,247.491,605.348L247.491,605.348z"/>
						</g>
						<g>
							<path d="M154.916,605.348c0.741,6.6,1.759,13.433,4.019,19.707c1.412,3.92,4.604,7.146,9.042,5.635
								c3.285-1.12,1.875-6.359-1.441-5.229c-3.674,1.253-5.98-18.175-6.197-20.112
								C159.953,601.919,154.526,601.879,154.916,605.348L154.916,605.348z"/>
						</g>
						<path fill="#62667C" d="M159.368,609.742c22.989,14.672,74.687,13.093,92.278,0.499c1.456-1.042,4.266-2.407,4.035-4.11
							l-5.766-42.544c-0.989-7.295-8.273-15.155-15.672-13.146c-18.59,5.048-54.682,7.609-69.011-0.502
							c-6.672-3.777-15.864,8.701-14.875,15.997l5.867,40.646C156.455,608.286,157.859,608.779,159.368,609.742z"/>
						<path fill="#434556" d="M158.953,610.44c21.93,13.647,73.382,13.17,90.164,1.455c1.388-0.969,2.913-1.298,2.692-2.882
							l-6.48-41.506c-0.943-6.786-7.892-14.098-14.949-12.229c-17.734,4.696-54.722,5.745-68.391-1.8
							c-6.364-3.514-12.477,7.663-11.534,14.449l5.5,39.573C156.174,609.086,157.514,609.545,158.953,610.44z"/>
					</g>
				</g>
				<path opacity="0.2" fill="#ECF2FA" d="M220.274,450.399c-0.073,0.1-0.136,0.199-0.199,0.298
					c-0.307-2.603-1.283-5.151-3.045-6.769c-0.724-0.669-1.578-1.186-2.572-1.485c-1.139-0.345-2.163-1.056-2.75-2.091
					c-0.852-1.5-2.401-2.736-4.07-2.789c-1.131-0.035-2.11-0.771-2.682-1.749c-0.99-1.69-2.438-2.59-4.899-2.279
					c-1.667,0.205-3.711,0.897-5.333,1.938c-1.063,0.681-2.399,0.579-3.436-0.142c-0.146-0.102-0.296-0.194-0.45-0.277
					c-0.813-0.434-1.735-0.569-2.991-0.244c-0.397,0.108-0.949,0.325-1.473,0.633c0.579-2.639,3.796-5.251,6.669-4.79
					c1.456,0.244,2.982-0.045,4.013-1.111c2.811-2.901,7.492-1.374,9.769,0.895c1.076,1.066,2.567,1.6,4.058,1.328
					c4.094-0.74,5.667,3.127,5.784,6.146c0.063,1.474,0.85,2.793,2.079,3.606C221.784,443.513,221.801,448.194,220.274,450.399z"/>
				<path opacity="0.2" fill="#ECF2FA" d="M260.78,539.617c-0.145,0.063-0.298,0.1-0.461,0.108
					c-1.943,0.145-12.374-27.668-24.211-38.519c-3.615-3.314-21.632-9.674-21.395-9.839c1.414-0.987,0.014-3.991-0.621-5
					c-1.646-2.615-2.621-4.003-2.657-4.22c0.154-0.1,0.334-0.172,0.515-0.235c0.126-0.036,0.416,0.181,0.931,0.434
					c4.176,2.305,4.429,7.8,5.757,9.436h-0.045c6.38,0.777,12.454,1.997,17.65,3.552c1.699,0.506,3.073,1.103,4.175,1.808
					c0.831,0.054,1.636,0.578,2.322,1.265c5.793,5.893,11.152,16.376,12.951,23.742C257.174,528.221,259.298,533.834,260.78,539.617
					z"/>
			</g>
			<g>
				<g>
					
						<linearGradient id="SVGID_21_" gradientUnits="userSpaceOnUse" x1="-176.3315" y1="704.8906" x2="-136.2863" y2="704.8906" gradientTransform="matrix(0.9994 0.0347 -0.0347 0.9994 344.2 1.4427)">
						<stop  offset="0" style="stop-color:#1E1E1E"/>
						<stop  offset="1" style="stop-color:#63322B"/>
					</linearGradient>
					<path fill="url(#SVGID_21_)" d="M146.952,714.15c3.902,0.17,10.905,0.097,17.096-3.057c6.192-3.154,14.472-5.321,17.391-12.058
						c1.271-2.93,1.901-4.544,2.188-5.564c-0.301-1.423-1.181-2.607-2.963-3.257c-2.652-0.966-7.392,4.956-18.423-2.987
						c-4.175-3.006-12.984,0.376-15.935,3.484c-2.765,1.443-2.842,14.577-3.096,18.128c-0.076,1.061-0.032,2.162,0.15,3.213
						C144.021,713.082,145.124,714.071,146.952,714.15z"/>
					<path fill="#1E1E1E" d="M183.627,693.472c-0.287,1.021-0.917,2.635-2.188,5.564c-2.919,6.736-11.199,8.903-17.391,12.058
						c-6.191,3.153-13.194,3.227-17.096,3.057c-1.829-0.079-2.931-1.068-3.592-2.098c0.51,2.943,0.159,5.977,3.447,5.991
						c6.327,0.027,10.822-0.63,12.977-1.85c3.683-2.086,2.416-5.263,5.431-5.473c2.024-0.141,0.979,0.872,3.098,0.632
						c2.835-0.322,7.586-2.404,11.76-7.727C181.918,701.275,184.316,696.736,183.627,693.472z"/>
					<path fill="#1E1E1E" d="M146.245,697.322c3.568,3.704,13.879,0.605,16.395-3.133c2.265-3.366,2.291-8.482,2.548-13.086
						c0.236-4.212-1.045-5.942-5.546-6.547c-3.654-0.49-10.208,0.371-13.059,2.966C144.851,679.1,144.139,695.137,146.245,697.322z"
						/>
					<path fill="#1E1E1E" d="M171.486,590.11c1.832,6.985-0.889,37.923-0.895,59.782c-0.002,9.959,0.825,26.138,1.342,36.078
						c-6.777,6.066-24.317,8.602-32.353,3.463c-0.689-6.86-0.988-25.751-1.293-32.618c-0.386-8.663-2.558-17.127-2.882-25.822
						c-0.395-10.562,0.451-22.672,4.02-32.724c3.313-9.332,10.06-15.428,20.246-16.592
						C163.391,581.253,169.763,583.536,171.486,590.11z"/>
				</g>
				<g>
					<linearGradient id="SVGID_22_" gradientUnits="userSpaceOnUse" x1="175.5869" y1="703.084" x2="226.6238" y2="703.084">
						<stop  offset="0" style="stop-color:#1E1E1E"/>
						<stop  offset="1" style="stop-color:#63322B"/>
					</linearGradient>
					<path fill="url(#SVGID_22_)" d="M196.355,713.544c22.718-3.846,25.611-9.198,27.529-11.147
						c1.009-1.026,1.705-2.076,2.146-2.853c-0.473-0.413-1.252-0.709-2.431-0.834c-4.785-0.505-9.392,0.793-14.663-0.552
						c-5.332-1.359-9.255-5.897-14.76-7.146c-5.017-1.137-12.962,0.827-15.804,4.035c-2.713,1.538-2.334,14.667-2.465,18.225
						c-0.027,0.733-0.095,1.434-0.161,2.094C178.193,715.611,183.955,715.644,196.355,713.544z"/>
					<path fill="#1E1E1E" d="M226.03,699.544c-0.441,0.776-1.137,1.826-2.146,2.853c-1.918,1.949-4.811,7.302-27.529,11.147
						c-12.4,2.1-18.163,2.067-20.61,1.822c-0.299,2.971-0.566,5.092,3.069,5.434c17.158,1.612,11.27-6.254,16.034-6.752
						c2.018-0.211,3.063,1.744,5.172,1.431c2.822-0.421,5.872-0.846,8.563-1.731c5.877-1.933,9.027-2.303,13.623-6.481
						C224.446,705.229,228.046,701.309,226.03,699.544z"/>
					<path fill="#1E1E1E" d="M179.006,681.479c-1.589,2.507-2.208,12.974-0.313,15.345c3.211,4.018,13.765,1.884,16.614-1.606
						c2.566-3.144,3.064-8.235,3.745-12.795c0.624-4.173-0.492-6.014-4.917-7.031c-3.594-0.825-10.199-0.572-13.278,1.749
						C178.986,678.551,177.028,681.964,179.006,681.479z"/>
					<path fill="#1E1E1E" d="M215.081,593.075c1.179,7.125-4.385,37.679-6.408,59.444c-0.921,9.916-1.59,26.103-1.993,36.049
						c-7.309,5.415-25.007,6.32-32.534,0.463c-0.053-6.896,1.393-25.733,1.722-32.6c0.415-8.661-0.966-17.289-0.487-25.978
						c0.582-10.554,2.542-22.533,7.022-32.213c4.16-8.987,11.905-15.267,22.154-15.485
						C208.3,582.677,213.971,586.37,215.081,593.075z"/>
				</g>
				<g>
					<g>
						<path d="M213.416,635.821c-7.086,5.753-14.479,11.847-18.705,20.13c-4.574,8.964-4.784,19.432-5.779,29.241
							c-2.294,22.603-4.53,45.212-6.5,67.846c-0.302,3.476,5.122,3.456,5.423,0c1.819-20.9,3.866-41.78,5.974-62.653
							c1.024-10.145,0.8-21.695,5.258-31.063c3.896-8.188,11.278-14.076,18.164-19.666
							C219.96,637.456,216.104,633.64,213.416,635.821L213.416,635.821z"/>
					</g>
					<g>
						<path d="M105.43,635.821c-7.086,5.753-14.479,11.847-18.705,20.13c-4.574,8.964-4.783,19.432-5.779,29.241
							c-2.294,22.603-4.53,45.212-6.5,67.846c-0.303,3.476,5.122,3.456,5.422,0c1.819-20.9,3.866-41.78,5.974-62.653
							c1.025-10.145,0.8-21.695,5.258-31.063c3.896-8.188,11.278-14.076,18.164-19.666
							C111.975,637.456,108.117,633.64,105.43,635.821L105.43,635.821z"/>
					</g>
					<g>
						<path d="M216.035,639.46c5.117,2.722,5.412,9.307,5.874,14.437c0.587,6.523,1.184,13.046,1.808,19.566
							c1.279,13.36,2.613,26.716,3.843,40.081c0.317,3.446,5.743,3.479,5.422,0c-1.438-15.619-3.02-31.225-4.483-46.842
							c-0.622-6.628-0.766-13.475-2.041-20.019c-0.959-4.925-3.107-9.471-7.686-11.906
							C215.692,633.14,212.95,637.818,216.035,639.46L216.035,639.46z"/>
					</g>
					<g>
						<path d="M118.854,639.46c5.117,2.722,5.412,9.307,5.874,14.437c0.587,6.523,1.184,13.046,1.808,19.566
							c1.279,13.36,2.613,26.716,3.843,40.081c0.317,3.446,5.743,3.479,5.423,0c-1.438-15.619-3.02-31.225-4.484-46.842
							c-0.621-6.628-0.766-13.475-2.04-20.019c-0.959-4.925-3.107-9.471-7.686-11.906C118.512,633.14,115.77,637.818,118.854,639.46
							L118.854,639.46z"/>
					</g>
					<g>
						<path fill="#434556" d="M212.42,625.171l-83.98,8.099c-7.861-0.058-18.587,3.589-23.957,8.145l-18.801,10.345
							c-5.716,4.85-0.913,17.913,11.137,22.046c15.448,5.298,37.108,6.162,62.988,1.526c7.945-1.423,21.5-6.191,27.216-11.041
							l26.03-22.082C221.413,636.785,220.281,625.229,212.42,625.171z"/>
						<path fill="#62667C" d="M209.056,624.551l-80.102-0.586c-7.861-0.058-18.587,3.589-23.957,8.144l-16.979,14.404
							c-5.717,4.85-2.735,13.854,9.314,17.987c15.448,5.298,37.108,6.161,62.988,1.526c7.946-1.423,21.5-6.191,27.216-11.041
							l26.03-22.083C218.937,628.348,216.917,624.608,209.056,624.551z"/>
					</g>
				</g>
				<g>
					
						<linearGradient id="SVGID_23_" gradientUnits="userSpaceOnUse" x1="566.6978" y1="805.7559" x2="623.1338" y2="805.7559" gradientTransform="matrix(-0.8013 -0.5982 -0.5982 0.8013 1061.5391 352.4903)">
						<stop  offset="0" style="stop-color:#6B73FF"/>
						<stop  offset="1" style="stop-color:#000DFF"/>
					</linearGradient>
					<path fill="url(#SVGID_23_)" d="M113.333,632c-3.572-4.303-14.994-18.192-21.2-17.498c-6.741,0.754-9.895,8.055-9.561,13.263
						c0.398,6.206,2.808,11.213,5.756,18.979c2.645,6.969,13.863,20.327,25.182,22.932C125.202,672.366,122.911,643.535,113.333,632
						z"/>
					<g>
						<path fill="#FDB9B1" d="M109.242,485.874c-1.253,8.555-7.593,0.402-7.382-2.464c0.211-2.867,1.387-4.929,3.355-4.77
							C107.182,478.801,109.658,483.03,109.242,485.874z"/>
						<path fill="#FED0CA" d="M141.512,480.881c0.393,15.801-4.063,24.132-9.465,25.521c-13.185,3.39-17.128-9.881-18.411-15.057
							c-0.304-1.243-0.686-2.641-1.347-4.313c-2.303-5.793-4.42-15.912-1.498-21.055c1.475-2.602,4.028-4.787,7.208-5.102
							c1.416-0.01,8.011-1.591,14.091,1.65C138.169,465.759,141.267,470.983,141.512,480.881z"/>
						<path fill="#FDB9B1" d="M136.939,485.053c2.299,8.848,8.027-0.448,7.476-3.438s-2.023-5.021-4.073-4.628
							S136.174,482.11,136.939,485.053z"/>
						<path fill="#FDB9B1" d="M107.273,516.576c4.816-1.756,9.212-4.907,29.459-0.625c-2.421-3.801-2.694-10.784-2.273-19.418
							c0.234-4.794-1.43,0.207-10.244,0.661c-5.222,0.27-14.158-5.874-14.07-2.161C110.46,508.218,109.979,511.148,107.273,516.576z
							"/>
						<path fill="#FED0CA" d="M140.265,466.414c-0.47,0.886-0.949,1.717-1.437,2.494c-5.567,9.064-11.514,11.089-20.497,11.387
							c-3.769,0.127-6.471-0.623-8.396-1.653c-3.525-1.88-4.401-4.699-4.365-4.745c0.922-4.636,1.699-8.784,3.769-12.047
							c0.009-0.018,0.027-0.036,0.036-0.054c2.359-3.67,6.372-6.209,14.099-7.05c5.956-0.632,10.249,2.007,13.222,5.604
							c0.615,0.75,1.166,1.546,1.681,2.35v0.009C139.126,463.92,139.75,465.176,140.265,466.414z"/>
						<linearGradient id="SVGID_24_" gradientUnits="userSpaceOnUse" x1="104.207" y1="479.3359" x2="141.9902" y2="479.3359">
							<stop  offset="0" style="stop-color:#1E1E1E"/>
							<stop  offset="1" style="stop-color:#63322B"/>
						</linearGradient>
						<path fill="url(#SVGID_24_)" d="M141.738,477.123c-0.081,0.642-0.181,1.283-0.307,1.935c-0.524,2.774-1.41,5.612-2.603,8.359
							c-0.117,0.271-0.235,0.542-0.353,0.805c-1.355,3.181-2.286,5.54-4.003,7.185c-2.187,2.078-5.657,3-12.905,2.991
							c-2.169,0.045-4.013-0.009-5.576-0.208c-2.702-0.353-4.564-1.139-5.775-2.603c-0.019-0.027-0.045-0.055-0.063-0.081
							c-0.967-1.202-2.042-3.29-3-5.865c-1.184-3.154-2.205-7.041-2.666-10.945c-0.84-6.904,0.063-13.845,4.853-16.846
							c0.018-0.009,0.036-0.026,0.054-0.036c-0.054,1.835-0.696,4.781,0.353,7.067c0.913,1.997,3.109,3.498,8.088,3.326
							c8.116-0.271,14.948-1.039,17.642-7.393c0.37-0.85,0.66-1.799,0.858-2.865c0.108-0.533,0.19-1.094,0.253-1.689
							c0.036,0.026,0.072,0.063,0.108,0.09c1.03,0.831,1.889,1.799,2.603,2.874C141.738,466.901,142.425,471.854,141.738,477.123z"
							/>
						<linearGradient id="SVGID_25_" gradientUnits="userSpaceOnUse" x1="102.877" y1="513.6543" x2="141.3677" y2="513.6543">
							<stop  offset="0" style="stop-color:#6B73FF"/>
							<stop  offset="1" style="stop-color:#000DFF"/>
						</linearGradient>
						<path fill="url(#SVGID_25_)" d="M141.368,515.867l-4.374,0.208l-34.117,1.6c0.073-0.027,0.407-0.353,1.003-0.868
							c0.949-0.822,2.558-2.114,4.79-3.38c2.946-1.672,6.977-3.28,12.038-3.678c5.685-0.443,10.637,0.442,14.388,1.934
							c0.262,0.108,0.515,0.208,0.759,0.325c1.979,0.868,3.588,1.916,4.727,3.01C140.88,515.298,141.133,515.587,141.368,515.867z"
							/>
					</g>
					<path fill="#1E1E1E" d="M206.945,583.146c-6.051-2.424-13.224,2.018-31.753,12.982c-15.576,9.219-37.645,23.141-46.455,39.353
						c-10.804-7.979-24.167-13.449-30.634-6.774c-10.74,12.49-6.308,33.929,15.407,40.97c5.457,1.77,27.262,2.777,34.182,1.754
						c5.997-0.887,16.519-3.423,24.787-8.732c9.214-5.918,35.269-43.519,41.356-53.371
						C220.215,599.003,214.005,585.973,206.945,583.146z"/>
					
						<linearGradient id="SVGID_26_" gradientUnits="userSpaceOnUse" x1="81.4009" y1="534.8477" x2="81.4009" y2="620.6494" gradientTransform="matrix(0.9999 -0.0145 0.0145 0.9999 -5.9582 -4.4044)">
						<stop  offset="0" style="stop-color:#6B73FF"/>
						<stop  offset="1" style="stop-color:#000DFF"/>
					</linearGradient>
					<path fill="url(#SVGID_26_)" d="M70.538,547.288c-4.215,19.271-1.917,29.995-0.835,44.863
						c0.434,5.97,0.736,23.379,12.148,22.884c9.938-0.432,14.207-14.155,15.17-20.23c1.854-11.688,2.606-23.678,2.262-35.396
						c-0.26-8.854-5.751-25.55-16.078-29.982C79.29,527.746,72.834,536.787,70.538,547.288z"/>
					<linearGradient id="SVGID_27_" gradientUnits="userSpaceOnUse" x1="125.6997" y1="652.4512" x2="125.6997" y2="514.499">
						<stop  offset="0" style="stop-color:#6B73FF"/>
						<stop  offset="1" style="stop-color:#000DFF"/>
					</linearGradient>
					<path fill="url(#SVGID_27_)" d="M172.583,643.224c-0.371,1.283-29.182,9.146-51.496,9.227
						c-10.447,0.036-19.467-1.626-23.479-6.462c-2.35-2.837-3.082-6.949-3.145-11.486c-0.1-6.416,1.111-13.674,0.913-19.349
						c-0.045-1.401-0.181-2.693-0.443-3.859c-0.443-1.997-0.913-3.868-1.401-5.631c-4.871-17.776-11.324-24.121-13.375-33.764
						c-1.861-8.722-4.356-32.933,1.338-42.585c0.09-0.162,0.189-0.316,0.289-0.47c3.895-5.982,12.056-9.842,22.097-12.038
						c1.184-0.262,2.395-0.497,3.624-0.705c0.569-0.099,1.139-0.189,1.708-0.279c6.137-0.949,12.761-1.365,19.413-1.32
						c2.485,0,4.979,0.082,7.447,0.208c0.244,0.009,0.479,0.027,0.714,0.036c1.274,0.082,2.54,0.172,3.796,0.271
						c6.995,0.569,13.692,1.6,19.521,2.964c3.958,0.931,6.543,2.232,8.206,4.004c2.377,2.54,2.865,6.037,2.774,10.817
						c-0.099,4.826-0.786,10.954-0.75,18.708c0.063,10.953-2.72,35.373-4.139,45.179c-0.235,1.636-0.434,2.874-0.579,3.561
						c-0.126,0.624-0.217,1.247-0.271,1.88C164.242,614.665,176.705,629.007,172.583,643.224z"/>
					<linearGradient id="SVGID_28_" gradientUnits="userSpaceOnUse" x1="175.4053" y1="521.96" x2="175.4053" y2="611.5469">
						<stop  offset="0" style="stop-color:#6B73FF"/>
						<stop  offset="1" style="stop-color:#000DFF"/>
					</linearGradient>
					<path fill="url(#SVGID_28_)" d="M189.104,588.022c-1.708,8.793-6.317,21.979-13.71,23.397
						c-5.197,0.994-7.51-3.994-8.604-10.193c-0.262-1.465-0.452-3.001-0.597-4.537c-0.551-5.992-0.407-12.047-0.85-14.415
						c-3.136-16.62-3.778-26.516-4.32-40.597c-0.434-11.297,1.672-16.619,4.139-18.671c1.04-0.858,2.142-1.139,3.145-1.021
						c0.967,0.1,1.834,0.561,2.467,1.202c6.38,6.479,9.986,14.188,11.966,22.286c1.301,5.332,2.955,11.532,4.347,17.776
						c1.392,6.309,2.521,12.662,2.748,18.202C189.89,582.844,189.646,585.202,189.104,588.022z"/>
				</g>
				<g>
					<g>
						<path d="M173.595,641.854c0.436,4.438,0.667,8.896,1.068,13.338c0.314,3.479,0.368,7.543,1.677,10.828
							c4.552,11.416,16.546,0.131,21.458-4.214c2.619-2.316-1.228-6.14-3.834-3.834c-2.39,2.113-6.92,7.825-10.439,7.577
							c-2.913-0.205-2.809-4.566-3.055-6.591c-0.693-5.682-0.893-11.411-1.452-17.104
							C178.68,638.412,173.254,638.377,173.595,641.854L173.595,641.854z"/>
					</g>
					<g>
						<path d="M71.715,641.854c0.788,7.069,1.95,14.259,4.224,21.021c1.468,4.365,4.751,8.326,9.805,6.604
							c3.285-1.119,1.875-6.359-1.441-5.229c-4.413,1.504-6.896-19.978-7.165-22.396C76.755,638.423,71.329,638.385,71.715,641.854
							L71.715,641.854z"/>
					</g>
					<path fill="#62667C" d="M76.342,646.689c25.299,16.146,82.193,14.408,101.553,0.549c1.602-1.146,4.694-2.648,4.44-4.523
						l-6.345-46.819c-1.088-8.028-9.104-16.679-17.247-14.468c-20.459,5.557-60.178,8.375-75.947-0.552
						c-7.342-4.157-17.459,9.576-16.371,17.604l6.457,44.732C73.137,645.087,74.682,645.63,76.342,646.689z"/>
					<path fill="#434556" d="M75.886,647.458c24.133,15.02,80.757,14.493,99.226,1.602c1.527-1.066,3.205-1.429,2.963-3.172
						l-7.132-45.678c-1.038-7.468-8.685-15.514-16.452-13.457c-19.517,5.168-60.221,6.322-75.264-1.981
						c-7.004-3.866-13.731,8.434-12.694,15.901l6.053,43.551C72.828,645.968,74.302,646.473,75.886,647.458z"/>
				</g>
				<path opacity="0.4" fill="#ECF2FA" d="M136.134,462.862c-0.633-0.145-9.064-9.381-26.741-1.049l-0.018-0.018
					c2.359-3.67,6.372-6.209,14.099-7.05c5.956-0.632,10.249,2.007,13.222,5.604c6.417,5.084,5.092,14.131,5.239,14.766
					C140.265,467.622,136.667,462.998,136.134,462.862z"/>
				<path opacity="0.2" fill="#ECF2FA" d="M187.088,563.25c-0.045-0.072-0.099-0.234-0.153-0.487
					c-0.94-4.167-11.53-33.69-21.771-39.756c-0.091-0.054-0.071-0.223-0.154-0.244c-7.219-1.862-28.215-7.754-28.224-8.017
					c-0.018-1.256-0.515-2.187-0.931-2.738c1.979,0.868,3.588,1.916,4.727,3.01c6.995,0.569,13.692,1.6,19.521,2.964
					c3.958,0.931,6.543,2.232,8.206,4.004c0.967,0.1,1.834,0.561,2.467,1.202c6.38,6.479,9.986,14.188,11.966,22.286
					C184.043,550.806,185.697,557.006,187.088,563.25z"/>
			</g>
			<g>
				<g>
					<path fill="#FDB9B1" d="M316.551,706.534c-0.521-0.451-1.036-0.942-1.547-1.453c-1.351-1.352-9.537-18.931-8.401-25.775
						c1.065-5.119,1.882-10.23,2.794-15.425c1.97-11.23,4.057-22.68,6.773-33.803c2.84-11.632,5.352-35.186-0.51-39.271
						c-4.053-2.825-7.39-4.055-11.776-1.337c-7.792,4.826-9.731,14.061-11.681,22.272c-2.825,11.898-2.973,26.322-1.312,38.485
						c0.86,6.303,1.552,23.378-1.621,31.878c-1.297,3.47-2.943,6.563-4.365,10.145c-2.468,6.217,3.656,5.69,8.019,8.664
						c5.4,3.682,8.845,9.376,15.637,11.101c3.991,1.014,10.334-0.567,14.983-1.886C321.197,708.757,318.672,708.371,316.551,706.534
						z"/>
					
						<linearGradient id="SVGID_29_" gradientUnits="userSpaceOnUse" x1="301.1875" y1="685.4063" x2="344.541" y2="685.4063" gradientTransform="matrix(0.9929 0.1193 -0.1193 0.9929 66.6646 -18.7209)">
						<stop  offset="0" style="stop-color:#6B73FF"/>
						<stop  offset="1" style="stop-color:#000DFF"/>
					</linearGradient>
					<path fill="url(#SVGID_29_)" d="M316.897,706.425c-0.674-0.143-1.382-0.833-1.893-1.344c-1.866,1.107-2.957,1.439-5.164-0.43
						c-2.151-1.821-4.066-4.736-5.837-6.812c-4.3-5.042-8.676-11.908-16.351-11.851c-0.913,2.037-1.883,4.085-2.747,6.261
						c-2.468,6.217,3.656,5.69,8.019,8.664c5.4,3.682,6.824,10.032,13.616,11.757c3.992,1.014,16.795,1.001,19.6-2.667
						C325.428,706.712,319.894,707.061,316.897,706.425z"/>
					<path fill="#F8DEBB" d="M290.22,695.624c6.032,4.093,5.697,4.337,7.929,6.778c2.108,2.306,4.407,7.856,6.844,9.763
						c-1.502-0.608-1.681-0.653-6.364-3.102c-6.097-3.188-0.243-5.156-10.545-11.475c-1.062-0.651-3.76-1.85-3.76-1.85
						C284.312,693.17,289.767,695.224,290.22,695.624z"/>
					<path fill="#634541" d="M284.412,694.438c1.939-0.985,3.889,0.195,5.808,1.187c1.14,0.59,1.791,1.109,2.164,1.711
						c0.205,2.688-0.189,11.566-0.401,13.333c-3.627,1.19-5.588,0.184-6.436-0.644C285.523,704.541,283.849,694.431,284.412,694.438
						z"/>
				</g>
				<g>
					<path fill="#FDB9B1" d="M343.895,709.082c-0.521-0.451-1.036-0.942-1.546-1.453c-1.352-1.353-9.537-18.931-8.401-25.775
						c1.064-5.119,1.882-10.231,2.793-15.426c1.97-11.229,4.057-22.679,6.773-33.802c2.84-11.632,5.352-35.187-0.51-39.271
						c-4.053-2.825-7.39-4.055-11.776-1.338c-7.792,4.827-9.731,14.062-11.681,22.272c-2.825,11.898-2.973,26.323-1.312,38.486
						c0.861,6.303,1.552,23.378-1.621,31.878c-1.297,3.469-2.942,6.562-4.365,10.145c-2.468,6.216,3.656,5.689,8.019,8.664
						c5.4,3.682,8.845,9.375,15.637,11.101c3.991,1.014,10.333-0.568,14.982-1.887C348.541,711.305,346.016,710.919,343.895,709.082
						z"/>
					
						<linearGradient id="SVGID_30_" gradientUnits="userSpaceOnUse" x1="328.6387" y1="684.6738" x2="371.9927" y2="684.6738" gradientTransform="matrix(0.9929 0.1193 -0.1193 0.9929 66.6646 -18.7209)">
						<stop  offset="0" style="stop-color:#6B73FF"/>
						<stop  offset="1" style="stop-color:#000DFF"/>
					</linearGradient>
					<path fill="url(#SVGID_30_)" d="M344.24,708.973c-0.674-0.143-1.382-0.833-1.892-1.344c-1.867,1.106-2.958,1.439-5.165-0.43
						c-2.151-1.821-4.066-4.736-5.836-6.812c-4.301-5.042-8.676-11.908-16.352-11.851c-0.913,2.037-1.883,4.084-2.747,6.261
						c-2.468,6.216,3.656,5.689,8.019,8.664c5.4,3.682,6.824,10.031,13.616,11.757c3.991,1.013,16.795,1.001,19.6-2.667
						C352.772,709.259,347.238,709.608,344.24,708.973z"/>
					<path fill="#F8DEBB" d="M317.563,698.172c6.033,4.092,5.697,4.336,7.929,6.777c2.108,2.306,4.407,7.857,6.844,9.764
						c-1.502-0.608-1.681-0.653-6.364-3.103c-6.097-3.188-0.243-5.155-10.545-11.474c-1.062-0.651-3.76-1.85-3.76-1.85
						C311.655,695.717,317.111,697.771,317.563,698.172z"/>
					<path fill="#634541" d="M311.756,696.985c1.939-0.985,3.889,0.195,5.808,1.187c1.14,0.589,1.791,1.108,2.164,1.711
						c0.205,2.688-0.189,11.566-0.401,13.333c-3.626,1.19-5.588,0.183-6.436-0.644C312.867,707.089,311.192,696.979,311.756,696.985
						z"/>
				</g>
				<g>
					<g>
						<path d="M354.018,635.793c-7.086,5.752-14.479,11.847-18.705,20.13c-4.574,8.964-4.784,19.431-5.779,29.24
							c-2.294,22.604-4.53,45.213-6.5,67.847c-0.302,3.476,5.122,3.455,5.422,0c1.819-20.901,3.866-41.781,5.975-62.654
							c1.024-10.144,0.8-21.695,5.258-31.063c3.896-8.187,11.278-14.076,18.164-19.666
							C360.563,637.427,356.706,633.611,354.018,635.793L354.018,635.793z"/>
					</g>
					<g>
						<path d="M246.032,635.793c-7.087,5.752-14.479,11.847-18.706,20.13c-4.574,8.964-4.784,19.431-5.779,29.24
							c-2.294,22.604-4.53,45.213-6.5,67.847c-0.303,3.476,5.122,3.455,5.422,0c1.819-20.901,3.866-41.781,5.974-62.654
							c1.025-10.144,0.8-21.695,5.258-31.063c3.896-8.187,11.278-14.076,18.164-19.666
							C252.577,637.427,248.719,633.611,246.032,635.793L246.032,635.793z"/>
					</g>
					<g>
						<path d="M356.637,639.431c5.117,2.722,5.412,9.307,5.874,14.437c0.587,6.523,1.184,13.046,1.808,19.566
							c1.279,13.36,2.613,26.716,3.843,40.081c0.317,3.446,5.743,3.479,5.422,0c-1.438-15.619-3.02-31.225-4.483-46.842
							c-0.621-6.628-0.766-13.474-2.041-20.018c-0.959-4.925-3.107-9.472-7.686-11.907
							C356.294,633.11,353.552,637.789,356.637,639.431L356.637,639.431z"/>
					</g>
					<g>
						<path d="M259.457,639.431c5.116,2.722,5.412,9.307,5.873,14.437c0.587,6.523,1.184,13.046,1.808,19.566
							c1.279,13.36,2.613,26.716,3.843,40.081c0.317,3.446,5.743,3.479,5.423,0c-1.438-15.619-3.02-31.225-4.484-46.842
							c-0.621-6.628-0.765-13.474-2.04-20.018c-0.959-4.925-3.108-9.472-7.686-11.907
							C259.114,633.11,256.372,637.789,259.457,639.431L259.457,639.431z"/>
					</g>
					<g>
						<path fill="#434556" d="M353.022,625.142l-83.98,8.1c-7.862-0.058-18.587,3.588-23.957,8.144l-18.801,10.345
							c-5.716,4.85-0.913,17.913,11.137,22.046c15.448,5.299,37.108,6.162,62.988,1.527c7.945-1.424,21.5-6.192,27.216-11.041
							l26.031-22.083C362.015,636.757,360.883,625.199,353.022,625.142z"/>
						<path fill="#62667C" d="M349.658,624.521l-80.102-0.586c-7.861-0.058-18.587,3.589-23.957,8.145l-16.979,14.403
							c-5.716,4.85-2.735,13.854,9.314,17.987c15.448,5.298,37.108,6.162,62.988,1.526c7.946-1.423,21.5-6.191,27.216-11.041
							l26.03-22.082C359.539,628.318,357.519,624.579,349.658,624.521z"/>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path fill="#FDB9B1" d="M249.129,490.778c0.49,8.369-7.131,1.873-7.494-0.891c-0.363-2.764,0.349-4.953,2.249-5.188
								S248.966,487.995,249.129,490.778z"/>
							<path fill="#FED0CA" d="M278.664,482.941c-0.556,8.439-5.168,23.854-12.473,24.562c-16.023,1.551-14.432-9.464-15.61-14.665
								c-0.278-1.249-0.632-2.654-1.259-4.34c-2.186-5.839-4.097-15.998-1.071-21.081c1.527-2.571,4.125-4.704,7.31-4.954
								c1.416,0.02,8.042-1.429,14.055,1.937C275.628,467.755,279.304,473.062,278.664,482.941z"/>
							<path fill="#FDB9B1" d="M273.134,488.897c0.82,8.343,7.335,0.738,7.263-2.048s-1.117-4.838-3.03-4.773
								S272.861,486.123,273.134,488.897z"/>
							<path fill="#FDB9B1" d="M274.436,516.4c-11.225-3.534-17.406-3.823-21.374-2.982c-2.874,0.614-4.591,1.816-6.227,2.819
								c-0.587,0.353-1.166,0.688-1.78,0.949c0.09-0.208,0.181-0.407,0.271-0.614c0.416-0.958,0.858-1.926,1.292-2.938
								c1.898-4.474,3.524-9.833,1.672-19.042c-0.009-0.027-0.009-0.055-0.018-0.081c-0.082-0.38-0.163-0.769-0.253-1.166
								c-0.226-0.977,0.235-1.365,1.121-1.419c0.352-0.019,0.768,0.009,1.247,0.081c0.85,0.117,1.88,0.361,2.991,0.633
								c2.431,0.569,5.251,1.256,7.474,1.012c8.775-0.958,10.709-5.757,10.501-0.958c0,0.063-0.009,0.136-0.009,0.199
								c-0.19,4.546-0.199,8.306-0.009,11.433c0.145,2.684,0.443,4.89,0.868,6.732v0.01c0.154,0.678,0.334,1.301,0.524,1.88
								c0.063,0.181,0.127,0.361,0.19,0.533C273.342,514.629,273.849,515.587,274.436,516.4z"/>
							<linearGradient id="SVGID_31_" gradientUnits="userSpaceOnUse" x1="239.7983" y1="474.7236" x2="278.8994" y2="474.7236">
								<stop  offset="0" style="stop-color:#B85D40"/>
								<stop  offset="1" style="stop-color:#EF7432"/>
							</linearGradient>
							<path fill="url(#SVGID_31_)" d="M278.892,473.635c-0.027,1.211-0.145,2.44-0.38,3.669c-0.289,1.618-0.768,3.235-1.455,4.817
								c-0.226,0.515-0.47,1.021-0.732,1.527c-1.301,2.485-2.169,4.537-3.055,6.254c-0.587,1.139-1.184,2.124-1.925,2.991
								c-2.178,2.612-5.64,4.13-13.918,5.305c-1.636,0-3.136-0.307-4.474-0.804c-0.65-0.235-1.265-0.524-1.834-0.841
								c-1.066-0.569-2.015-1.256-2.829-1.961c-0.027-0.009-0.045-0.027-0.063-0.045c-1.084-0.94-1.943-1.898-2.566-2.685
								c-1.03-1.301-2.16-3.615-3.154-6.471c-1.853-5.287-3.208-12.427-2.53-18.491c0.397-3.569,1.5-6.769,3.633-8.992
								c0,0,0,0,0.009-0.009c3.19-3.316,8.007-5.893,16.123-6.598c5.513-0.479,9.86,2.305,13.005,5.775
								C276.334,460.494,279.063,466.839,278.892,473.635z"/>
						</g>
						<linearGradient id="SVGID_32_" gradientUnits="userSpaceOnUse" x1="247.9067" y1="527.9541" x2="270.7703" y2="508.4136">
							<stop  offset="0" style="stop-color:#A3BEE3"/>
							<stop  offset="1" style="stop-color:#C1E5F4"/>
						</linearGradient>
						<path fill="url(#SVGID_32_)" d="M278.9,517.928l-3.479,0.045l-22.051,0.289l-13.592,0.181
							c0.045-0.009,0.163-0.117,0.361-0.298c0.822-0.723,3.009-2.729,6.48-4.51c1.78-0.922,3.895-1.78,6.344-2.368
							c1.437-0.361,2.991-0.623,4.654-0.75c5.432-0.425,10.393,0.741,14.243,2.477c0.009-0.009,0.009-0.009,0.009,0
							c0.362,0.153,0.705,0.325,1.048,0.488c0.19,0.099,0.371,0.189,0.551,0.289c2.377,1.265,4.221,2.711,5.323,4.03
							C278.828,517.846,278.864,517.882,278.9,517.928z"/>
					</g>
					<linearGradient id="SVGID_33_" gradientUnits="userSpaceOnUse" x1="229.3945" y1="624.3008" x2="348.293" y2="624.3008">
						<stop  offset="0" style="stop-color:#6B73FF"/>
						<stop  offset="1" style="stop-color:#000DFF"/>
					</linearGradient>
					<path fill="url(#SVGID_33_)" d="M313.77,582.131c-5.361,3.707-10.583-15.765-31.709,0.34
						c-14.395,10.973-40.587,40.325-47.054,47c-10.74,12.49-6.308,33.93,15.406,40.97c5.457,1.77,27.262,2.778,34.183,1.755
						c5.996-0.887,16.519-3.424,24.787-8.733c5.043-3.239,13.566-13.834,22.664-26.312c7.524-10.318,16.818-3.541,16.22-36.971
						C348.048,588.046,329.974,570.927,313.77,582.131z"/>
					
						<linearGradient id="SVGID_34_" gradientUnits="userSpaceOnUse" x1="228.7651" y1="532.8164" x2="228.7651" y2="618.6172" gradientTransform="matrix(0.9999 -0.0145 0.0145 0.9999 -5.9582 -4.4044)">
						<stop  offset="0" style="stop-color:#B7D2F7"/>
						<stop  offset="1" style="stop-color:#D5F9FF"/>
					</linearGradient>
					<path fill="url(#SVGID_34_)" d="M217.858,543.121c-4.215,19.27-1.917,29.994-0.835,44.862
						c0.434,5.97,0.736,23.379,12.148,22.884c9.938-0.432,14.206-14.154,15.169-20.23c1.854-11.687,2.606-23.678,2.262-35.396
						c-0.26-8.854-5.751-25.55-16.077-29.982C226.61,523.578,220.155,532.62,217.858,543.121z"/>
					<linearGradient id="SVGID_35_" gradientUnits="userSpaceOnUse" x1="264.2417" y1="515.1299" x2="264.2417" y2="653.1484">
						<stop  offset="0" style="stop-color:#A3BEE3"/>
						<stop  offset="1" style="stop-color:#C1E5F4"/>
					</linearGradient>
					<path fill="url(#SVGID_35_)" d="M305.19,647.227c-0.054,1.971-63.479,13.593-74.993-2.403
						c-0.307-0.435-0.569-0.886-0.777-1.356c-3.795-8.449,8.197-22.322,6.842-31.224c-0.208-1.393-0.407-2.702-0.587-3.932
						c-2.775-18.563-2.485-19.006-3.914-29.064c-1.265-8.975-13.158-41.374-6.471-50.258c1.039-1.383,2.187-2.657,3.416-3.813
						c1.22-1.166,2.521-2.215,3.914-3.154c2.295-1.572,4.817-2.855,7.519-3.877c1.663-0.624,3.398-1.147,5.188-1.572
						c0.497-0.127,1.003-0.235,1.509-0.335c2.042-0.425,4.157-0.714,6.344-0.903c6.661-0.543,13.909,0.009,21.419,1.527
						c1.392,0.271,2.792,0.587,4.193,0.939c2.368,0.578,4.754,1.257,7.148,2.016c2.919,0.931,5.125,2.224,6.769,3.832
						c0.009,0.009,0.027,0.018,0.037,0.036c3.759,3.696,4.563,9.046,4.121,15.508c-0.488,6.933-2.413,15.147-3.651,23.95
						c-0.705,4.979-1.22,9.624-1.591,13.881c-0.777,8.83-0.949,15.979-0.976,20.886c-0.027,2.521,0,4.456,0,5.721
						C290.649,620.25,305.986,618.099,305.19,647.227z"/>
					<linearGradient id="SVGID_36_" gradientUnits="userSpaceOnUse" x1="297.3379" y1="522.6553" x2="297.3379" y2="603.6338">
						<stop  offset="0" style="stop-color:#B7D2F7"/>
						<stop  offset="1" style="stop-color:#D5F9FF"/>
					</linearGradient>
					<path fill="url(#SVGID_36_)" d="M309.33,581.353c-0.723,7.862-3.425,20.669-11.143,22.142c-4.049,0.777-6.29-1.771-7.537-5.585
						c-2.043-6.199-1.464-15.734-1.835-19.711c-0.027-0.343-0.072-0.65-0.117-0.903c-2.82-14.984-3.281-23.932-3.561-36.674
						c-0.145-6.562,0.416-10.908,1.311-13.684c1.654-5.061,4.456-4.88,6.263-3.289c0.009,0.009,0.027,0.018,0.037,0.036
						c0.018,0.009,0.027,0.018,0.045,0.036c6.236,5.757,10.601,15.652,12.427,22.955c1.084,4.32,2.024,8.73,2.738,13.177
						c0.895,5.513,1.473,11.08,1.6,16.593C309.583,577.529,309.528,579.265,309.33,581.353z"/>
					<linearGradient id="SVGID_37_" gradientUnits="userSpaceOnUse" x1="230.3818" y1="506.4746" x2="263.1782" y2="506.4746">
						<stop  offset="0" style="stop-color:#B85D40"/>
						<stop  offset="1" style="stop-color:#EF7432"/>
					</linearGradient>
					<path fill="url(#SVGID_37_)" d="M258.602,465.805c-7.693-2.652-15.51-2.888-21.911,3.886c-6.05,6.403-6.118,15.929-5.228,24.23
						c0.733,6.84,2.849,28.854-1.082,52.386c1.379,1.29,7.154,2.246,9.751,2.567c0.61,0.075,1.548-23.122,1.412-23.817
						c0.437,1.487,0.854,23.84,1.653,23.817c2.018-0.06,4.152-0.256,6.096-0.499c0.27-0.033,0.405-5.549,0.319-5.884
						c0.128,0.249,1.024,5.706,1.289,5.668c1.727-0.249,3.201-0.516,4.155-0.719c1.744-11.406-9.456-63.036,6.149-71.211
						C265.721,473.864,261.461,467.731,258.602,465.805z"/>
				</g>
				<g>
					<g>
						<path d="M314.324,641.824c0.436,4.438,0.667,8.896,1.068,13.338c0.314,3.479,0.368,7.544,1.677,10.828
							c4.552,11.416,16.546,0.131,21.458-4.214c2.62-2.316-1.228-6.14-3.834-3.834c-2.39,2.113-6.92,7.825-10.439,7.577
							c-2.913-0.205-2.809-4.566-3.056-6.591c-0.692-5.682-0.893-11.41-1.452-17.104
							C319.409,638.383,313.983,638.348,314.324,641.824L314.324,641.824z"/>
					</g>
					<g>
						<path d="M212.444,641.824c0.788,7.07,1.951,14.259,4.224,21.021c1.468,4.366,4.751,8.326,9.805,6.604
							c3.285-1.119,1.874-6.358-1.442-5.229c-4.413,1.504-6.895-19.977-7.165-22.396
							C217.484,638.395,212.057,638.355,212.444,641.824L212.444,641.824z"/>
					</g>
					<path fill="#62667C" d="M217.071,646.66c25.299,16.146,82.193,14.409,101.553,0.55c1.602-1.146,4.695-2.649,4.44-4.523
						l-6.345-46.82c-1.088-8.028-9.104-16.679-17.247-14.467c-20.459,5.556-60.177,8.374-75.947-0.553
						c-7.342-4.156-17.459,9.576-16.371,17.604l6.457,44.732C213.866,645.059,215.411,645.601,217.071,646.66z"/>
					<path fill="#434556" d="M216.614,647.429c24.133,15.02,80.758,14.493,99.226,1.602c1.528-1.066,3.206-1.428,2.963-3.172
						l-7.132-45.678c-1.038-7.468-8.685-15.514-16.452-13.457c-19.517,5.168-60.222,6.323-75.265-1.981
						c-7.004-3.866-13.731,8.434-12.693,15.901l6.053,43.552C213.557,645.938,215.031,646.443,216.614,647.429z"/>
				</g>
				<path opacity="0.2" fill="#ECF2FA" d="M278.892,473.635c-0.19-0.37-0.462-0.731-0.588-1.175
					c-6.01-21.211-27.608-18.977-34.686-14.56c3.19-3.316,8.007-5.893,16.123-6.598c5.513-0.479,9.86,2.305,13.005,5.775
					C276.334,460.494,279.063,466.839,278.892,473.635z"/>
				<path opacity="0.4" fill="#ECF2FA" d="M272.204,511.068c0.154,0.678,0.334,1.301,0.524,1.88c-0.46-0.263-0.795-0.01-0.858,0.045
					c-0.009,0.009-0.009,0.009-0.009,0.009v-0.009L272.204,511.068z"/>
				<path opacity="0.4" fill="#ECF2FA" d="M307.956,559.853c-3.669-8.64-7.936-25.848-19.112-34.796
					c-5.505-4.408-11.607-5.177-13.423-7.084c-0.361-0.38-0.642-0.741-0.822-1.111c-0.063-0.154-0.126-0.308-0.163-0.461
					c-0.316-1.266-0.651-2.097-0.967-2.63c2.377,1.265,4.221,2.711,5.323,4.03c2.368,0.578,4.754,1.257,7.148,2.016
					c2.919,0.931,5.125,2.224,6.769,3.832c0.009,0.009,0.027,0.018,0.037,0.036c0.018,0.009,0.027,0.018,0.045,0.036
					c6.236,5.757,10.601,15.652,12.427,22.955C306.302,550.996,307.242,555.406,307.956,559.853z"/>
			</g>
			<g>
				<g>
					<g>
						<path d="M407.766,715.848c-14.647,1.83-32.005-6.311-36.841-20.826c-1.398,3.035,0.728,8.079,2.582,10.62
							c3.987,5.464,8.663,7.709,11.485,8.13c2.108,0.313,0.589-0.608,2.607-0.397c4.765,0.498-0.427,6.74,16.705,7.261
							c3.446,0.104,5.15-2.621,5.681-5.728C409.282,715.578,408.261,715.848,407.766,715.848z"/>
						<linearGradient id="SVGID_38_" gradientUnits="userSpaceOnUse" x1="370.9248" y1="702.4727" x2="410.1768" y2="702.4727">
							<stop  offset="0" style="stop-color:#1E1E1E"/>
							<stop  offset="1" style="stop-color:#63322B"/>
						</linearGradient>
						<path fill="url(#SVGID_38_)" d="M410.167,712.13c-0.131-3.558,0.248-16.687-2.465-18.225
							c-2.842-3.208-11.528-6.895-15.805-4.035c-11.3,7.557-13.804,1.969-17.86,2.849c-1.63,0.354-2.603,1.196-3.112,2.303
							c4.836,14.516,22.194,22.656,36.841,20.826c0.495,0,1.516-0.27,2.219-0.94C410.142,713.993,410.201,713.048,410.167,712.13z"
							/>
						<path fill="#434556" d="M407.574,698.949c-3.211,4.017-13.765,1.883-16.614-1.606c-2.566-3.144-3.063-8.235-3.745-12.795
							c-0.624-4.173,0.492-6.014,4.917-7.031c3.594-0.825,10.199-0.572,13.278,1.748
							C407.281,680.676,409.469,696.578,407.574,698.949z"/>
						<path fill="#1E1E1E" d="M372.547,594.523c-1.179,7.125,4.385,37.679,6.408,59.444c0.921,9.916,1.59,26.103,1.993,36.049
							c7.309,5.415,25.007,6.32,32.534,0.462c0.053-6.895-1.393-25.732-1.722-32.599c-0.415-8.661,0.966-17.289,0.487-25.978
							c-0.582-10.554-2.541-22.533-7.022-32.213c-4.16-8.987-11.441-14.434-21.691-14.652
							C379.791,584.957,373.656,587.818,372.547,594.523z"/>
					</g>
					<g>
						<linearGradient id="SVGID_39_" gradientUnits="userSpaceOnUse" x1="477.5732" y1="706.1621" x2="527.9883" y2="706.1621">
							<stop  offset="0" style="stop-color:#1E1E1E"/>
							<stop  offset="1" style="stop-color:#63322B"/>
						</linearGradient>
						<path fill="url(#SVGID_39_)" d="M527.47,702.317c1.932,0.634-2.146-2.414-2.245-2.425c-4.785-0.505-9.36,2.544-14.631,1.2
							c-5.332-1.359-9.255-5.897-14.76-7.146c-5.017-1.138-12.962,0.826-15.804,4.034c-2.645,1.5-2.352,14.004-2.457,17.932
							c4.992,6.004,12.633,0.471,16.541,0.292c5.33-0.242,14.816-0.546,19.607-2.291
							C519.882,711.671,523.157,706.756,527.47,702.317z"/>
						<path d="M527.47,702.317c-7.619,7.841-12.65,13.183-33.114,13.862c-4.128,0.138-13.178,4.696-16.782-0.267
							c-0.002,0.103-0.005,0.203-0.009,0.294c-0.148,4.035-1.552,7.662,2.908,7.526c17.132-0.52,11.27-6.253,16.034-6.752
							c2.018-0.211,3.063,1.745,5.172,1.431c2.822-0.42,5.872-0.846,8.563-1.73c5.877-1.933,9.027-2.304,13.623-6.482
							C526.584,707.727,531.002,704.055,527.47,702.317z"/>
						<path fill="#434556" d="M496.964,698.151c2.566-3.143,3.064-8.234,3.745-12.794c0.624-4.174-0.492-6.015-4.917-7.031
							c-3.594-0.826-10.199-0.573-13.278,1.748c-1.871,1.41-4.059,17.313-2.164,19.685
							C483.561,703.775,494.115,701.642,496.964,698.151z"/>
						<path fill="#1E1E1E" d="M516.189,608.528c-1.229,11.288-3.76,27.51-5.296,41.455c-0.208,1.871-0.398,3.696-0.561,5.468
							c-0.922,9.923-1.59,26.109-1.997,36.051c-2.25,1.672-5.495,2.91-9.119,3.65c-0.199,0.046-0.397,0.091-0.596,0.118
							c-4.69,0.886-9.959,0.958-14.542,0.072c-1.573-0.299-3.064-0.714-4.428-1.257c-0.732-0.279-1.419-0.605-2.07-0.967
							c-0.642-0.344-1.238-0.731-1.78-1.156c-0.045-5.45,0.85-18.338,1.392-26.905c0.082-1.283,0.154-2.467,0.217-3.524
							c0.045-0.813,0.09-1.536,0.117-2.169c0.108-2.269,0.09-4.546,0.018-6.814c-0.099-3.253-0.316-6.507-0.461-9.77
							c-0.145-3.127-0.217-6.254-0.045-9.39c0.099-1.88,0.253-3.814,0.452-5.766c0.09-0.94,0.199-1.89,0.316-2.838
							c1.021-8.125,2.955-16.494,6.254-23.615c2.214-4.781,5.432-9.896,9.499-13.665c3.561-3.298,7.763-5.566,12.517-5.702
							c0.037-0.009,0.073-0.009,0.109-0.009c0.678-0.01,1.418,0.226,2.187,0.668c0.009,0,0.009,0.01,0.018,0.01
							c1.491,0.858,3.091,2.476,4.491,4.482c1.835,2.639,3.344,5.946,3.859,9.056C517.111,598.234,516.821,602.727,516.189,608.528z
							"/>
					</g>
					<g>
						<g>
							<path d="M500.118,636.192c-7.086,5.752-14.479,11.847-18.705,20.13c-4.574,8.964-4.784,19.431-5.779,29.24
								c-2.294,22.604-4.53,45.213-6.5,67.846c-0.302,3.477,5.122,3.456,5.423,0c1.819-20.9,3.866-41.78,5.974-62.653
								c1.024-10.144,0.8-21.695,5.258-31.063c3.896-8.187,11.278-14.076,18.164-19.666
								C506.663,637.826,502.806,634.011,500.118,636.192L500.118,636.192z"/>
						</g>
						<g>
							<path d="M392.132,636.192c-7.086,5.752-14.479,11.847-18.705,20.13c-4.574,8.964-4.783,19.431-5.779,29.24
								c-2.294,22.604-4.53,45.213-6.5,67.846c-0.303,3.477,5.122,3.456,5.422,0c1.819-20.9,3.866-41.78,5.974-62.653
								c1.025-10.144,0.8-21.695,5.258-31.063c3.896-8.187,11.278-14.076,18.164-19.666
								C398.677,637.826,394.819,634.011,392.132,636.192L392.132,636.192z"/>
						</g>
						<g>
							<path d="M502.737,639.83c5.117,2.722,5.412,9.307,5.874,14.437c0.587,6.523,1.184,13.046,1.808,19.566
								c1.279,13.36,2.613,26.716,3.843,40.081c0.317,3.446,5.743,3.479,5.422,0c-1.438-15.619-3.02-31.225-4.483-46.842
								c-0.622-6.628-0.766-13.474-2.04-20.018c-0.96-4.926-3.108-9.472-7.686-11.907
								C502.395,633.51,499.652,638.188,502.737,639.83L502.737,639.83z"/>
						</g>
						<g>
							<path d="M405.557,639.83c5.117,2.722,5.412,9.307,5.874,14.437c0.587,6.523,1.184,13.046,1.808,19.566
								c1.279,13.36,2.613,26.716,3.843,40.081c0.317,3.446,5.743,3.479,5.423,0c-1.438-15.619-3.02-31.225-4.484-46.842
								c-0.621-6.628-0.765-13.474-2.04-20.018c-0.959-4.926-3.107-9.472-7.686-11.907
								C405.214,633.51,402.472,638.188,405.557,639.83L405.557,639.83z"/>
						</g>
						<g>
							<path fill="#434556" d="M499.122,625.541l-83.98,8.1c-7.862-0.058-18.587,3.588-23.957,8.144l-18.801,10.345
								c-5.716,4.85-0.913,17.913,11.137,22.046c15.448,5.299,37.108,6.162,62.988,1.526c7.945-1.423,21.5-6.191,27.216-11.04
								l26.03-22.083C508.115,637.156,506.983,625.599,499.122,625.541z"/>
							<path fill="#62667C" d="M495.758,624.921l-80.102-0.586c-7.861-0.058-18.587,3.589-23.957,8.145l-16.979,14.403
								c-5.717,4.85-2.735,13.854,9.314,17.987c15.448,5.298,37.108,6.162,62.988,1.526c7.946-1.423,21.5-6.191,27.216-11.041
								l26.03-22.082C505.639,628.718,503.619,624.979,495.758,624.921z"/>
						</g>
					</g>
					<g>
						
							<linearGradient id="SVGID_40_" gradientUnits="userSpaceOnUse" x1="343.4819" y1="635.334" x2="402.3784" y2="635.334" gradientTransform="matrix(-0.8013 -0.5982 -0.5982 0.8013 1061.5391 352.4903)">
							<stop  offset="0" style="stop-color:#6B73FF"/>
							<stop  offset="1" style="stop-color:#000DFF"/>
						</linearGradient>
						<path fill="url(#SVGID_40_)" d="M393.861,627.95c-3.798-4.412-15.942-18.657-22.54-17.944
							c-7.167,0.773-10.521,8.26-10.166,13.602c0.423,6.364,2.986,11.498,6.12,19.463c2.813,7.146,14.74,20.846,26.773,23.517
							C406.479,669.346,404.044,639.779,393.861,627.95z"/>
						<g>
							<g>
								<path fill="#FDB9B1" d="M405.474,479.395c-0.176,8.596-8.404-1.392-8.559-4.246s1.472-2.301,3.505-2.388
									C402.453,472.673,405.532,476.536,405.474,479.395z"/>
								<path fill="#FED0CA" d="M437.431,473.749c-1.291,8.584-3.139,13.466-8.184,23.548c-0.017,0.033-0.971,1.024-1.021,1.041
									c-0.833,0.458-5.474-0.137-6.057-0.478c-14.703-8.451-14.512-10.832-15.328-16.244c-0.191-1.298-0.45-2.764-0.974-4.537
									c-1.832-6.145-3.014-16.685,0.616-21.639c1.832-2.506,4.763-4.479,8.159-4.479c1.499,0.134,8.643-0.815,14.737,3.105
									C435.474,457.979,438.929,463.699,437.431,473.749z"/>
								<path fill="#FDB9B1" d="M431.074,479.395c0.176,8.596,5.201,0.409,6.359-2.627c1.019-2.671,1.958-5.861-0.075-5.949
									S431.015,476.536,431.074,479.395z"/>
								<path fill="#FDB9B1" d="M430.161,507.625c-0.615-0.244-1.22-0.471-1.808-0.688c-21.355-7.971-24.365-2.53-29.39-0.876
									c0.633-1.157,1.292-2.26,1.916-3.398c2.187-3.968,3.995-8.387,2.982-17.307c-0.082-0.678-0.181-1.383-0.289-2.115
									c-0.027-0.153-0.027-0.307-0.018-0.442c0.054-0.813,0.696-1.319,1.699-1.591c0.434-0.108,0.94-0.181,1.5-0.208
									c3.127-0.162,7.845,0.949,10.908,2.277c8.197,3.543,10.673-4.256,10.321,0.66c-0.009,0.127-0.018,0.262-0.027,0.397
									c-0.316,4.591-0.361,9.706,0.009,14.09c0.136,1.519,0.316,2.955,0.551,4.257c0.072,0.389,0.154,0.768,0.235,1.12
									c0.298,1.302,0.66,2.422,1.112,3.299c0.054,0.108,0.117,0.217,0.181,0.325C430.08,507.498,430.125,507.562,430.161,507.625z
									"/>
								<linearGradient id="SVGID_41_" gradientUnits="userSpaceOnUse" x1="392.3877" y1="452.1953" x2="442.9589" y2="452.1953">
									<stop  offset="0" style="stop-color:#B85D40"/>
									<stop  offset="1" style="stop-color:#EF7432"/>
								</linearGradient>
								<path fill="url(#SVGID_41_)" d="M440.934,450.463c-0.569,0.813-1.157,1.59-1.762,2.341c-1.6,1.979-3.344,3.723-5.179,5.224
									c-6.661,5.485-14.595,7.88-22.097,7.41c-3.163-0.198-5.693-0.939-7.727-1.997c-5.287-2.729-7.23-7.592-8.179-10.564
									c0.054-0.063,0.108-0.127,0.172-0.19c0.434-1.373,0.967-2.684,1.618-3.913v-0.009c4.021-7.664,12.336-12.237,26.471-8.595
									C429.854,441.615,438.647,444.732,440.934,450.463z"/>
								<linearGradient id="SVGID_42_" gradientUnits="userSpaceOnUse" x1="392.3911" y1="465.5918" x2="442.957" y2="465.5918">
									<stop  offset="0" style="stop-color:#B85D40"/>
									<stop  offset="1" style="stop-color:#EF7432"/>
								</linearGradient>
								<path fill="url(#SVGID_42_)" d="M439.009,469.803v0.009c-0.344,0.416-0.678,0.813-1.021,1.185
									c-0.09,0.108-0.19,0.217-0.289,0.325c-1.347,1.473-2.503,2.513-2.802,2.955c-1.527,2.277-2.756,4.094-3.805,5.612
									c-1.256,1.825-2.25,3.218-3.136,4.446c-1.834,2.549-3.244,4.393-5.793,7.953c-2.801-0.66-6.344-0.587-9.39-0.461
									c-2.802,0.117-5.188,0.289-6.227-0.009c-0.94-1.021-1.798-3.778-2.684-6.444c0-0.009-0.009-0.009,0-0.018
									c-0.325-0.949-0.642-1.88-0.976-2.721c-0.379-0.976-0.768-1.816-1.184-2.413c-1.175-1.681-2.964-3.903-4.664-6.452
									c-4.031-6.046-7.546-13.937-1.048-20.895c0.054-0.063,0.108-0.127,0.172-0.19c0.434-1.373,0.967-2.684,1.618-3.913v-0.009
									c4.021-7.664,12.336-12.237,26.471-8.595c5.603,1.446,14.396,4.563,16.683,10.294c0.569,1.428,0.741,3.027,0.353,4.799
									C444.883,460.774,441.991,466.143,439.009,469.803z"/>
							</g>
							<linearGradient id="SVGID_43_" gradientUnits="userSpaceOnUse" x1="413.6313" y1="508.167" x2="413.6313" y2="500.25">
								<stop  offset="0" style="stop-color:#1E1E1E"/>
								<stop  offset="1" style="stop-color:#63322B"/>
							</linearGradient>
							<path fill="url(#SVGID_43_)" d="M434.219,508.167l-2.467-0.054l-38.708-0.832c0.235-0.045,3.01-2.603,7.835-4.618
								c3.154-1.328,7.185-2.413,11.948-2.413c6.299,0,11.64,1.401,15.544,3.354c0.126,0.063,0.253,0.135,0.379,0.198
								c2.205,1.147,3.922,2.477,5.034,3.813C433.948,507.797,434.083,507.986,434.219,508.167z"/>
						</g>
						<path fill="#1E1E1E" d="M514.02,597.259c-0.534,4.157-2.088,8.612-4.636,12.598c-2.061,3.218-7.14,8.785-13.43,15.075
							c-0.334,0.344-0.669,0.678-1.012,1.012c-5.513,5.486-11.857,11.441-17.858,16.837c-4.79,4.293-9.363,8.233-13.131,11.297
							c-3.009,2.432-5.504,4.302-7.203,5.351c-8.794,5.449-19.982,8.053-26.354,8.956c-3.046,0.434-8.812,0.515-14.93,0.325
							c-1.012-0.027-2.033-0.072-3.063-0.117c-7.7-0.335-15.355-1.066-18.355-2.007c-6.254-1.961-11.17-5.007-14.758-8.657
							c-0.813-0.805-1.555-1.654-2.223-2.521c-0.434-0.561-0.841-1.13-1.22-1.709c-0.199-0.307-0.397-0.614-0.579-0.921
							c-0.343-0.561-0.66-1.14-0.94-1.718c-0.425-0.831-0.786-1.681-1.103-2.539c-2.422-6.507-1.925-13.548,1.311-19.503
							c0,0-0.009,0,0-0.01c0.353-0.632,0.732-1.265,1.148-1.87c0.596-0.895,1.256-1.744,1.988-2.566
							c2.63-2.621,6.335-3.416,10.556-2.929c4.446,0.497,9.462,2.413,14.378,5.088c2.603,1.41,5.179,3.046,7.637,4.79
							c0.542-0.967,1.139-1.934,1.78-2.892c0.949-1.446,2.006-2.874,3.154-4.293c9.309-11.532,24.32-21.934,37.931-28.414
							c0.416-0.208,0.822-0.397,1.238-0.587c0.416-0.199,0.822-0.38,1.229-0.569c1.817-0.813,3.597-1.546,5.332-2.206
							c0.235-0.09,0.47-0.181,0.705-0.271c3.353-1.266,6.48-2.404,9.381-3.416c3.389-1.193,6.489-2.224,9.318-3.1
							c13.412-4.167,20.895-4.971,25.766-3.968c0.841,0.162,1.6,0.389,2.296,0.659c0.009,0,0.009,0.01,0.018,0.01
							c2.024,0.786,3.524,2.377,4.491,4.482C514.155,589.712,514.535,593.354,514.02,597.259z"/>
						
							<linearGradient id="SVGID_44_" gradientUnits="userSpaceOnUse" x1="355.8096" y1="559.8008" x2="389.9575" y2="557.6224" gradientTransform="matrix(0.998 0.0637 -0.0637 0.998 32.3635 -20.4209)">
							<stop  offset="0" style="stop-color:#63322B"/>
							<stop  offset="0.5108" style="stop-color:#1E1E1E"/>
						</linearGradient>
						<path fill="url(#SVGID_44_)" d="M356.528,534.61c-6.068,19.363-4.523,30.513-4.612,45.8
							c-0.036,6.139-1.162,23.961,10.976,24.368c10.57,0.355,16.234-13.333,17.76-19.467c2.935-11.8,4.729-23.998,5.337-36.006
							c0.46-9.074-3.974-26.582-14.551-31.941C367.428,515.332,359.834,524.059,356.528,534.61z"/>
						<linearGradient id="SVGID_45_" gradientUnits="userSpaceOnUse" x1="413.9419" y1="646.8711" x2="413.9419" y2="506.0244">
							<stop  offset="0" style="stop-color:#1E1E1E"/>
							<stop  offset="1" style="stop-color:#63322B"/>
						</linearGradient>
						<path fill="url(#SVGID_45_)" d="M452.872,596.762c-3.904,13.05,9.643,29.57,3.823,44.5
							c-0.732,1.88-67.618,13.322-79.702-3.189c-6.236-8.514,1.473-26.037,0.045-35.681c-3.407-23.081-10.89-30.447-12.399-41.464
							c-1.256-9.209-5.45-33.194,1.744-42.232c4.754-5.974,14.677-9.643,26.597-11.396c0.045-0.01,0.063-0.019,0.063-0.019
							c10.917-1.608,23.488-1.617,35.31-0.344c0.407,0.036,0.813,0.082,1.22,0.127c0.1,0.009,0.19,0.018,0.29,0.036
							c1.319,0.153,2.63,0.325,3.922,0.515c7.365,1.058,14.27,2.612,20.081,4.601c3.633,1.238,6.272,2.468,8.106,4.311
							c2.431,2.432,3.479,5.956,3.615,12.021c0.072,3.28-0.118,7.311-0.497,12.327c-0.804,10.664-7.456,37.894-10.673,50.194
							C453.686,593.843,453.134,595.867,452.872,596.762z"/>
						<linearGradient id="SVGID_46_" gradientUnits="userSpaceOnUse" x1="449.8223" y1="564.1328" x2="482.3563" y2="567.7397">
							<stop  offset="0" style="stop-color:#1E1E1E"/>
							<stop  offset="1" style="stop-color:#63322B"/>
						</linearGradient>
						<path fill="url(#SVGID_46_)" d="M480.617,582.455c0,0.235,0,0.479-0.009,0.714c-0.018,0.678-0.127,1.563-0.298,2.604
							c-0.307,1.834-0.85,4.147-1.6,6.66c-2.495,8.342-7.293,18.889-13.628,21.419c-0.669,0.271-1.365,0.452-2.07,0.524
							c-1.265,0.135-2.359-0.046-3.29-0.507c-4.573-2.178-5.386-10.682-5.377-18.535c0-0.805,0.009-1.6,0.027-2.387
							c0.009-0.632,0.027-1.265,0.045-1.879c0.082-2.956,0.217-5.612,0.208-7.502c0-0.704-0.027-1.301-0.082-1.771
							c-2.061-18.338-1.997-29.155-1.5-44.492c0.271-8.423,1.59-13.7,3.281-16.827c1.735-3.218,3.859-4.167,5.648-3.95
							c1.139,0.145,2.142,0.769,2.82,1.591c6.281,7.628,9.517,16.331,11.008,25.314c1.311,7.926,3.172,17.587,4.157,26.715
							C480.428,574.448,480.699,578.633,480.617,582.455z"/>
						<path opacity="0.2" fill="#ECF2FA" d="M479.958,570.146c-1.681-7.854-8.676-32.436-14.37-41.6
							c-0.551-0.895-1.103-1.646-1.627-2.215c-2.413-2.62-4.292-4.049-7.266-5.657c-0.045-0.018-0.171-0.091-0.37-0.198
							c-2.675-1.41-18.5-9.697-24.573-12.363c-0.723-0.316-1.301-0.552-1.708-0.688c-0.163-0.054-0.298-0.1-0.407-0.117
							c-0.018-0.081-0.036-0.163-0.063-0.244c-0.316-1.139-1.12-2.377-1.202-3.461c-0.027-0.326,0.009-0.633,0.145-0.922
							c0.072,0.389,0.154,0.768,0.235,1.12c2.205,1.147,3.922,2.477,5.034,3.813c7.365,1.058,14.27,2.612,20.081,4.601
							c3.633,1.238,6.272,2.468,8.106,4.311c1.139,0.145,2.142,0.769,2.82,1.591c6.281,7.628,9.517,16.331,11.008,25.314
							C477.111,551.357,478.973,561.019,479.958,570.146z"/>
						<path opacity="0.2" fill="#ECF2FA" d="M392.98,507.3c-1.247,0.415-21.355,7.067-26.597,11.396
							C371.137,512.722,381.061,509.053,392.98,507.3z"/>
					</g>
					<g>
						<g>
							<path d="M460.297,642.224c0.436,4.438,0.667,8.896,1.068,13.338c0.314,3.479,0.367,7.544,1.677,10.828
								c4.552,11.416,16.546,0.131,21.458-4.214c2.619-2.316-1.228-6.14-3.834-3.834c-2.39,2.113-6.92,7.825-10.439,7.577
								c-2.913-0.205-2.809-4.566-3.055-6.591c-0.693-5.682-0.893-11.41-1.452-17.104
								C465.382,638.782,459.956,638.747,460.297,642.224L460.297,642.224z"/>
						</g>
						<g>
							<path d="M358.417,642.224c0.788,7.07,1.95,14.259,4.224,21.021c1.468,4.366,4.751,8.326,9.805,6.604
								c3.285-1.119,1.875-6.358-1.441-5.229c-4.413,1.504-6.896-19.978-7.165-22.396
								C363.458,638.794,358.031,638.755,358.417,642.224L358.417,642.224z"/>
						</g>
						<path fill="#62667C" d="M363.045,647.06c25.299,16.147,82.193,14.409,101.553,0.55c1.602-1.146,4.694-2.649,4.44-4.523
							l-6.345-46.82c-1.088-8.028-9.104-16.679-17.247-14.467c-20.459,5.556-60.178,8.374-75.947-0.553
							c-7.342-4.156-17.459,9.576-16.371,17.604l6.457,44.732C359.839,645.458,361.384,646,363.045,647.06z"/>
						<path fill="#434556" d="M362.588,647.828c24.133,15.02,80.757,14.493,99.226,1.602c1.527-1.066,3.205-1.429,2.963-3.172
							l-7.132-45.678c-1.038-7.468-8.685-15.514-16.452-13.457c-19.517,5.168-60.221,6.322-75.264-1.981
							c-7.004-3.866-13.731,8.434-12.694,15.901l6.053,43.552C359.53,646.338,361.004,646.843,362.588,647.828z"/>
					</g>
				</g>
				<path opacity="0.2" fill="#ECF2FA" d="M516.189,608.528v-0.054c-0.181-4.618-0.958-8.297-2.169-11.216
					c-3.977-9.652-12.689-10.917-20.461-9.752c-7.908,1.202-14.849,4.926-14.849,4.926s1.232-4.062,1.6-6.66
					c13.412-4.167,20.895-4.971,25.766-3.968c0.037-0.009,0.073-0.009,0.109-0.009c0.678-0.01,1.418,0.226,2.187,0.668
					c0.009,0,0.009,0.01,0.018,0.01c2.024,0.786,3.524,2.377,4.491,4.482c1.835,2.639,3.344,5.946,3.859,9.056
					C517.111,598.234,516.821,602.727,516.189,608.528z"/>
				<path opacity="0.2" fill="#ECF2FA" d="M441.286,455.262c-0.687-0.877-1.392-1.699-2.115-2.458
					c-17.189-18.292-41.392-4.031-41.392-4.031v-0.009c4.021-7.664,12.336-12.237,26.471-8.595
					c5.603,1.446,14.396,4.563,16.683,10.294C441.503,451.891,441.675,453.49,441.286,455.262z"/>
			</g>
		</g>
	</g>
	<g>
		<path fill="#ECF2FA" d="M721.391,756.693l384.603-0.527c29.142,0.225,38.238,3.582,30.684-9.975l-122.265-97.364
			c-2.883-2.556-6.619-3.938-10.47-3.871l-351.167,4.006c-12.906,0.224-35.087,0.926-26.834,10.85l78.094,93.519
			C706.935,756.816,709.566,756.693,721.391,756.693z"/>
		<g>
			<g>
				<g>
					<g>
						<path fill="#FDB9B1" d="M813.988,653.122c0.462-0.399,0.918-0.835,1.371-1.288c1.197-1.198,8.45-16.773,7.443-22.839
							c-0.943-4.536-1.668-9.065-2.476-13.668c-1.745-9.95-3.595-20.096-6.001-29.952c-2.517-10.306-4.742-31.177,0.452-34.797
							c3.591-2.503,6.548-3.593,10.435-1.186c6.904,4.277,8.623,12.459,10.351,19.735c2.502,10.543,2.634,23.324,1.162,34.102
							c-0.763,5.585-1.376,20.715,1.436,28.246c1.15,3.074,2.608,5.814,3.868,8.989c2.187,5.509-3.24,5.042-7.105,7.678
							c-4.785,3.262-7.838,8.307-13.855,9.835c-3.537,0.898-9.156-0.503-13.276-1.671
							C809.872,655.092,812.109,654.749,813.988,653.122z"/>
						
							<linearGradient id="SVGID_47_" gradientUnits="userSpaceOnUse" x1="1051.332" y1="542.4873" x2="1089.7451" y2="542.4873" gradientTransform="matrix(-0.9929 0.1193 0.1193 0.9929 1822.0278 -18.7209)">
							<stop  offset="0" style="stop-color:#6B73FF"/>
							<stop  offset="1" style="stop-color:#000DFF"/>
						</linearGradient>
						<path fill="url(#SVGID_47_)" d="M813.682,653.025c0.598-0.127,1.225-0.738,1.678-1.191c1.653,0.98,2.62,1.275,4.576-0.381
							c1.905-1.613,3.603-4.196,5.171-6.035c3.811-4.468,7.688-10.552,14.489-10.501c0.809,1.806,1.668,3.619,2.434,5.548
							c2.187,5.509-3.24,5.042-7.105,7.678c-4.785,3.262-6.047,8.888-12.065,10.417c-3.536,0.897-14.882,0.887-17.366-2.363
							C806.123,653.279,811.026,653.588,813.682,653.025z"/>
						<path fill="#F8DEBB" d="M837.32,643.455c-5.346,3.626-5.048,3.842-7.025,6.005c-1.868,2.043-3.905,6.962-6.064,8.651
							c1.331-0.539,1.489-0.579,5.64-2.749c5.402-2.825,0.215-4.568,9.344-10.167c0.94-0.577,3.331-1.639,3.331-1.639
							C842.555,641.279,837.722,643.1,837.32,643.455z"/>
						<path fill="#634541" d="M842.466,642.403c-1.719-0.873-3.445,0.173-5.146,1.052c-1.01,0.521-1.587,0.982-1.917,1.516
							c-0.182,2.382,0.167,10.249,0.355,11.813c3.213,1.056,4.951,0.163,5.702-0.569
							C841.481,651.355,842.966,642.396,842.466,642.403z"/>
					</g>
					<g>
						<path fill="#FDB9B1" d="M789.76,655.379c0.462-0.399,0.918-0.835,1.37-1.287c1.198-1.198,8.451-16.774,7.444-22.84
							c-0.943-4.535-1.668-9.064-2.476-13.667c-1.745-9.951-3.595-20.097-6.001-29.952c-2.517-10.307-4.742-31.178,0.451-34.798
							c3.592-2.503,6.549-3.593,10.435-1.185c6.905,4.276,8.623,12.459,10.351,19.735c2.503,10.543,2.635,23.323,1.163,34.102
							c-0.763,5.584-1.376,20.714,1.436,28.246c1.149,3.074,2.607,5.814,3.868,8.988c2.187,5.509-3.24,5.042-7.106,7.678
							c-4.785,3.262-7.837,8.307-13.855,9.836c-3.536,0.897-9.156-0.503-13.275-1.672
							C785.644,657.349,787.88,657.007,789.76,655.379z"/>
						
							<linearGradient id="SVGID_48_" gradientUnits="userSpaceOnUse" x1="1075.6553" y1="541.8379" x2="1114.0703" y2="541.8379" gradientTransform="matrix(-0.9929 0.1193 0.1193 0.9929 1822.0278 -18.7209)">
							<stop  offset="0" style="stop-color:#6B73FF"/>
							<stop  offset="1" style="stop-color:#000DFF"/>
						</linearGradient>
						<path fill="url(#SVGID_48_)" d="M789.453,655.282c0.598-0.126,1.225-0.737,1.677-1.19c1.654,0.98,2.621,1.275,4.576-0.381
							c1.906-1.613,3.604-4.196,5.172-6.035c3.811-4.468,7.688-10.552,14.489-10.501c0.809,1.805,1.668,3.619,2.434,5.548
							c2.187,5.508-3.24,5.041-7.106,7.677c-4.785,3.262-6.046,8.889-12.064,10.417c-3.536,0.898-14.882,0.888-17.367-2.362
							C781.894,655.536,786.797,655.846,789.453,655.282z"/>
						<path fill="#F8DEBB" d="M813.092,645.712c-5.346,3.626-5.048,3.843-7.026,6.006c-1.868,2.043-3.904,6.962-6.063,8.65
							c1.331-0.539,1.489-0.578,5.639-2.748c5.402-2.825,0.216-4.569,9.344-10.167c0.941-0.577,3.331-1.639,3.331-1.639
							C818.326,643.537,813.492,645.356,813.092,645.712z"/>
						<path fill="#634541" d="M818.237,644.661c-1.719-0.874-3.446,0.173-5.146,1.051c-1.011,0.522-1.587,0.982-1.918,1.516
							c-0.181,2.382,0.168,10.249,0.355,11.814c3.214,1.055,4.952,0.162,5.703-0.57
							C817.253,653.613,818.736,644.654,818.237,644.661z"/>
					</g>
					<g>
						<g>
							<path d="M777.174,594.055c6.169,5.011,12.869,10.316,16.23,17.736c3.826,8.445,3.608,18.827,4.525,27.913
								c1.835,18.189,3.615,36.385,5.201,54.599c0.3,3.449,5.726,3.481,5.422,0c-1.736-19.938-3.703-39.854-5.723-59.763
								c-0.865-8.521-1.043-17.598-4.743-25.486c-3.668-7.819-10.519-13.505-17.079-18.833
								C778.322,588.039,774.463,591.854,777.174,594.055L777.174,594.055z"/>
						</g>
						<g>
							<path d="M872.858,594.055c6.169,5.011,12.869,10.316,16.23,17.736c3.826,8.445,3.608,18.827,4.525,27.913
								c1.835,18.189,3.614,36.385,5.201,54.599c0.3,3.449,5.726,3.481,5.422,0c-1.736-19.938-3.703-39.854-5.724-59.763
								c-0.864-8.521-1.042-17.598-4.742-25.486c-3.668-7.819-10.519-13.505-17.079-18.833
								C874.006,588.039,870.147,591.854,872.858,594.055L872.858,594.055z"/>
						</g>
						<g>
							<path d="M775.888,589.247c-9.271,5.051-7.931,19.588-8.762,28.461c-1.3,13.869-2.705,27.728-3.981,41.6
								c-0.32,3.474,5.104,3.451,5.422,0c1.07-11.625,2.229-23.241,3.343-34.862c0.541-5.646,1.07-11.293,1.571-16.942
								c0.417-4.706,0.434-11.008,5.145-13.573C781.69,592.26,778.956,587.575,775.888,589.247L775.888,589.247z"/>
						</g>
						<g>
							<path d="M861.998,589.247c-9.271,5.051-7.932,19.588-8.763,28.461c-1.299,13.869-2.704,27.728-3.981,41.6
								c-0.32,3.474,5.104,3.451,5.422,0c1.07-11.625,2.229-23.241,3.343-34.862c0.541-5.646,1.07-11.293,1.571-16.942
								c0.418-4.706,0.434-11.008,5.145-13.573C867.8,592.26,865.066,587.575,861.998,589.247L861.998,589.247z"/>
						</g>
						<g>
							<path fill="#434556" d="M781.672,581.002l74.413,7.176c6.966-0.051,16.47,3.18,21.229,7.217l16.658,9.166
								c5.065,4.297,0.81,15.872-9.867,19.534c-13.688,4.695-32.881,5.46-55.813,1.353c-7.04-1.261-19.051-5.485-24.116-9.783
								l-23.064-19.566C773.704,591.293,774.706,581.053,781.672,581.002z"/>
							<path fill="#62667C" d="M784.653,580.452l70.977-0.52c6.966-0.051,16.47,3.18,21.228,7.216l15.045,12.764
								c5.064,4.297,2.423,12.276-8.254,15.938c-13.688,4.695-32.881,5.46-55.813,1.354c-7.04-1.262-19.05-5.486-24.115-9.784
								l-23.065-19.566C775.897,583.816,777.688,580.503,784.653,580.452z"/>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path fill="#FDB9B1" d="M873.729,461.944c-0.434,7.416,6.318,1.66,6.64-0.789s-0.31-4.389-1.992-4.597
									C876.693,456.351,873.874,459.479,873.729,461.944z"/>
								<path fill="#FED0CA" d="M847.56,455c0.492,7.479,4.579,21.137,11.052,21.764c14.198,1.375,12.788-8.386,13.832-12.994
									c0.246-1.106,0.56-2.352,1.115-3.846c1.937-5.173,3.631-14.176,0.949-18.68c-1.353-2.277-3.654-4.168-6.477-4.39
									c-1.255,0.018-7.126-1.265-12.454,1.716C850.249,441.544,846.992,446.246,847.56,455z"/>
								<path fill="#FDB9B1" d="M852.46,460.277c-0.727,7.394-6.5,0.655-6.437-1.813c0.064-2.47,0.99-4.287,2.686-4.23
									C850.403,454.291,852.701,457.819,852.46,460.277z"/>
								<path fill="#FDB9B1" d="M877.337,485.341c-4.17-1.799-6.31-6.909-26.028-0.69c2.337-3.238,3.238-9.111,2.726-21.009
									c-0.183-4.249,1.532,0,9.306,0.853c4.605,0.505,12.107-3.486,11.377-0.278C872.173,475.381,875.221,480.401,877.337,485.341
									z"/>
								<linearGradient id="SVGID_49_" gradientUnits="userSpaceOnUse" x1="842.2842" y1="451.9473" x2="888.0693" y2="451.9473">
									<stop  offset="0" style="stop-color:#1E1E1E"/>
									<stop  offset="1" style="stop-color:#63322B"/>
								</linearGradient>
								<path fill="url(#SVGID_49_)" d="M882.016,475.468c-2.494,0.442-5.151,0.786-7.854,1.03
									c-4.572,0.416-9.29,0.551-13.583,0.416c-2.404-0.072-4.682-0.235-6.724-0.479c-7.465-0.895-11.948-2.919-8.848-6.046
									c1.636-1.654,1.156-3.968,0.189-6.796c-2.259-6.507-7.167-15.762,4.971-25.947c-3.227-7.709,9.272-11.106,14.152-10.682
									c5.658,0.497,9.508,2.015,12.264,4.066c0.76,0.561,1.429,1.157,2.034,1.789c6.841,7.122,7.971,12.165,8.82,17.903
									C888.135,455.423,889.752,470.344,882.016,475.468z"/>
							</g>
							<linearGradient id="SVGID_50_" gradientUnits="userSpaceOnUse" x1="864.6816" y1="479.3564" x2="864.6816" y2="486.457">
								<stop  offset="0" style="stop-color:#F8E388"/>
								<stop  offset="1" style="stop-color:#EF7432"/>
							</linearGradient>
							<path fill="url(#SVGID_50_)" d="M882.016,486.457l-32.309-0.434l-2.358-0.027c0.026-0.036,0.054-0.072,0.09-0.108
								c0.949-1.12,2.494-2.35,4.482-3.443c0.244-0.126,0.488-0.253,0.732-0.379c3.533-1.771,8.287-3.046,13.556-2.63
								c3.977,0.316,7.239,1.473,9.743,2.756c3.055,1.563,4.979,3.326,5.729,3.985C881.871,486.349,881.979,486.448,882.016,486.457
								z"/>
						</g>
						
							<linearGradient id="SVGID_51_" gradientUnits="userSpaceOnUse" x1="997.4775" y1="580.2559" x2="1102.8311" y2="580.2559" gradientTransform="matrix(-1 0 0 1 1888.6934 0)">
							<stop  offset="0" style="stop-color:#6B73FF"/>
							<stop  offset="1" style="stop-color:#000DFF"/>
						</linearGradient>
						<path fill="url(#SVGID_51_)" d="M816.452,542.891c4.751,3.285,9.378-13.97,28.098,0.301
							c12.755,9.723,35.963,35.731,41.693,41.646c9.517,11.067,5.59,30.064-13.651,36.303c-4.835,1.568-24.156,2.462-30.288,1.555
							c-5.313-0.786-14.637-3.033-21.963-7.738c-4.469-2.87-12.021-12.258-20.082-23.313c-6.667-9.144-14.902-3.138-14.372-32.76
							C786.079,548.131,802.095,532.962,816.452,542.891z"/>
						
							<linearGradient id="SVGID_52_" gradientUnits="userSpaceOnUse" x1="997.1113" y1="511.2949" x2="997.1113" y2="587.2441" gradientTransform="matrix(-0.9999 -0.0145 -0.0145 0.9999 1894.651 -4.4044)">
							<stop  offset="0" style="stop-color:#F8E388"/>
							<stop  offset="1" style="stop-color:#EF7432"/>
						</linearGradient>
						<path fill="url(#SVGID_52_)" d="M901.438,508.324c3.735,17.074,1.698,26.577,0.74,39.752
							c-0.385,5.29-0.652,20.716-10.765,20.276c-8.806-0.382-12.588-12.542-13.441-17.926c-1.643-10.355-2.31-20.979-2.004-31.362
							c0.23-7.847,4.488-28.551,14.245-26.567C893.268,493.117,899.403,499.02,901.438,508.324z"/>
						<linearGradient id="SVGID_53_" gradientUnits="userSpaceOnUse" x1="815.543" y1="592.1045" x2="903.5386" y2="504.1089">
							<stop  offset="0" style="stop-color:#F8E388"/>
							<stop  offset="1" style="stop-color:#EF7432"/>
						</linearGradient>
						<path fill="url(#SVGID_53_)" d="M891.189,597.239c-0.181,0.415-0.416,0.813-0.688,1.201c-0.045,0.055-0.081,0.118-0.135,0.172
							c-0.696,0.931-1.609,1.763-2.693,2.477c-0.561,0.389-1.185,0.741-1.844,1.066c-6.679,3.326-17.804,4.004-28.784,3.516
							c-7.303-0.325-14.542-1.166-20.362-2.088c-7.465-1.175-12.616-2.485-12.625-3.009c-0.181-6.725,0.605-11.568,1.861-15.31
							c0.669-1.979,1.465-3.651,2.323-5.134c2.53-4.347,5.576-7.059,7.312-11.044v-0.009c0.85-1.952,1.383-4.221,1.383-7.131
							c0-1.12,0.018-2.828,0-5.061c-0.01-2.422-0.063-5.468-0.227-9.038c-0.063-1.237-0.136-2.548-0.217-3.913
							c-0.117-1.753-0.253-3.615-0.425-5.566c-0.334-3.77-0.786-7.891-1.41-12.31c-1.328-9.426-3.542-18.093-3.308-24.926
							c0.108-3.361,0.813-6.28,2.521-8.621c1.51-2.106,3.832-3.751,7.239-4.835c2.124-0.678,4.229-1.274,6.326-1.79
							c1.049-0.262,2.088-0.497,3.118-0.714c9.047-1.889,17.695-2.214,25.205-0.678c0.443,0.091,0.886,0.181,1.329,0.289
							c1.591,0.38,3.118,0.841,4.591,1.392c4.988,1.88,9.264,4.79,12.544,8.821c0.009,0.009,0.019,0.018,0.019,0.018
							c0.207,0.263,0.415,0.524,0.614,0.786c5.92,7.872-4.618,36.584-5.739,44.528c-0.244,1.717-0.434,3.118-0.596,4.383
							c-0.19,1.491-0.335,2.783-0.507,4.166c-0.425,3.534-0.939,7.673-2.367,17.208c-0.163,1.093-0.335,2.25-0.524,3.488
							C883.923,577.465,894.551,589.756,891.189,597.239z"/>
						<linearGradient id="SVGID_54_" gradientUnits="userSpaceOnUse" x1="836.1094" y1="490.2178" x2="824.668" y2="560.92">
							<stop  offset="0" style="stop-color:#F8E388"/>
							<stop  offset="1" style="stop-color:#EF7432"/>
						</linearGradient>
						<path fill="url(#SVGID_54_)" d="M841.826,506.114c-0.244,11.278-0.659,19.213-3.154,32.489
							c-0.045,0.227-0.081,0.497-0.108,0.813c-0.108,1.175-0.126,2.892-0.162,4.871c-0.027,1.22-0.055,2.53-0.127,3.877
							c-0.162,3.019-0.497,6.191-1.337,8.722c-0.705,2.15-1.771,3.841-3.371,4.609c-0.913,0.434-2.007,0.578-3.308,0.325
							c-3.896-0.75-6.986-4.817-9.2-9.48c-0.633-1.338-1.193-2.721-1.682-4.085c-0.298-0.832-0.561-1.654-0.804-2.458
							c-0.263-0.858-0.488-1.699-0.678-2.495c-0.606-2.476-0.896-4.563-0.868-5.747c0.199-8.495,2.603-14.587,4.69-22.657
							c0.091-0.325,0.172-0.65,0.253-0.977c1.519-6.027,6.715-15.978,11.902-21.409c0.361-0.389,0.732-0.76,1.103-1.094
							c1.374-1.274,3.471-1.708,4.979,1.075C841.157,494.672,841.989,498.83,841.826,506.114z"/>
					</g>
					<g>
						<g>
							<path d="M810.848,595.783c-0.484,4.956-0.667,9.94-1.254,14.888c-0.128,1.081-0.087,4.153-0.962,5.061
								c-2.718,2.816-8.669-4.263-10.501-5.885c-2.604-2.306-6.453,1.516-3.834,3.835c4.484,3.97,15.28,14.016,19.476,3.751
								c2.622-6.417,1.832-14.839,2.498-21.649C816.61,592.313,811.186,592.336,810.848,595.783L810.848,595.783z"/>
						</g>
						<g>
							<path d="M901.122,595.783c-0.248,2.212-2.552,20.709-5.958,19.548c-3.311-1.129-4.731,4.107-1.441,5.229
								c10.884,3.71,12.115-18.479,12.822-24.776C906.934,592.32,901.508,592.349,901.122,595.783L901.122,595.783z"/>
						</g>
						<path fill="#62667C" d="M902.136,600.068c-22.417,14.308-72.83,12.768-89.984,0.487c-1.419-1.017-4.159-2.348-3.935-4.009
							l5.622-41.485c0.964-7.114,8.067-14.779,15.282-12.819c18.129,4.923,53.322,7.42,67.295-0.49
							c6.506-3.683,15.471,8.486,14.506,15.6l-5.721,39.637C904.976,598.649,903.606,599.13,902.136,600.068z"/>
						<path fill="#434556" d="M902.54,600.75c-21.384,13.308-71.558,12.842-87.922,1.419c-1.354-0.945-2.84-1.266-2.625-2.811
							l6.319-40.475c0.92-6.617,7.695-13.746,14.578-11.924c17.293,4.579,53.36,5.603,66.689-1.756
							c6.207-3.426,12.168,7.473,11.248,14.09l-5.363,38.59C905.25,599.429,903.943,599.876,902.54,600.75z"/>
					</g>
				</g>
				<path opacity="0.2" fill="#ECF2FA" d="M876.584,431.03c0,0-22.223-4.699-23.126,6.443c-0.091,1.13-0.271,2.062-0.516,2.865
					c-0.858,2.766-2.513,3.868-4.103,5.368c-2.485,2.341-4.809,5.667-3.643,17.886c-2.259-6.507-7.167-15.762,4.971-25.947
					c-3.227-7.709,9.272-11.106,14.152-10.682C869.979,427.461,873.828,428.979,876.584,431.03z"/>
				<path opacity="0.2" fill="#ECF2FA" d="M852.057,483.421c0,0.009,0,0.009-0.009,0.009c-0.244,0.353-0.633,0.813-1.202,1.438
					c-0.1,0.108-0.189,0.217-0.289,0.307c-0.036,0.055-0.091,0.1-0.136,0.145c-0.244,0.253-0.479,0.497-0.714,0.705
					c-0.072,0.081-0.145,0.145-0.217,0.208c-2.504,2.332-4.926,3.389-9.535,6.263c-0.081,0.046-0.171,0.1-0.253,0.154
					c-2.358,1.481-4.401,3.271-8.351,8.485c-0.054,0.072-0.108,0.146-0.162,0.218c-2.332,3.117-5.332,7.419-9.472,13.547
					c0.091-0.325,0.172-0.65,0.253-0.977c1.519-6.027,6.715-15.978,11.902-21.409c1.51-2.106,3.832-3.751,7.239-4.835
					c2.124-0.678,4.229-1.274,6.326-1.79c0.949-1.12,2.494-2.35,4.482-3.443c0.01,0,0.01,0,0.019,0l0.172,0.027c0,0,0,0,0.009,0
					c0.054-0.018,0.298-0.09,0.344,0.063C852.509,482.652,852.437,482.905,852.057,483.421z"/>
			</g>
			<g>
				<g>
					<g>
						<path d="M965.419,640.52c2.269,1.579,0.86,13.132,0.685,16.281c-0.199,3.573-1.944,7.509-5.868,7.024
							c-15.073-1.859-9.982-6.942-14.146-7.771c-1.763-0.352-0.498,0.587-2.333,0.137c-2.455-0.602-6.397-2.965-9.468-8.111
							c-1.949-3.267-4.412-10.635,0.987-11.854c2.439-0.551,5.94,5.186,16.528-0.557
							C955.811,633.494,963.174,637.457,965.419,640.52z"/>
						<path fill="#1E1E1E" d="M964.894,644.959c-3.162,3.282-12.299,0.537-14.527-2.775c-2.008-2.983-2.03-7.517-2.259-11.595
							c-0.209-3.733,0.927-5.267,4.914-5.802c3.238-0.435,9.045,0.329,11.572,2.628C966.129,628.813,966.76,643.022,964.894,644.959
							z"/>
						
							<linearGradient id="SVGID_55_" gradientUnits="userSpaceOnUse" x1="1511.8711" y1="672.6738" x2="1548.29" y2="672.6738" gradientTransform="matrix(-0.9957 -0.0923 -0.0923 0.9957 2542.405 62.9876)">
							<stop  offset="0" style="stop-color:#6B73FF"/>
							<stop  offset="1" style="stop-color:#000DFF"/>
						</linearGradient>
						<path fill="url(#SVGID_55_)" d="M942.527,549.961c-1.623,6.189,0.788,33.603,0.793,52.972
							c0.002,8.824-0.731,23.16-1.189,31.969c6.006,5.375,21.548,7.621,28.667,3.068c0.611-6.079,0.875-22.818,1.146-28.903
							c0.342-7.676,2.266-15.175,2.554-22.88c0.35-9.359-0.399-20.089-3.562-28.996c-2.936-8.27-8.914-13.67-17.939-14.701
							C949.701,542.112,944.055,544.136,942.527,549.961z"/>
					</g>
					<g>
						<path d="M936.426,642.943c2.404,1.363,2.069,12.996,2.185,16.149c0.132,3.576,1.359,6.3-2.577,6.669
							c-15.203,1.429-9.985-5.541-14.207-5.982c-1.788-0.188-2.715,1.546-4.583,1.268c-2.501-0.372-5.203-0.75-7.588-1.533
							c-5.207-1.713-7.998-2.041-12.07-5.744c-2.449-2.227-6.737-6.999-1.233-7.58c4.239-0.448,8.321,0.702,12.992-0.489
							c4.725-1.205,8.201-5.226,13.078-6.331C926.867,638.361,933.908,640.102,936.426,642.943z"/>
						<path fill="#1E1E1E" d="M935.864,630.921c1.408,2.222,1.957,11.496,0.278,13.597c-2.846,3.56-12.197,1.669-14.722-1.423
							c-2.274-2.786-2.715-7.298-3.319-11.338c-0.552-3.697,0.437-5.328,4.358-6.229c3.184-0.731,9.036-0.508,11.765,1.549
							C935.882,628.326,937.616,631.351,935.864,630.921z"/>
						
							<linearGradient id="SVGID_56_" gradientUnits="userSpaceOnUse" x1="948.5215" y1="592.3047" x2="984.9375" y2="592.3047" gradientTransform="matrix(-1 0 0 1 1888.6934 0)">
							<stop  offset="0" style="stop-color:#6B73FF"/>
							<stop  offset="1" style="stop-color:#000DFF"/>
						</linearGradient>
						<path fill="url(#SVGID_56_)" d="M903.899,552.588c-1.044,6.313,3.886,33.387,5.678,52.673
							c0.817,8.786,1.409,23.128,1.767,31.941c6.476,4.799,22.158,5.601,28.827,0.41c0.048-6.109-1.234-22.802-1.525-28.885
							c-0.368-7.676,0.855-15.32,0.432-23.019c-0.516-9.352-2.252-19.967-6.223-28.543c-3.687-7.964-10.548-13.527-19.63-13.722
							C909.907,543.374,904.883,546.646,903.899,552.588z"/>
					</g>
					<g>
						<g>
							<path d="M901.759,594.08c6.169,5.011,12.869,10.316,16.23,17.736c3.826,8.445,3.608,18.827,4.525,27.913
								c1.835,18.189,3.614,36.386,5.2,54.599c0.301,3.45,5.726,3.481,5.423,0c-1.736-19.937-3.703-39.853-5.724-59.763
								c-0.864-8.521-1.043-17.598-4.742-25.485c-3.668-7.819-10.519-13.506-17.079-18.834
								C902.907,588.064,899.048,591.879,901.759,594.08L901.759,594.08z"/>
						</g>
						<g>
							<path d="M997.443,594.08c6.169,5.011,12.869,10.316,16.23,17.736c3.826,8.445,3.608,18.827,4.525,27.913
								c1.835,18.189,3.614,36.386,5.2,54.599c0.301,3.45,5.726,3.481,5.423,0c-1.736-19.937-3.704-39.853-5.724-59.763
								c-0.864-8.521-1.043-17.598-4.743-25.485c-3.667-7.819-10.518-13.506-17.078-18.834
								C998.591,588.064,994.732,591.879,997.443,594.08L997.443,594.08z"/>
						</g>
						<g>
							<path d="M900.473,589.272c-9.271,5.051-7.931,19.588-8.762,28.461c-1.3,13.869-2.705,27.729-3.982,41.6
								c-0.319,3.474,5.105,3.452,5.423,0c1.07-11.625,2.229-23.241,3.343-34.861c0.541-5.646,1.07-11.293,1.571-16.942
								c0.417-4.707,0.434-11.008,5.144-13.574C906.275,592.285,903.541,587.602,900.473,589.272L900.473,589.272z"/>
						</g>
						<g>
							<path d="M986.582,589.272c-9.271,5.051-7.931,19.588-8.762,28.461c-1.3,13.869-2.705,27.729-3.981,41.6
								c-0.32,3.474,5.104,3.452,5.422,0c1.07-11.625,2.229-23.241,3.343-34.861c0.541-5.646,1.07-11.293,1.571-16.942
								c0.417-4.707,0.434-11.008,5.145-13.574C992.385,592.285,989.65,587.602,986.582,589.272L986.582,589.272z"/>
						</g>
						<g>
							<path fill="#434556" d="M906.257,581.027l74.413,7.176c6.966-0.051,16.47,3.181,21.229,7.217l16.658,9.166
								c5.065,4.297,0.81,15.873-9.868,19.535c-13.688,4.694-32.88,5.459-55.812,1.353c-7.041-1.261-19.051-5.486-24.116-9.783
								l-23.064-19.567C898.289,591.318,899.291,581.078,906.257,581.027z"/>
							<path fill="#62667C" d="M909.238,580.478l70.976-0.519c6.966-0.052,16.471,3.18,21.229,7.216l15.044,12.763
								c5.065,4.297,2.424,12.276-8.253,15.938c-13.688,4.694-32.881,5.46-55.813,1.353c-7.04-1.261-19.051-5.486-24.116-9.783
								l-23.064-19.567C900.482,583.842,902.272,580.528,909.238,580.478z"/>
						</g>
					</g>
					<g>
						
							<linearGradient id="SVGID_57_" gradientUnits="userSpaceOnUse" x1="-29.5776" y1="300.6934" x2="20.4287" y2="300.6934" gradientTransform="matrix(0.8013 -0.5982 0.5982 0.8013 827.1533 352.4903)">
							<stop  offset="0" style="stop-color:#6B73FF"/>
							<stop  offset="1" style="stop-color:#000DFF"/>
						</linearGradient>
						<path fill="url(#SVGID_57_)" d="M994.056,587.078c3.166-3.813,13.286-16.12,18.785-15.505
							c5.973,0.669,8.768,7.138,8.472,11.753c-0.353,5.499-2.488,9.935-5.101,16.816c-2.344,6.176-12.284,18.012-22.313,20.319
							C983.539,622.846,985.569,597.299,994.056,587.078z"/>
						<g>
							<g>
								<path fill="#FDB9B1" d="M996.021,459.654c-1.656,8.479,6.028,2.568,6.753-0.213c0.725-2.78,0.416-5.075-1.229-5.49
									C999.9,453.536,996.572,456.836,996.021,459.654z"/>
								<path fill="#FED0CA" d="M1001.374,453.923c-0.298,0.75-0.597,1.428-0.895,1.987c-0.741,1.41-3.01,6.725-3.389,7.791
									c-0.036,0.108-0.082,0.208-0.127,0.307c-0.479,1.221-1.211,2.251-2.305,3.199c-0.1,0.082-0.198,0.172-0.307,0.263
									c-2.766,2.232-7.728,4.121-16.756,7.257c-0.235,0.081-0.913,0.235-1.744,0.271c-1.066,0.045-2.377-0.108-3.28-0.886
									c-0.037-0.027-0.643-1.021-0.651-1.058c-2.395-5.757-4.04-11.496-4.699-16.937c-0.081-0.678-0.145-1.355-0.199-2.024
									c-0.145-1.834-0.162-3.624-0.063-5.368v-0.009c0.497-8.306,4.356-15.924,9.58-18.636c0.289-0.153,0.578-0.289,0.868-0.406
									c5.657-2.296,13.267,6.489,14.514,6.624c2.774,0.569,5.729,1.501,8.378,1.527
									C1005.595,437.881,1003.67,448.156,1001.374,453.923z"/>
								<path fill="#FDB9B1" d="M974.636,457.521c-2,8.741-8.103-0.228-7.655-3.175c0.448-2.946,1.859-4.979,3.938-4.647
									C972.998,450.03,975.3,454.614,974.636,457.521z"/>
								<path fill="#FDB9B1" d="M998.582,483.141c-4.104-1.5-6.508-6.154-23.868,0.018c-0.308,0.1-0.615,0.218-0.931,0.335
									c-0.398,0.145-0.813,0.289-1.229,0.452c0.081-0.127,0.163-0.263,0.244-0.398c0.497-0.822,0.976-1.771,1.41-2.838
									c0.289-0.695,0.56-1.437,0.804-2.241c0.335-1.066,0.614-2.223,0.841-3.47c0.425-2.35,0.633-4.998,0.488-7.908
									c-0.046-0.886-0.1-1.6-0.091-2.16c0.027-2.114,0.985-2.123,7.157-1.798c4.628,0.234,12.292-3.027,11.74,0.217
									c-0.235,1.4-0.397,2.684-0.488,3.858c-0.009,0.046-0.009,0.091-0.009,0.136c-0.588,7.42,1.364,10.863,3.28,14.532
									C998.147,482.291,998.365,482.716,998.582,483.141z"/>
								<path d="M1004.898,443.918c0.019,0.027-0.994,4.835-2.837,10.248c-1.157,3.39-2.657,7.059-4.447,9.852
									c-0.678,1.075-1.409,2.006-2.169,2.756c-0.253,0.253-0.523,0.434-0.795,0.569c-0.1,0.055-0.199,0.091-0.298,0.127
									c-2.097,0.731-4.646-1.211-6.851-1.519c-4.004-0.561-8.758,2.35-11.252-1.021c-0.406-0.551-0.759-1.274-1.03-2.205
									c-0.235-0.804-0.524-2.214-1.003-3.777c-0.822-2.711-2.215-5.874-4.871-7.194c-0.425-0.207-0.796-0.487-1.103-0.822
									c-0.569-0.578-0.994-1.337-1.284-2.205v-0.009c-1.816-5.351,1.058-14.966,4.401-14.451
									c3.127-7.528,9.399-10.998,17.271-9.309c2.793,0.605,5.151,1.374,7.113,2.313c0.018,0.009,0.027,0.019,0.045,0.019
									C1002.757,430.642,1004.89,436.146,1004.898,443.918z"/>
							</g>
							<linearGradient id="SVGID_58_" gradientUnits="userSpaceOnUse" x1="969.21" y1="482.2168" x2="1003.3174" y2="482.2168">
								<stop  offset="0" style="stop-color:#1E1E1E"/>
								<stop  offset="1" style="stop-color:#63322B"/>
							</linearGradient>
							<path fill="url(#SVGID_58_)" d="M1003.317,485.779l-32.011-1.5l-2.097-0.1c0.108-0.126,0.217-0.262,0.343-0.389
								c1.04-1.13,2.631-2.205,4.655-3.082c0.542-0.234,1.12-0.46,1.734-0.659c0.425-0.145,0.858-0.28,1.319-0.406
								c2.91-0.796,6.399-1.193,10.258-0.886c4.338,0.343,7.826,1.689,10.411,3.117c2.594,1.429,4.275,2.938,4.998,3.579
								C1003.146,485.653,1003.281,485.771,1003.317,485.779z"/>
						</g>
						
							<linearGradient id="SVGID_59_" gradientUnits="userSpaceOnUse" x1="876.1709" y1="582.7988" x2="986.0479" y2="582.7988" gradientTransform="matrix(-1 0 0 1 1888.6934 0)">
							<stop  offset="0" style="stop-color:#6B73FF"/>
							<stop  offset="1" style="stop-color:#000DFF"/>
						</linearGradient>
						<path fill="url(#SVGID_59_)" d="M911.108,543.789c5.361-2.147,11.717,1.787,28.136,11.504
							c13.802,8.168,33.355,20.504,41.162,34.869c9.574-7.07,21.413-11.917,27.145-6.003c9.516,11.067,5.589,30.064-13.651,36.303
							c-4.836,1.568-24.157,2.462-30.289,1.555c-5.313-0.786-14.637-3.033-21.963-7.738c-8.164-5.243-31.251-38.561-36.645-47.29
							C899.351,557.84,904.853,546.295,911.108,543.789z"/>
						
							<linearGradient id="SVGID_60_" gradientUnits="userSpaceOnUse" x1="866.5352" y1="513.0186" x2="866.5352" y2="589.0439" gradientTransform="matrix(-0.9999 -0.0145 -0.0145 0.9999 1894.651 -4.4044)">
							<stop  offset="0" style="stop-color:#1E1E1E"/>
							<stop  offset="1" style="stop-color:#63322B"/>
						</linearGradient>
						<path fill="url(#SVGID_60_)" d="M1031.977,512.018c3.734,17.074,1.697,26.577,0.739,39.752
							c-0.385,5.29-0.651,20.715-10.765,20.276c-8.806-0.383-12.587-12.542-13.441-17.926c-1.643-10.355-2.309-20.979-2.004-31.363
							c0.23-7.846,5.097-22.639,14.246-26.566C1024.221,494.7,1029.941,502.713,1031.977,512.018z"/>
						<linearGradient id="SVGID_61_" gradientUnits="userSpaceOnUse" x1="983.0977" y1="605.5361" x2="983.0977" y2="483.2949">
							<stop  offset="0" style="stop-color:#1E1E1E"/>
							<stop  offset="1" style="stop-color:#63322B"/>
						</linearGradient>
						<path fill="url(#SVGID_61_)" d="M1023.453,534.157c-0.588,2.783-1.6,5.269-2.847,7.89c-0.832,1.78-1.79,3.633-2.784,5.693
							c-2.015,4.14-4.229,9.119-6.163,16.114c-0.452,1.627-0.886,3.362-1.302,5.215c-0.208,0.939-0.325,1.979-0.379,3.081
							c-0.235,4.98,0.84,11.415,0.804,17.145c-0.019,1.898-0.153,3.715-0.506,5.377c-0.344,1.646-0.895,3.137-1.744,4.401
							c-0.145,0.227-0.308,0.434-0.47,0.642c-0.027,0.036-0.046,0.063-0.073,0.091c-3.561,4.293-11.576,5.766-20.849,5.729
							c-1.79-0.009-3.624-0.072-5.486-0.181c-3.082-0.181-6.227-0.497-9.336-0.895c-12.661-1.618-24.69-4.663-29.046-6.236
							c-1.066-0.389-1.672-0.687-1.718-0.858c-1.247-4.311-0.776-8.64,0.353-12.905c0.244-0.958,0.524-1.907,0.832-2.847
							c0.145-0.461,0.298-0.922,0.461-1.383c2.322-6.851,5.422-13.493,4.735-19.639c-0.055-0.434-0.117-0.877-0.208-1.311
							c-0.126-0.633-0.316-1.78-0.533-3.308c-0.189-1.302-0.397-2.874-0.623-4.654c-0.254-1.961-0.516-4.157-0.787-6.498
							c-0.036-0.325-0.072-0.65-0.108-0.976c-1.093-9.67-2.159-21.329-2.133-27.754c0.036-7.203-0.642-12.834-0.668-17.199
							c-0.027-4.157,0.523-7.166,2.837-9.326c1.482-1.392,3.679-2.422,6.905-3.182c5.061-1.184,10.872-2.087,16.936-2.594
							c1.076-0.1,2.16-0.172,3.245-0.244c0.334-0.018,0.659-0.036,0.985-0.054c0.289-0.019,0.569-0.027,0.858-0.045
							c9.742-0.461,19.801,0.09,28.287,2.006c8.676,1.961,15.698,5.359,19.087,10.556c0.009,0.01,0.019,0.027,0.019,0.036
							C1027.375,504.334,1025.125,526.313,1023.453,534.157z"/>
						<linearGradient id="SVGID_62_" gradientUnits="userSpaceOnUse" x1="939.0547" y1="489.5664" x2="939.0547" y2="568.9561">
							<stop  offset="0" style="stop-color:#1E1E1E"/>
							<stop  offset="1" style="stop-color:#63322B"/>
						</linearGradient>
						<path fill="url(#SVGID_62_)" d="M951.795,507.045c-0.471,12.354-1.04,21.084-3.751,35.571
							c-0.019,0.136-0.045,0.271-0.072,0.407c-0.027,0.135-0.045,0.279-0.063,0.442c-0.198,1.455-0.244,3.932-0.361,6.796
							c-0.063,1.808-0.172,3.769-0.353,5.712c-0.126,1.302-0.289,2.594-0.506,3.832c-0.714,4.049-2.016,7.52-4.482,8.703
							c-0.235,0.117-0.479,0.208-0.741,0.271c-0.714,0.207-1.51,0.234-2.396,0.063c-0.795-0.153-1.554-0.461-2.277-0.895
							c-0.099-0.063-0.198-0.117-0.298-0.181c-4.817-3.172-7.962-12.029-9.372-18.672c-0.072-0.334-0.145-0.659-0.198-0.984
							c-0.488-2.494-0.705-4.591-0.651-5.82c0.389-9.67,3.887-22.133,6.209-31.595c0.027-0.091,0.045-0.19,0.072-0.28
							c1.753-7.186,4.952-14.009,10.601-19.756c0.633-0.651,1.555-1.094,2.558-1.094c1.166,0,2.449,0.605,3.543,2.196
							C950.9,494.166,952.111,498.82,951.795,507.045z"/>
					</g>
					<g>
						<g>
							<path d="M935.545,595.809c-0.484,4.956-0.668,9.941-1.254,14.888c-0.129,1.081-0.088,4.154-0.962,5.061
								c-2.718,2.816-8.669-4.263-10.501-5.884c-2.604-2.307-6.453,1.515-3.834,3.834c4.484,3.971,15.28,14.017,19.475,3.751
								c2.622-6.417,1.833-14.838,2.499-21.649C941.307,592.338,935.882,592.361,935.545,595.809L935.545,595.809z"/>
						</g>
						<g>
							<path d="M1025.819,595.809c-0.248,2.212-2.552,20.709-5.958,19.548c-3.311-1.128-4.731,4.107-1.441,5.229
								c10.884,3.71,12.115-18.479,12.821-24.776C1031.63,592.347,1026.204,592.374,1025.819,595.809L1025.819,595.809z"/>
						</g>
						<path fill="#62667C" d="M1026.832,600.095c-22.417,14.307-72.829,12.767-89.983,0.486c-1.419-1.016-4.16-2.348-3.935-4.008
							l5.622-41.486c0.964-7.114,8.067-14.778,15.282-12.819c18.128,4.923,53.322,7.421,67.295-0.489
							c6.506-3.684,15.47,8.485,14.506,15.599l-5.721,39.637C1029.673,598.675,1028.304,599.155,1026.832,600.095z"/>
						<path fill="#434556" d="M1027.237,600.775c-21.384,13.309-71.558,12.842-87.922,1.419c-1.354-0.945-2.84-1.266-2.626-2.811
							l6.32-40.474c0.919-6.617,7.695-13.747,14.577-11.925c17.294,4.58,53.361,5.603,66.69-1.755
							c6.206-3.426,12.167,7.473,11.248,14.09l-5.363,38.59C1029.947,599.455,1028.641,599.902,1027.237,600.775z"/>
					</g>
				</g>
				<path opacity="0.2" fill="#ECF2FA" d="M995.744,427.271c-0.841-0.253-12.492-2.808-18.357,3.925
					c-1.175,1.355-2.087,3.127-2.584,5.432c0,0-6.632,2.465-7.844,12.09c-1.816-5.351,1.058-14.966,4.401-14.451
					c3.127-7.528,9.399-10.998,17.271-9.309C991.424,425.563,993.782,426.331,995.744,427.271z"/>
				<path opacity="0.2" fill="#ECF2FA" d="M975.942,480.05c0.618-0.204-0.822,1.446-1.229,3.108c-0.027,0.091-0.055,0.19-0.072,0.29
					c-0.019,0.09-0.046,0.181-0.063,0.271c0,0-1.283,0.199-3.271,0.561c-6.028,1.111-18.6,3.76-21.735,7.14
					c-0.1,0.108-0.167,0.257-0.316,0.344c-1.058,0.614-9.153,4.832-11.891,6.681c0.822-1.732,2.966-4.91,5.79-7.783
					c0.633-0.651,1.555-1.094,2.558-1.094c1.482-1.392,3.679-2.422,6.905-3.182c5.061-1.184,10.872-2.087,16.936-2.594
					c1.04-1.13,2.631-2.205,4.655-3.082C975.048,480.366,975.164,480.307,975.942,480.05z"/>
			</g>
			<g>
				<g>
					<g>
						<path fill="#1E1E1E" d="M1094.549,712.906c0.087-0.014,0.171-0.03,0.266-0.04c2.019-0.211,0.499,0.712,2.607,0.397
							c2.822-0.42,7.498-2.666,11.485-8.13c1.047-1.434,1.624-3.898,1.528-6.411C1108.645,705.128,1101.07,709.603,1094.549,712.906
							z"/>
						<path fill="#1E1E1E" d="M1072.512,714.841c0.613,2.918,2.316,5.386,5.598,5.286c13.845-0.42,13.112-4.576,14.836-6.419
							c-1.173,0.58-2.293,1.124-3.309,1.632C1084.598,717.859,1077.278,718.836,1072.512,714.841z"/>
						<path fill="#434556" d="M1110.436,698.723c-0.135-3.563-1.636-7.221-5.17-8.182c-3.799-1.032-3.449,6.378-14.749-1.179
							c-4.276-2.859-12.963,0.827-15.805,4.035c-2.714,1.538-2.335,14.667-2.466,18.225c-0.039,1.066,0.045,2.171,0.266,3.219
							c4.767,3.995,12.086,3.019,17.125,0.499c1.016-0.508,2.136-1.052,3.309-1.632c0.366-0.393,0.85-0.678,1.604-0.802
							C1101.07,709.603,1108.645,705.128,1110.436,698.723z"/>
						<path fill="#1E1E1E" d="M1091.454,696.835c2.566-3.144,3.063-8.235,3.745-12.795c0.624-4.173-0.492-6.014-4.918-7.031
							c-3.593-0.825-10.198-0.572-13.277,1.748c-1.871,1.411-4.06,17.313-2.164,19.685
							C1078.051,702.458,1088.604,700.325,1091.454,696.835z"/>
						<linearGradient id="SVGID_63_" gradientUnits="userSpaceOnUse" x1="1068.9316" y1="639.2559" x2="1110.0293" y2="639.2559">
							<stop  offset="0" style="stop-color:#6B73FF"/>
							<stop  offset="1" style="stop-color:#000DFF"/>
						</linearGradient>
						<path fill="url(#SVGID_63_)" d="M1101.467,689.509c0.402-9.946,1.071-26.132,1.992-36.049
							c2.023-21.766,7.587-52.319,6.408-59.444c-1.109-6.705-7.244-9.566-10.987-9.486c-10.249,0.219-17.53,5.665-21.69,14.652
							c-4.48,9.68-6.44,21.66-7.022,32.213c-0.479,8.688,0.902,17.316,0.487,25.978c-0.329,6.866-1.775,25.704-1.722,32.6
							C1076.459,695.829,1094.158,694.924,1101.467,689.509z"/>
					</g>
					<g>
						<path fill="#434556" d="M1004.85,715.699c-0.131-3.559,0.248-16.687-2.465-18.226c-2.843-3.208-10.788-5.172-15.805-4.034
							c-5.505,1.248-9.428,5.786-14.761,7.146c-5.271,1.345-9.23-1.165-14.016-0.66c-1.806,0.19-2.728,0.886-3.065,1.834
							c6.586,15.361,22.239,10.02,35.104,14.614c2.775,0.925,9.157,5.44,12.856,3.591c0.489,0,1.495,0.254,2.472,0.088
							C1005.155,718.797,1004.908,717.281,1004.85,715.699z"/>
						<path fill="#1E1E1E" d="M998.42,719.188c-1.986-0.339-3.221-0.815-5.895-1.999c-13.064-5.78-31.201-0.069-37.787-15.431
							c-0.825,2.314,1.851,6.15,3.811,7.933c4.597,4.179,7.746,4.55,13.623,6.482c2.691,0.885,5.741,1.311,8.563,1.73
							c2.108,0.314,3.153-1.642,5.172-1.431c4.765,0.499-1.099,6.232,16.034,6.752c2.711,0.083,3.253-2.781,3.229-4.727
							C1004.194,718.666,1002.583,719.356,998.42,719.188z"/>
						<path fill="#1E1E1E" d="M1001.75,683.906c1.59,2.506,2.209,12.973,0.313,15.345c-3.211,4.017-13.765,1.883-16.614-1.606
							c-2.566-3.144-3.063-8.235-3.745-12.795c-0.623-4.174,0.492-6.015,4.918-7.031c3.594-0.826,10.199-0.572,13.277,1.748
							C1001.771,680.977,1003.728,684.391,1001.75,683.906z"/>
						
							<linearGradient id="SVGID_64_" gradientUnits="userSpaceOnUse" x1="844.5723" y1="638.3789" x2="885.6699" y2="638.3789" gradientTransform="matrix(-1 0 0 1 1851.1836 0)">
							<stop  offset="0" style="stop-color:#6B73FF"/>
							<stop  offset="1" style="stop-color:#000DFF"/>
						</linearGradient>
						<path fill="url(#SVGID_64_)" d="M965.676,595.502c-1.179,7.125,4.385,37.679,6.408,59.444
							c0.922,9.916,1.59,26.103,1.993,36.049c7.309,5.415,25.007,6.319,32.533,0.462c0.054-6.895-1.393-25.732-1.722-32.599
							c-0.415-8.662,0.966-17.29,0.487-25.978c-0.581-10.554-2.541-22.533-7.022-32.213c-4.16-8.987-11.871-19.158-22.121-19.377
							C972.489,581.211,966.785,588.797,965.676,595.502z"/>
					</g>
					<g>
						<g>
							<path d="M978.462,639.519c6.706,5.444,13.892,11.163,17.857,19.032c4.776,9.479,4.519,21.342,5.564,31.696
								c2.108,20.873,4.154,41.753,5.974,62.653c0.301,3.45,5.726,3.482,5.423,0c-1.971-22.633-4.206-45.242-6.5-67.846
								c-0.997-9.822-1.218-20.26-5.779-29.24c-4.208-8.285-11.627-14.385-18.705-20.13
								C979.609,633.504,975.75,637.317,978.462,639.519L978.462,639.519z"/>
						</g>
						<g>
							<path d="M1086.447,639.519c6.707,5.444,13.892,11.163,17.857,19.032c4.777,9.479,4.52,21.342,5.564,31.696
								c2.109,20.873,4.155,41.753,5.975,62.653c0.301,3.45,5.726,3.482,5.423,0c-1.971-22.633-4.206-45.242-6.5-67.846
								c-0.997-9.822-1.218-20.26-5.779-29.24c-4.208-8.285-11.627-14.385-18.705-20.13
								C1087.596,633.504,1083.736,637.317,1086.447,639.519L1086.447,639.519z"/>
						</g>
						<g>
							<path d="M976.939,634.641c-4.579,2.436-6.726,6.981-7.686,11.906c-1.274,6.542-1.419,13.394-2.04,20.019
								c-1.464,15.616-3.046,31.222-4.483,46.842c-0.319,3.474,5.104,3.451,5.423,0c1.229-13.366,2.563-26.722,3.843-40.082
								c0.624-6.521,1.225-13.042,1.807-19.566c0.459-5.135,0.75-11.711,5.874-14.437C982.759,637.683,980.022,633,976.939,634.641
								L976.939,634.641z"/>
						</g>
						<g>
							<path d="M1074.12,634.641c-4.579,2.436-6.726,6.981-7.686,11.906c-1.274,6.542-1.419,13.394-2.04,20.019
								c-1.464,15.616-3.047,31.222-4.483,46.842c-0.32,3.474,5.104,3.451,5.422,0c1.23-13.366,2.564-26.722,3.843-40.082
								c0.624-6.521,1.226-13.042,1.808-19.566c0.458-5.135,0.75-11.711,5.874-14.437
								C1079.939,637.683,1077.203,633,1074.12,634.641L1074.12,634.641z"/>
						</g>
						<g>
							<path fill="#434556" d="M983.292,625.033l83.98,8.1c7.861-0.058,18.587,3.588,23.957,8.144l18.8,10.345
								c5.717,4.85,0.913,17.913-11.136,22.046c-15.448,5.299-37.108,6.162-62.988,1.527c-7.945-1.424-21.499-6.192-27.216-11.041
								l-26.03-22.083C974.3,636.648,975.431,625.091,983.292,625.033z"/>
							<path fill="#62667C" d="M986.656,624.413l80.102-0.586c7.861-0.058,18.587,3.589,23.957,8.145l16.979,14.403
								c5.717,4.85,2.734,13.854-9.314,17.987c-15.448,5.298-37.108,6.162-62.988,1.526c-7.945-1.423-21.499-6.191-27.216-11.041
								l-26.03-22.082C976.775,628.21,978.795,624.471,986.656,624.413z"/>
						</g>
					</g>
					<g>
						
							<linearGradient id="SVGID_65_" gradientUnits="userSpaceOnUse" x1="48.2681" y1="414.3115" x2="107.165" y2="414.3115" gradientTransform="matrix(0.8013 -0.5982 0.5982 0.8013 789.6445 352.4903)">
							<stop  offset="0" style="stop-color:#6B73FF"/>
							<stop  offset="1" style="stop-color:#000DFF"/>
						</linearGradient>
						<path fill="url(#SVGID_65_)" d="M1088.554,627.442c3.798-4.412,15.941-18.656,22.539-17.944
							c7.167,0.773,10.521,8.26,10.165,13.602c-0.423,6.364-2.985,11.498-6.12,19.463c-2.813,7.146-14.739,20.846-26.772,23.517
							C1075.935,668.838,1078.37,639.271,1088.554,627.442z"/>
						<g>
							<g>
								<path fill="#FDB9B1" d="M1084.423,482.207c-0.936,10.58,8.423,2.488,8.971-1.002c0.549-3.49-0.208-6.271-2.463-6.601
									C1088.676,474.276,1084.733,478.689,1084.423,482.207z"/>
								<path fill="#FED0CA" d="M1082.567,482.517c-0.597,1.446-0.985,2.693-1.283,3.814c-0.055,0.208-0.108,0.406-0.154,0.597
									c-0.578,2.268-0.931,3.994-2.331,5.774c-0.136,0.163-0.271,0.334-0.425,0.506c-2.024,2.241-5.893,4.637-14.144,8.314
									c-0.461,0.208-3.199,0.326-4.872,0.145c-0.56-0.063-1.003-0.153-1.202-0.289c-0.045-0.018-0.65-0.777-0.849-1.048
									c-0.046-0.055-0.063-0.081-0.063-0.091c-0.769-2.033-1.438-3.858-2.024-5.54c-1.536-4.438-2.477-7.907-3.036-11.613
									c-0.344-2.205-0.552-4.491-0.679-7.104c-0.063-1.383-0.045-2.675,0.055-3.886c0.018-0.253,0.045-0.515,0.072-0.759
									c0.795-7.212,4.491-11.388,9.887-14.117c6.462-3.28,13.476-1.608,14.976-1.581c3.38,0.343,6.1,2.603,7.663,5.287
									C1087.267,466.223,1085.017,476.588,1082.567,482.517z"/>
								<path fill="#FDB9B1" d="M1058.131,480.284c-1.229,10.437-8.81,0.711-8.641-2.78c0.17-3.491,1.481-6.032,3.771-5.896
									C1055.551,471.745,1058.539,476.813,1058.131,480.284z"/>
								<path fill="#FDB9B1" d="M1084.027,494.035c0.032-1.631,0.097-3.343,0.192-5.152c0.2-3.807-8.752-3.072-14.022-1.408
									c-8.513,2.688-11.917-9.807-12.066-4.886c-0.103,3.387-0.217,8.062-0.479,12.751c3.713-1.758,9.709-2.091,13.188-2.286
									C1074.776,492.833,1079.719,493.121,1084.027,494.035z"/>
								<linearGradient id="SVGID_66_" gradientUnits="userSpaceOnUse" x1="1054.6318" y1="502.8936" x2="1086.4717" y2="502.8936">
									<stop  offset="0" style="stop-color:#6B73FF"/>
									<stop  offset="1" style="stop-color:#000DFF"/>
								</linearGradient>
								<path fill="url(#SVGID_66_)" d="M1086.472,512.052c-4.953-2.215-9.228,0.063-31.84,0.75
									c0.832-1.049,1.446-3.263,1.916-6.092c0.027-0.181,0.055-0.361,0.082-0.542c0.28-1.771,0.497-3.75,0.678-5.838
									c0.136-1.627,0.253-3.308,0.343-4.989c3.715-1.762,9.707-2.087,13.186-2.286c2.278-0.127,4.898-0.081,7.538,0.153
									c1.915,0.163,3.841,0.435,5.657,0.823c-0.091,4.689,0.108,8.685,0.75,12.173
									C1085.17,508.319,1085.721,510.253,1086.472,512.052z"/>
								<linearGradient id="SVGID_67_" gradientUnits="userSpaceOnUse" x1="1050.9209" y1="467.5215" x2="1095.2412" y2="467.5215">
									<stop  offset="0" style="stop-color:#B85D40"/>
									<stop  offset="1" style="stop-color:#EF7432"/>
								</linearGradient>
								<path fill="url(#SVGID_67_)" d="M1092.4,475.359c-1.735,4.383-4.014,8.378-6.02,10.709
									c-0.316,0.371-0.578,0.741-0.795,1.103c-0.696,1.13-0.994,2.206-1.438,3.173c-0.307,0.705-0.687,1.355-1.338,1.934
									c-0.74,0.127-2.123,0.344-4.012,0.425c-3.778,0.172-9.563-0.181-16.195-2.783c-3.027-3.479-0.877-1.935-4.474-7.33v-0.009
									c-0.108-0.153-0.217-0.316-0.325-0.479c-0.913-1.347-2.143-3.073-3.832-5.332c-0.633-0.85-1.527-2.432-2.188-4.817
									c-0.054-0.199-0.1-0.397-0.153-0.614c-0.153-0.615-0.289-1.283-0.397-2.007c-0.506-3.299-0.497-7.646,0.759-13.122
									c1.374-6.01,11.288-12.599,17.009-13.466c9.553-1.446,15.888,0.813,19.955,4.907v0.009
									c3.081,3.082,4.853,7.212,5.747,11.577c0.063,0.072,0.136,0.127,0.154,0.208
									C1095.942,463.71,1094.596,469.847,1092.4,475.359z"/>
							</g>
						</g>
						
							<linearGradient id="SVGID_68_" gradientUnits="userSpaceOnUse" x1="883.0156" y1="624.5986" x2="740.4736" y2="624.5986" gradientTransform="matrix(-1 0 0 1 1851.1836 0)">
							<stop  offset="0" style="stop-color:#6B73FF"/>
							<stop  offset="1" style="stop-color:#000DFF"/>
						</linearGradient>
						<path fill="url(#SVGID_68_)" d="M974.047,581.952c6.434-2.485,17.897-1.115,47.462,10.102
							c17.908,6.794,41.3,22.332,50.666,38.958c11.487-8.183,25.694-13.792,32.57-6.947c11.418,12.809,6.706,34.795-16.38,42.015
							c-5.803,1.814-28.985,2.849-36.343,1.799c-6.376-0.909-17.563-3.511-26.354-8.956c-9.797-6.068-46.167-39.473-52.639-49.576
							C966.248,598.759,966.541,584.853,974.047,581.952z"/>
						<path fill="#1E1E1E" d="M1125.887,534.103c6.068,19.363,4.522,30.513,4.611,45.8c0.036,6.139,1.162,23.961-10.976,24.368
							c-10.569,0.355-16.234-13.332-17.76-19.467c-2.936-11.8-4.729-23.998-5.338-36.006c-0.46-9.073,3.975-26.582,14.551-31.941
							C1114.986,514.824,1122.579,523.551,1125.887,534.103z"/>
						<path d="M1117.768,560.42c-0.957,7.022-4.347,12.563-7.563,21.636c-0.605,1.735-1.221,3.598-1.799,5.631
							c-0.153,0.533-0.308,1.075-0.452,1.627c-0.542,1.987-1.048,4.13-1.509,6.452c-0.389,1.907-0.75,3.94-1.066,6.118
							c-0.389,2.612-0.108,5.793,0.389,9.228c0.487,3.39,1.184,7.031,1.645,10.574c0.308,2.341,0.506,4.645,0.47,6.814v0.009
							c-0.045,3.488-0.687,6.634-2.458,9.056c-0.055,0.072-0.108,0.145-0.172,0.217c-0.967,1.256-2.259,2.35-3.813,3.28
							c-1.682,1.021-3.679,1.871-5.92,2.558c-0.009,0.009-0.018,0.009-0.027,0.009c-4.238,1.311-9.335,2.079-14.83,2.459
							c-3.28,0.217-6.706,0.298-10.158,0.271c-0.425,0-0.84,0-1.266-0.009c-21.057-0.316-43.1-4.519-43.515-5.594
							c-1.501-3.842-1.708-7.781-1.266-11.749c0.172-1.618,0.461-3.244,0.822-4.871c2.151-9.833,6.751-19.558,4.257-27.881
							c-0.063-0.217-0.145-0.506-0.244-0.85c-0.226-0.813-0.542-1.97-0.913-3.398c-0.289-1.084-0.614-2.322-0.967-3.696
							c-0.325-1.292-0.687-2.692-1.066-4.193c-3.425-13.655-8.342-34.686-9.028-43.75c-0.262-3.39-0.425-6.326-0.479-8.884
							c-0.19-7.944,0.687-12.282,3.308-15.147c1.843-2.033,4.563-3.316,8.404-4.627c7.881-2.693,17.768-4.6,28.08-5.54
							c1.283-0.117,2.566-0.217,3.858-0.298c8.206-0.552,16.557-0.47,24.293,0.334c13.963,1.446,25.893,5.242,31.252,11.984
							c0.009,0.009,0.009,0.009,0.018,0.018C1123.218,527.253,1119.033,551.22,1117.768,560.42z"/>
						<path fill="#1E1E1E" d="M1027.863,581.288c-0.055,0.461-0.1,1.075-0.145,1.816c-0.091,1.364-0.181,3.154-0.308,5.205
							c-0.1,1.744-0.235,3.67-0.416,5.694c-0.009,0.099-0.018,0.207-0.026,0.315c-0.624,6.842-1.808,14.642-4.365,19.63
							c-1.482,2.91-3.443,4.854-6.037,5.088c-0.389,0.036-0.795,0.036-1.221-0.009c-10.51-1.13-15.183-27.411-15.372-33.655
							c-0.009-0.253-0.019-0.516-0.019-0.769c-0.162-6.814,0.316-13.357,1.13-19.928c0-0.018,0.009-0.036,0.009-0.054
							c0.588-4.718,1.338-9.454,2.143-14.315c1.491-8.993,6.38-25.866,14.378-32.698c0.688-0.587,1.573-1.075,2.531-1.274
							c1.916-0.415,4.157,0.299,5.964,3.562c1.682,3.036,2.982,8.278,3.263,16.899
							C1029.869,552.133,1029.933,562.951,1027.863,581.288z"/>
					</g>
					<g>
						<g>
							<path d="M1016.694,641.716c-0.56,5.694-0.76,11.423-1.452,17.104c-0.244,2.006-0.136,5.107-2.084,6.102
								c-3.489,1.779-8.997-4.952-11.411-7.088c-2.605-2.305-6.454,1.517-3.834,3.834c4.864,4.303,16.993,15.687,21.458,4.214
								c2.823-7.255,1.994-16.512,2.746-24.166C1022.457,638.245,1017.032,638.269,1016.694,641.716L1016.694,641.716z"/>
						</g>
						<g>
							<path d="M1118.574,641.716c-0.266,2.38-2.805,23.883-7.165,22.396c-3.311-1.128-4.731,4.107-1.441,5.229
								c5.06,1.725,8.335-2.231,9.805-6.604c2.274-6.765,3.436-13.949,4.225-21.021
								C1124.383,638.253,1118.957,638.28,1118.574,641.716L1118.574,641.716z"/>
						</g>
						<path fill="#62667C" d="M1119.369,646.552c-25.299,16.147-82.192,14.409-101.553,0.55c-1.602-1.146-4.694-2.649-4.44-4.523
							l6.345-46.82c1.089-8.028,9.104-16.679,17.247-14.467c20.459,5.556,60.178,8.374,75.947-0.553
							c7.342-4.156,17.459,9.576,16.371,17.604l-6.457,44.733C1122.575,644.95,1121.029,645.492,1119.369,646.552z"/>
						<path fill="#434556" d="M1119.826,647.32c-24.133,15.02-80.758,14.493-99.226,1.602c-1.527-1.066-3.205-1.428-2.963-3.172
							l7.132-45.678c1.038-7.468,8.685-15.514,16.452-13.457c19.517,5.168,60.222,6.323,75.265-1.981
							c7.004-3.866,13.731,8.434,12.693,15.901l-6.053,43.552C1122.885,645.83,1121.41,646.335,1119.826,647.32z"/>
					</g>
				</g>
				<path opacity="0.2" fill="#ECF2FA" d="M1088.957,447.65v0.009c0,0-30.484-3.858-37.723,21.672
					c-0.506-3.299-0.497-7.646,0.759-13.122c1.374-6.01,11.288-12.599,17.009-13.466
					C1078.555,441.297,1084.89,443.557,1088.957,447.65z"/>
				<path opacity="0.2" fill="#ECF2FA" d="M1060.488,505.87c0,0-1.527,0.289-3.94,0.84c-8.043,1.826-23.581,9.46-31.165,12.038
					c-1.976,0.671-4.818,2.801-8.479,7.438c-6.046,7.682-12.828,23.254-15.811,38.438c0.588-4.718,1.338-9.454,2.143-14.315
					c1.491-8.993,6.38-25.866,14.378-32.698c0.688-0.587,1.573-1.075,2.531-1.274c1.843-2.033,4.563-3.316,8.404-4.627
					c7.881-2.693,17.768-4.6,28.08-5.54c0.28-1.771,0.497-3.75,0.678-5.838c-0.046-0.055-0.063-0.081-0.063-0.091
					c-0.769-2.033-1.438-3.858-2.024-5.54l2.431,0.642l1.708,6.326L1060.488,505.87z"/>
			</g>
			<g>
				<g>
					<g>
						<g>
							<path fill="#1E1E1E" d="M923.159,711.64c-15.289-10.521-28.886-7.238-35.614-19.495c0.126,2.701,0.908,5.477,2.039,7.026
								c3.987,5.464,7.371,6.875,10.193,7.296c2.108,0.313,1.881,0.225,3.899,0.437c2.678,0.279,2.211,2.373,4.328,4.247
								c1.651,1.46,6.797,5.89,14.276,5.229c5.829-0.515,3.699-3.665,4.424-6.281C925.61,710.537,925.21,711.147,923.159,711.64z"/>
							<linearGradient id="SVGID_69_" gradientUnits="userSpaceOnUse" x1="887.5264" y1="697.418" x2="927.4863" y2="697.418">
								<stop  offset="0" style="stop-color:#1E1E1E"/>
								<stop  offset="1" style="stop-color:#63322B"/>
							</linearGradient>
							<path fill="url(#SVGID_69_)" d="M927.229,701.216c-0.131-3.558-0.961-8.682-3.449-13.782
								c-2.842-3.208-11.103-6.122-15.805-4.034c-10.403,4.62-14.179,0.737-17.263,1.535c-2.473,0.64-3.324,3.867-3.167,7.21
								c2.039,7.026,4.521,8.269,9.949,11.056c1.366,0.702,4.85,1.024,6.301,1.619c1.275,0.521,3.874,3.749,5.145,4.393
								c10.492,5.318,16.463,1.979,17.766,0.887C928.034,706.76,927.275,702.512,927.229,701.216z"/>
							<path fill="#1E1E1E" d="M907.037,690.871c2.85,3.49,13.403,5.624,16.614,1.607c1.896-2.372-0.293-18.274-2.164-19.685
								c-3.079-2.321-9.684-2.574-13.277-1.748c-4.426,1.017-5.542,2.857-4.918,7.03
								C903.974,682.636,904.471,687.729,907.037,690.871z"/>
							<path fill="#1E1E1E" d="M888.624,588.053c-1.179,7.125,4.385,37.679,6.408,59.444c0.922,9.916,1.59,26.103,1.993,36.049
								c7.309,5.415,25.007,6.319,32.533,0.462c0.054-6.895-1.393-25.732-1.722-32.599c-0.415-8.662,0.966-17.29,0.487-25.978
								c-0.582-10.554-2.541-22.533-7.022-32.213c-4.16-8.987-11.44-14.434-21.69-14.653
								C895.868,578.486,889.733,581.347,888.624,588.053z"/>
						</g>
						<g>
							
								<linearGradient id="SVGID_70_" gradientUnits="userSpaceOnUse" x1="826.4844" y1="702.5605" x2="878.9238" y2="702.5605" gradientTransform="matrix(0.9953 0.0964 -0.0964 0.9953 65.6995 -79.8405)">
								<stop  offset="0" style="stop-color:#1E1E1E"/>
								<stop  offset="1" style="stop-color:#63322B"/>
							</linearGradient>
							<path fill="url(#SVGID_70_)" d="M871.496,717.255c-0.002-0.814-0.012-1.693,0.043-2.617
								c0.224-3.734,1.949-17.425-0.732-19.309c-2.647-3.643-10.758-6.501-16.119-5.819c-5.883,0.748-10.445,5.096-16.158,5.979
								c-5.648,0.871-10.334-0.952-15.389-0.908c-0.938,0.008-1.644,0.144-2.167,0.372
								C830.839,712.149,853.478,713.687,871.496,717.255z"/>
							<path fill="#1E1E1E" d="M869.932,680.972c1.589,2.507,2.208,12.974,0.313,15.345c-3.211,4.018-13.765,1.884-16.614-1.606
								c-2.566-3.144-3.064-8.235-3.745-12.795c-0.624-4.173,0.492-6.014,4.918-7.03c3.593-0.826,10.198-0.573,13.277,1.748
								C869.951,678.043,871.909,681.456,869.932,680.972z"/>
							<path fill="#1E1E1E" d="M833.857,592.567c-1.18,7.125,4.385,37.679,6.407,59.444c0.922,9.916,1.591,26.103,1.993,36.049
								c7.309,5.415,25.008,6.32,32.534,0.463c0.054-6.896-1.394-25.733-1.722-32.599c-0.416-8.662,0.966-17.29,0.486-25.979
								c-0.581-10.554-2.541-22.533-7.021-32.213c-4.16-8.987-11.905-15.267-22.154-15.485
								C840.638,582.169,834.967,585.862,833.857,592.567z"/>
							<path fill="#1E1E1E" d="M820.974,694.952c-3.137,1.373,0.497,6.224,2.756,8.715c4.383,4.835,7.64,5.542,13.59,8.158
								c2.724,1.198,5.87,1.952,8.779,2.677c2.173,0.542,3.464-1.397,5.553-0.972c4.933,1.003-2.021,8.633,16.084,8.684
								c3.665,0.011,3.77-3.184,3.761-6.101C869.086,717.422,830.839,712.149,820.974,694.952z"/>
						</g>
						<g>
							<g>
								<path d="M831.688,639.148c6.706,5.443,13.892,11.163,17.857,19.032c4.776,9.479,4.519,21.342,5.564,31.696
									c2.108,20.873,4.154,41.753,5.974,62.653c0.301,3.45,5.726,3.481,5.423,0c-1.971-22.633-4.206-45.243-6.5-67.846
									c-0.997-9.822-1.218-20.26-5.779-29.24c-4.208-8.285-11.627-14.385-18.705-20.131
									C832.835,633.133,828.976,636.947,831.688,639.148L831.688,639.148z"/>
							</g>
							<g>
								<path d="M939.673,639.148c6.707,5.443,13.892,11.163,17.857,19.032c4.777,9.479,4.519,21.342,5.564,31.696
									c2.108,20.873,4.155,41.753,5.975,62.653c0.3,3.45,5.726,3.481,5.423,0c-1.971-22.633-4.206-45.243-6.5-67.846
									c-0.997-9.822-1.218-20.26-5.779-29.24c-4.208-8.285-11.627-14.385-18.705-20.131
									C940.821,633.133,936.962,636.947,939.673,639.148L939.673,639.148z"/>
							</g>
							<g>
								<path d="M830.165,634.27c-4.579,2.437-6.726,6.982-7.686,11.907c-1.274,6.542-1.419,13.394-2.04,20.018
									c-1.464,15.617-3.046,31.223-4.483,46.842c-0.32,3.474,5.104,3.452,5.422,0c1.23-13.365,2.564-26.721,3.843-40.081
									c0.624-6.521,1.226-13.042,1.809-19.566c0.458-5.135,0.749-11.711,5.873-14.437
									C835.984,637.313,833.248,632.63,830.165,634.27L830.165,634.27z"/>
							</g>
							<g>
								<path d="M927.346,634.27c-4.58,2.437-6.727,6.982-7.686,11.907c-1.274,6.542-1.419,13.394-2.04,20.018
									c-1.464,15.617-3.046,31.223-4.483,46.842c-0.32,3.474,5.104,3.452,5.422,0c1.23-13.365,2.564-26.721,3.843-40.081
									c0.624-6.521,1.226-13.042,1.808-19.566c0.458-5.135,0.75-11.711,5.874-14.437
									C933.165,637.313,930.429,632.63,927.346,634.27L927.346,634.27z"/>
							</g>
							<g>
								<path fill="#434556" d="M836.518,624.663l83.98,8.099c7.861-0.057,18.587,3.589,23.957,8.145l18.8,10.345
									c5.717,4.85,0.913,17.913-11.137,22.046c-15.447,5.298-37.107,6.162-62.987,1.526c-7.945-1.423-21.5-6.191-27.216-11.041
									l-26.03-22.082C827.525,636.277,828.656,624.721,836.518,624.663z"/>
								<path fill="#62667C" d="M839.882,624.043l80.102-0.586c7.861-0.058,18.587,3.589,23.957,8.144l16.979,14.404
									c5.717,4.85,2.735,13.854-9.314,17.987c-15.448,5.298-37.108,6.161-62.988,1.526c-7.945-1.423-21.499-6.191-27.216-11.041
									l-26.03-22.082C830,627.84,832.021,624.101,839.882,624.043z"/>
							</g>
						</g>
						<g>
							
								<linearGradient id="SVGID_71_" gradientUnits="userSpaceOnUse" x1="-75.8867" y1="325.4082" x2="-19.4507" y2="325.4082" gradientTransform="matrix(0.8013 -0.5982 0.5982 0.8013 789.6445 352.4903)">
								<stop  offset="0" style="stop-color:#6B73FF"/>
								<stop  offset="1" style="stop-color:#000DFF"/>
							</linearGradient>
							<path fill="url(#SVGID_71_)" d="M935.604,631.492c3.572-4.303,14.994-18.192,21.199-17.498
								c6.741,0.754,9.896,8.055,9.562,13.263c-0.398,6.206-2.809,11.213-5.756,18.979c-2.646,6.969-13.863,20.327-25.182,22.932
								C923.735,671.858,926.026,643.027,935.604,631.492z"/>
							<g>
								<g>
									<path fill="#FDB9B1" d="M936.711,489.506c-0.49,8.369,7.131,1.872,7.493-0.891c0.362-2.765-0.349-4.953-2.249-5.188
										C940.056,483.192,936.874,486.723,936.711,489.506z"/>
									<path fill="#FED0CA" d="M939.133,474.14c-0.1,4.563-1.311,9.598-2.611,13.086c-0.633,1.689-0.985,3.091-1.266,4.338
										c-0.009,0.027-0.009,0.045-0.018,0.072c-0.235,1.058-0.425,1.988-0.705,2.874c-0.299,0.931-0.688,1.808-1.329,2.702
										c-1.437,1.997-4.139,4.085-9.851,7.122h-0.009c-1.121,0.596-2.341,1.229-3.696,1.897c-0.253,0.126-1.266,0.244-2.377,0.289
										h-0.1c-1.103,0.055-2.286,0.019-2.901-0.136c-0.036-0.009-0.072-0.018-0.099-0.036c-0.1-0.026-0.182-0.063-0.235-0.099
										c-0.009,0-0.009-0.01-0.019-0.019c-0.036-0.036-0.117-0.127-0.217-0.253c-0.262-0.316-0.642-0.796-0.65-0.813
										c-1.238-3.182-2.224-5.848-3-8.261c-0.01-0.009-0.01-0.018-0.01-0.026c-0.289-0.886-0.551-1.727-0.777-2.558v-0.01
										c-0.389-1.328-0.704-2.603-0.967-3.886c-0.542-2.594-0.867-5.206-1.084-8.287c-0.019-0.154-0.027-0.316-0.036-0.47
										c-0.01-0.172-0.019-0.335-0.027-0.498c0-0.09-0.009-0.189-0.009-0.279c-0.019-0.29-0.027-0.569-0.027-0.841
										c-0.019-1.147,0.018-2.232,0.126-3.254v-0.009c0.063-0.669,0.154-1.319,0.271-1.934c0-0.01,0.009-0.027,0.009-0.036
										c0.19-0.968,0.435-1.871,0.741-2.712v-0.009c0.371-1.048,0.832-1.997,1.365-2.883c0.497-0.822,1.066-1.582,1.698-2.286
										c1.365-1.51,3.019-2.757,4.898-3.806c6.02-3.361,12.644-1.915,14.054-1.934c3.19,0.253,5.784,2.386,7.312,4.952
										C938.808,468.192,939.224,471.058,939.133,474.14z"/>
									<path fill="#434556" d="M934.705,498.595c-0.769,2.269-0.904,3.579-7.466,5.721c-1.925,0.624-4.401,1.319-7.609,2.133
										c-0.253,0.063-1.256,0.091-2.358,0.072c-0.036,0.009-0.063,0.009-0.1,0c-1.084-0.009-2.25-0.072-2.874-0.181
										c-0.045-0.009-0.081-0.018-0.117-0.018h-0.009c-0.1-0.027-0.182-0.046-0.235-0.072c-0.009,0-0.009-0.01-0.019-0.019
										c-0.09-0.063-0.181-0.136-0.279-0.217c-2.16-1.799-5.604-6.868-4.447-11.433c0.019-0.081,0.037-0.172,0.072-0.262v-0.01
										c0.299-1.048,0.859-2.061,1.745-2.991c0.271-0.298,0.587-0.597,0.939-0.876c0.009-0.01,0.018-0.019,0.018-0.027
										c0.027-0.136,0.019-0.66,0.019-1.355v-0.01c0.019-1.075,0.081-2.566,0.406-3.75v-0.01c0.362-1.273,1.021-2.178,2.269-1.798
										c3.706,1.111,9.029,5.251,13.259,8.911c0.885,0.777,1.734,1.527,2.503,2.232h0.009c1.103,1.003,2.052,1.897,2.774,2.575
										C934.09,498.043,934.642,498.567,934.705,498.595z"/>
									<path fill="#FDB9B1" d="M912.707,487.623c-0.081,0.795-0.208,1.446-0.379,1.971c-0.1,0.325-0.227,0.596-0.362,0.822
										c0,0.009-0.009,0.018-0.018,0.027c-0.262,0.461-0.588,0.731-0.939,0.876c-0.813,0.308-1.799-0.117-2.712-0.895
										c-1.545-1.292-2.892-3.569-2.855-4.844c0.036-1.527,0.37-2.829,0.976-3.688c0.208-0.299,0.452-0.552,0.732-0.724
										c0.163-0.117,0.344-0.207,0.542-0.262c0.244-0.081,0.497-0.117,0.777-0.108c0.371,0.019,0.76,0.172,1.147,0.434
										c1.121,0.75,2.241,2.386,2.774,4.058c0,0,0.01,0,0,0.01C912.653,486.096,912.779,486.9,912.707,487.623z"/>
									<path fill="#FDB9B1" d="M940.782,515.91c-4.706-2.03-7.121-7.798-29.375-0.779c2.637-3.654,3.771-14.222,3.849-22.865
										c0.043-4.8,0.956-0.845,9.729,0.117c5.197,0.57,14.041-2.614,13.217,1.007C935.331,505.99,938.394,510.335,940.782,515.91z
										"/>
									<path fill="#FED0CA" d="M944.809,468.608c0,0.145-1.211,3.579-5.676,5.531c-1.853,0.813-4.266,1.364-7.374,1.328
										c-8.342-0.108-14.831-1.292-20.435-8.54c-0.451-0.588-0.903-1.221-1.346-1.89c-0.471-0.704-0.922-1.455-1.383-2.259
										c-0.019-0.036-0.046-0.072-0.063-0.108c0.389-0.813,0.858-1.69,1.409-2.585c0-0.018,0.01-0.036,0.019-0.045
										c1.256-2.07,2.91-4.239,4.952-6.028c1.176-1.03,2.468-1.934,3.896-2.621c0.036-0.026,0.081-0.045,0.127-0.063
										c2.105-1.003,4.5-1.536,7.157-1.301c0.244,0.018,0.479,0.045,0.705,0.072c0.117,0.009,0.235,0.027,0.353,0.036
										c2.088,0.234,3.886,0.597,5.468,1.094h0.009c0.289,0.09,0.569,0.181,0.841,0.28c4.672,1.672,7.157,4.51,8.757,8.134
										C943.381,462.264,944.076,465.301,944.809,468.608z"/>
									<path fill="#434556" d="M946.535,477.112c2.322,4.257,1.753,7.366-2.513,8.252c-0.244,0.054-0.497,0.099-0.759,0.135
										c0.361,2.946-0.66,5.215-5.062,7.89c-0.072,0.046-0.136,0.082-0.208,0.108c-0.958,0.543-2.15,0.85-3.461,1.013
										c-1.283,0.162-2.685,0.181-4.104,0.127h-0.009c-4.166-0.145-8.478-0.896-10.502-0.498c-1.319,0.263-3.072-0.37-4.672-1.526
										c-1.112-0.796-2.142-1.844-2.919-3.019c-0.118-0.182-0.235-0.353-0.344-0.533v-0.01c-1.021-1.78-1.419-3.786-0.578-5.63
										c-0.777-0.271-1.455-0.605-2.052-1.013c-0.669-0.425-1.22-0.939-1.663-1.5c-0.226-0.271-0.416-0.561-0.578-0.858
										c-1.311-2.286-1.147-5.215,0.253-7.953c-3.777-0.65-4.754-11.071,2.575-12.011c0.01-0.009,0.027-0.009,0.037-0.009
										c-0.01-0.009-0.01-0.027-0.019-0.036c-0.334-0.724-0.497-1.925-0.406-3.235c0.253-3.923,2.72-8.884,9.254-5.414
										c0.036,0.019,0.072,0.036,0.108,0.055c0-0.036,0.009-0.081,0.019-0.118c0.578-3.994,10.817-9.073,13.863-0.659
										c4.094-4.673,11.73,1.744,9.923,5.974c-0.018,0.027-0.027,0.045-0.027,0.072c-0.09,0.199-0.207,0.397-0.343,0.588
										c2.467,0.352,4.283,1.256,5.386,2.548c1.745,2.024,1.718,5.025-0.298,8.396
										C951.415,470.741,951.216,474.709,946.535,477.112z"/>
								</g>
								<linearGradient id="SVGID_72_" gradientUnits="userSpaceOnUse" x1="907.3662" y1="510.7383" x2="946.0557" y2="510.7383">
									<stop  offset="0" style="stop-color:#6B73FF"/>
									<stop  offset="1" style="stop-color:#000DFF"/>
								</linearGradient>
								<path fill="url(#SVGID_72_)" d="M946.056,517.167l-0.723-0.019l-37.958-0.777c-0.009,0-0.009,0-0.009,0
									c0.045-0.054,0.091-0.117,0.127-0.181c1.347-1.979,1.753-7.7,6.146-10.176c0.018-0.019,0.045-0.027,0.063-0.036
									c0.181-0.108,0.371-0.199,0.569-0.28v-0.009c0.235-0.108,0.479-0.208,0.732-0.289c0.009-0.01,0.009-0.01,0.009-0.01
									c1.926-0.669,5.025-0.958,8.333-1.057h0.009c1.283-0.027,2.603-0.027,3.886-0.019c3.679,0.045,7.158,0.262,9.155,0.425
									c0.253,0.018,0.506,0.045,0.75,0.081C947.602,506.141,945.712,517.067,946.056,517.167z"/>
							</g>
							<path fill="#1E1E1E" d="M841.992,582.638c6.051-2.424,13.224,2.018,31.753,12.983c15.577,9.218,37.645,23.14,46.455,39.352
								c10.804-7.979,24.166-13.449,30.634-6.774c10.739,12.49,6.308,33.93-15.406,40.97c-5.457,1.77-27.263,2.777-34.183,1.754
								c-5.996-0.887-16.52-3.423-24.787-8.732c-9.214-5.918-35.269-43.519-41.356-53.37
								C828.723,598.495,834.933,585.465,841.992,582.638z"/>
							
								<linearGradient id="SVGID_73_" gradientUnits="userSpaceOnUse" x1="884.0771" y1="540.0176" x2="884.0771" y2="631.7734" gradientTransform="matrix(-0.9999 -0.0145 -0.0145 0.9999 1857.1432 -4.4044)">
								<stop  offset="0" style="stop-color:#6B73FF"/>
								<stop  offset="1" style="stop-color:#000DFF"/>
							</linearGradient>
							<path fill="url(#SVGID_73_)" d="M975.184,545.856c4.215,19.27,5.133,30.919,4.052,45.787
								c-0.435,5.97-0.736,23.379-12.148,22.884c-9.938-0.432-14.207-14.155-15.17-20.23c-1.854-11.688-2.606-23.678-2.262-35.396
								c0.26-8.854-1.156-31.676,9.17-36.108C962.74,521.111,972.887,535.355,975.184,545.856z"/>
							<linearGradient id="SVGID_74_" gradientUnits="userSpaceOnUse" x1="923.2363" y1="652.3232" x2="923.2363" y2="515.3057">
								<stop  offset="0" style="stop-color:#6B73FF"/>
								<stop  offset="1" style="stop-color:#000DFF"/>
							</linearGradient>
							<path fill="url(#SVGID_74_)" d="M968.785,571.771c-0.669,3.145-1.808,5.938-3.209,8.901
								c-0.948,2.007-2.023,4.095-3.153,6.417c-2.269,4.672-4.763,10.294-6.959,18.192c-0.506,1.835-0.994,3.787-1.464,5.884
								c-0.235,1.057-0.371,2.223-0.435,3.479c-0.262,5.612,0.958,12.869,0.913,19.34c-0.018,2.142-0.172,4.193-0.561,6.073
								c-0.389,1.853-1.012,3.543-1.979,4.962c-0.163,0.253-0.335,0.497-0.533,0.731c-0.027,0.036-0.055,0.072-0.082,0.108
								c-4.003,4.826-13.005,6.498-23.443,6.462H927.8c-2.015-0.009-4.094-0.081-6.19-0.208c-9.02-0.533-18.536-2.051-26.498-3.696
								c-10.673-2.205-18.563-4.654-18.762-5.323c-1.41-4.861-0.877-9.742,0.397-14.55c0.416-1.608,0.922-3.199,1.455-4.781
								c2.63-7.735,6.128-15.228,5.351-22.169c-0.046-0.497-0.127-0.985-0.227-1.473c-0.145-0.724-0.361-2.024-0.605-3.76
								c-0.217-1.464-0.451-3.235-0.705-5.232c-0.325-2.513-0.668-5.387-1.012-8.433c-1.238-10.908-2.44-24.058-2.404-31.314
								c0.036-7.004-0.198-12.427-0.081-16.665c0.163-6.326,1.111-10.032,4.88-12.454c1.211-0.777,2.721-1.428,4.592-1.988
								c5.458-1.636,11.794-2.937,18.463-3.786c0.308-0.046,0.605-0.082,0.913-0.118c0,0,0,0,0.009,0
								c11.044-1.346,22.938-1.491,33.34-0.009c1.581,0.226,3.117,0.488,4.618,0.786c9.941,1.998,18.012,5.712,21.825,11.568
								c0.353,0.542,0.678,1.139,0.977,1.789c0,0.009,0.009,0.019,0.018,0.027C972.915,540.926,970.557,563.43,968.785,571.771z"/>
							<linearGradient id="SVGID_75_" gradientUnits="userSpaceOnUse" x1="873.5449" y1="522.2578" x2="873.5449" y2="611.0391">
								<stop  offset="0" style="stop-color:#6B73FF"/>
								<stop  offset="1" style="stop-color:#000DFF"/>
							</linearGradient>
							<path fill="url(#SVGID_75_)" d="M887.908,541.17c-0.523,13.945-1.166,23.787-4.22,40.127
								c-0.027,0.153-0.063,0.316-0.091,0.47c-0.289,1.527-0.325,4.591-0.47,8.179c-0.081,2.034-0.199,4.229-0.407,6.417
								c-0.145,1.482-0.325,2.946-0.569,4.356c-0.886,4.997-2.558,9.209-5.893,10.131c-0.804,0.226-1.708,0.253-2.711,0.063
								c-0.895-0.172-1.753-0.516-2.575-1.004c-0.109-0.072-0.217-0.145-0.326-0.217c-5.44-3.569-8.992-13.574-10.582-21.066
								c-0.082-0.38-0.154-0.75-0.227-1.111c-0.542-2.819-0.786-5.179-0.731-6.57c0.353-8.947,3.334-16.828,5.847-25.224
								c0-0.009,0.009-0.019,0.009-0.027c0.072-0.234,0.145-0.479,0.218-0.723c0-0.019,0-0.027,0.009-0.045
								c0.162-0.524,0.316-1.066,0.461-1.6c0.334-1.166,0.65-2.351,0.939-3.543c1.979-8.098,7.881-19.639,14.262-26.128
								c0.74-0.759,1.645-1.337,2.548-1.392c1.157-0.081,2.323,0.688,3.19,3.01C887.61,528.012,888.225,532.928,887.908,541.17z"/>
						</g>
						<g>
							<g>
								<path d="M869.92,641.346c-0.56,5.693-0.76,11.423-1.452,17.104c-0.244,2.005-0.136,5.107-2.084,6.102
									c-3.489,1.778-8.997-4.953-11.411-7.088c-2.605-2.305-6.454,1.517-3.834,3.834c4.864,4.302,16.993,15.687,21.458,4.214
									c2.823-7.255,1.994-16.512,2.745-24.166C875.683,637.875,870.258,637.898,869.92,641.346L869.92,641.346z"/>
							</g>
							<g>
								<path d="M971.8,641.346c-0.266,2.38-2.805,23.883-7.165,22.396c-3.311-1.129-4.731,4.107-1.441,5.229
									c5.06,1.725,8.335-2.232,9.805-6.604c2.274-6.765,3.436-13.95,4.225-21.021C977.608,637.883,972.183,637.91,971.8,641.346
									L971.8,641.346z"/>
							</g>
							<path fill="#62667C" d="M972.595,646.182c-25.299,16.146-82.192,14.408-101.553,0.549c-1.602-1.146-4.694-2.648-4.44-4.522
								l6.345-46.82c1.089-8.028,9.104-16.679,17.247-14.468c20.459,5.557,60.178,8.375,75.947-0.552
								c7.342-4.157,17.459,9.576,16.371,17.604l-6.457,44.732C975.801,644.579,974.255,645.122,972.595,646.182z"/>
							<path fill="#434556" d="M973.052,646.95c-24.134,15.02-80.758,14.493-99.226,1.602c-1.527-1.066-3.205-1.429-2.963-3.172
								l7.132-45.678c1.038-7.468,8.685-15.514,16.452-13.457c19.517,5.168,60.222,6.322,75.265-1.981
								c7.004-3.866,13.731,8.434,12.693,15.901l-6.054,43.552C976.109,645.46,974.636,645.965,973.052,646.95z"/>
						</g>
					</g>
					<path opacity="0.2" fill="#ECF2FA" d="M942.721,456.643c-0.018,0.027-0.027,0.045-0.027,0.072
						c-0.009,0.091,0.019,0.226-0.343,0.588c0.172-3.055-5.351-6.534-8.884-5.793c-1.03,0.217-1.898,0.795-2.396,1.844
						c-0.479-1.564-2.196-2.983-3.931-3.154c-0.118-0.01-0.235-0.019-0.353-0.019c-0.108,0-0.227,0.009-0.335,0.019
						c-2.061,0.198-3.045,2.087-3.822,3.94c-2.459-2.26-5.595-1.898-7.709-0.118c-1.474,1.238-2.44,3.173-2.341,5.387
						c-2.088,0.361-2.874,1.491-3.606,2.666c-0.145,0.235-0.271,0.47-0.379,0.705c-1.771,3.651-0.316,7.907-0.335,9.264v0.063
						c-0.027,0.769-0.434,1.763-0.741,2.712c0,0.009-0.009,0.026-0.009,0.036c-0.217,0.695-0.37,1.364-0.28,1.897
						c0,0.019,0.009,0.027,0.009,0.036v0.009c0.146,0.841,0.1,2.034,0.452,4.112c0.055,0.325-0.09,0.299-0.334,0.136
						c-0.072-0.036-0.145-0.091-0.217-0.153c-0.019-0.29-0.027-0.569-0.027-0.841c-1.311-2.286-1.147-5.215,0.253-7.953
						c-3.777-0.65-4.754-11.071,2.575-12.011c0-0.018,0.01-0.036,0.019-0.045c-0.334-0.724-0.497-1.925-0.406-3.235
						c0.253-3.923,2.72-8.884,9.254-5.414c0.036-0.026,0.081-0.045,0.127-0.063c0.578-3.994,10.817-9.073,13.863-0.659
						C936.892,445.996,944.528,452.413,942.721,456.643z"/>
					<path opacity="0.2" fill="#ECF2FA" d="M887.99,520.275c5.458-1.636,11.794-2.937,18.463-3.786
						c-7.085,2.024-19.34,5.919-19.864,8.784c0,0-3.724,4.148-8.07,9.444c-5.124,6.245-11.116,14.08-12.869,18.608
						c0.334-1.166,0.65-2.351,0.939-3.543c1.979-8.098,7.881-19.639,14.262-26.128c0.74-0.759,1.645-1.337,2.548-1.392
						C884.609,521.486,886.119,520.836,887.99,520.275z"/>
				</g>
				<path opacity="0.2" fill="#ECF2FA" d="M915.012,505.391c0,0,0,0-0.009,0.01c-0.055,0.072-0.325,0.397-0.705,0.939
					c-0.009,0.009-0.018,0.027-0.027,0.045c-0.045,0.063-0.09,0.136-0.145,0.208c-1.13,1.69-2.928,4.979-2.72,8.54
					c0,0-1.563,0.398-3.913,1.058c1.347-1.979,1.753-7.7,6.146-10.176c-2.16-1.799-5.604-6.868-4.447-11.433
					c0.182,0.533,0.471,1.347,0.85,2.296c0,0.009,0,0.018,0.01,0.026c0.967,2.432,2.503,5.775,4.347,7.854
					C914.596,504.984,914.804,505.201,915.012,505.391z"/>
			</g>
			<g>
				<g>
					<g>
						
							<linearGradient id="SVGID_76_" gradientUnits="userSpaceOnUse" x1="-1325.6387" y1="651.5332" x2="-1290.4541" y2="651.5332" gradientTransform="matrix(1 0 0 1 2056.667 0)">
							<stop  offset="0" style="stop-color:#1E1E1E"/>
							<stop  offset="1" style="stop-color:#63322B"/>
						</linearGradient>
						<path fill="url(#SVGID_76_)" d="M733.221,641.932c-2.404,1.363-2.068,12.996-2.184,16.148
							c-0.132,3.576,1.242,7.656,5.194,7.536c15.181-0.461,10.581-5.992,14.803-6.434c1.788-0.188,0.441,0.63,2.31,0.352
							c2.501-0.372,6.645-2.362,10.178-7.203c2.241-3.072,4.938-10.276-0.471-11.45c-3.594-0.78-5.813,4.171-15.825-2.524
							C743.437,635.822,735.739,639.089,733.221,641.932z"/>
						<path fill="#1E1E1E" d="M733.335,646.4c2.845,3.56,12.196,1.669,14.722-1.423c2.273-2.786,2.714-7.298,3.318-11.338
							c0.553-3.697-0.437-5.328-4.357-6.229c-3.185-0.731-9.037-0.508-11.766,1.549C733.595,630.209,731.655,644.3,733.335,646.4z"
							/>
						<path fill="#1E1E1E" d="M764.372,553.871c1.044,6.313-3.886,33.387-5.678,52.673c-0.817,8.786-1.409,23.128-1.767,31.941
							c-6.476,4.799-22.158,5.601-28.827,0.41c-0.048-6.109,1.234-22.802,1.525-28.885c0.368-7.676-0.855-15.32-0.432-23.019
							c0.516-9.352,2.252-19.967,6.222-28.543c3.687-7.964,10.139-12.79,19.221-12.984
							C757.953,545.395,763.389,547.93,764.372,553.871z"/>
					</g>
					<g>
						<linearGradient id="SVGID_77_" gradientUnits="userSpaceOnUse" x1="626.7217" y1="652.7129" x2="671.4766" y2="652.7129">
							<stop  offset="0" style="stop-color:#1E1E1E"/>
							<stop  offset="1" style="stop-color:#63322B"/>
						</linearGradient>
						<path fill="url(#SVGID_77_)" d="M671.477,663.746c-0.063-0.655-0.133-1.347-0.159-2.054
							c-0.116-3.152,0.22-14.785-2.185-16.148c-2.518-2.843-9.559-4.583-14.004-3.575c-4.877,1.105-8.354,5.127-13.078,6.331
							c-4.671,1.191-9.069-0.886-13.309-0.438c-1.215,0.128-1.635,1.388-2.021,1.852
							C636.646,662.255,656.018,661.207,671.477,663.746z"/>
						<path fill="#1E1E1E" d="M626.722,649.714c-1.363,1.64,1.661,4.92,3.57,6.655c4.072,3.703,6.863,4.031,12.07,5.744
							c2.385,0.784,5.087,1.161,7.588,1.533c1.868,0.278,2.795-1.454,4.583-1.268c4.222,0.441-0.974,5.521,14.207,5.983
							c3.171,0.096,2.997-1.955,2.736-4.616C656.018,661.207,636.646,662.255,626.722,649.714z"/>
						<path fill="#1E1E1E" d="M654.128,645.694c2.524,3.092,11.876,4.982,14.721,1.423c1.68-2.101,1.131-11.375-0.277-13.597
							c1.752,0.43,0.018-2.595-1.64-3.845c-2.729-2.056-8.581-2.28-11.765-1.549c-3.922,0.901-4.91,2.532-4.358,6.23
							C651.413,638.397,651.854,642.909,654.128,645.694z"/>
						<path fill="#1E1E1E" d="M672.879,640.212c-0.479,0.371-1.003,0.714-1.572,1.021c-0.451,0.253-0.931,0.488-1.428,0.687
							c-1.311,0.561-2.774,0.985-4.329,1.283c-4.058,0.787-8.721,0.732-12.878-0.054c-0.181-0.036-0.361-0.072-0.543-0.108
							c-3.208-0.66-6.082-1.763-8.079-3.235c-0.353-8.821-0.949-23.154-1.763-31.947c-0.145-1.573-0.316-3.19-0.497-4.854
							c-1.139-10.312-2.892-22.024-4.075-31.433c-0.958-7.654-1.537-13.782-1.112-16.385c0.452-2.757,1.79-5.693,3.435-8.025
							c-0.009-0.009-0.009-0.018,0.009-0.018c1.238-1.781,2.648-3.209,3.977-3.959c0.678-0.389,1.338-0.597,1.935-0.587
							c0.027,0,0.063,0,0.09,0.009c2.241,0.054,4.348,0.65,6.3,1.645c5.919,2.982,10.447,9.544,13.212,15.518
							c2.929,6.309,4.637,13.719,5.54,20.922c0.108,0.841,0.199,1.681,0.29,2.513c0.171,1.734,0.307,3.434,0.397,5.105
							c0.153,2.784,0.09,5.55-0.036,8.324c-0.127,2.883-0.325,5.774-0.416,8.657c-0.063,2.016-0.081,4.022,0.019,6.037
							c0.026,0.552,0.063,1.202,0.099,1.916c0.055,0.931,0.118,1.979,0.19,3.118C672.12,623.954,672.916,635.386,672.879,640.212z"
							/>
					</g>
					<g>
						<g>
							<path d="M647.717,594.409c6.17,5.01,12.87,10.315,16.231,17.735c3.826,8.445,3.608,18.827,4.525,27.914
								c1.835,18.189,3.614,36.385,5.2,54.598c0.301,3.45,5.726,3.481,5.423,0c-1.736-19.937-3.704-39.853-5.724-59.763
								c-0.864-8.521-1.043-17.598-4.743-25.486c-3.667-7.818-10.518-13.505-17.078-18.833
								C648.865,588.393,645.006,592.207,647.717,594.409L647.717,594.409z"/>
						</g>
						<g>
							<path d="M743.401,594.409c6.17,5.01,12.87,10.315,16.231,17.735c3.825,8.445,3.608,18.827,4.524,27.914
								c1.836,18.189,3.615,36.385,5.201,54.598c0.301,3.45,5.726,3.481,5.423,0c-1.736-19.937-3.704-39.853-5.724-59.763
								c-0.864-8.521-1.043-17.598-4.743-25.486c-3.667-7.818-10.518-13.505-17.078-18.833
								C744.55,588.393,740.69,592.207,743.401,594.409L743.401,594.409z"/>
						</g>
						<g>
							<path d="M646.432,589.601c-9.271,5.051-7.932,19.589-8.763,28.461c-1.299,13.869-2.704,27.729-3.981,41.6
								c-0.319,3.474,5.104,3.452,5.423,0c1.07-11.625,2.229-23.241,3.342-34.861c0.542-5.646,1.071-11.293,1.572-16.942
								c0.417-4.707,0.433-11.008,5.144-13.574C652.233,592.613,649.5,587.93,646.432,589.601L646.432,589.601z"/>
						</g>
						<g>
							<path d="M732.541,589.601c-9.271,5.051-7.931,19.589-8.762,28.461c-1.3,13.869-2.705,27.729-3.982,41.6
								c-0.319,3.474,5.105,3.452,5.423,0c1.07-11.625,2.229-23.241,3.343-34.861c0.541-5.646,1.07-11.293,1.571-16.942
								c0.417-4.707,0.434-11.008,5.145-13.574C738.344,592.613,735.609,587.93,732.541,589.601L732.541,589.601z"/>
						</g>
						<g>
							<path fill="#434556" d="M652.216,581.355l74.413,7.177c6.966-0.052,16.47,3.18,21.228,7.216l16.659,9.166
								c5.065,4.297,0.81,15.873-9.868,19.535c-13.688,4.694-32.881,5.46-55.812,1.353c-7.041-1.261-19.051-5.486-24.116-9.783
								l-23.064-19.567C644.248,591.647,645.25,581.406,652.216,581.355z"/>
							<path fill="#62667C" d="M655.197,580.806l70.976-0.519c6.966-0.052,16.47,3.18,21.228,7.216l15.045,12.763
								c5.065,4.297,2.424,12.276-8.254,15.938c-13.688,4.694-32.88,5.46-55.812,1.353c-7.041-1.261-19.051-5.486-24.116-9.783
								l-23.064-19.567C646.441,584.17,648.23,580.856,655.197,580.806z"/>
						</g>
					</g>
					<g>
						
							<linearGradient id="SVGID_78_" gradientUnits="userSpaceOnUse" x1="-227.3652" y1="149.6855" x2="-175.1782" y2="149.6855" gradientTransform="matrix(0.8013 -0.5982 0.5982 0.8013 827.1533 352.4903)">
							<stop  offset="0" style="stop-color:#6B73FF"/>
							<stop  offset="1" style="stop-color:#000DFF"/>
						</linearGradient>
						<path fill="url(#SVGID_78_)" d="M745.485,583.489c3.366-3.909,14.126-16.53,19.972-15.899
							c6.351,0.686,9.322,7.318,9.008,12.052c-0.375,5.64-2.646,10.188-5.423,17.246c-2.492,6.333-13.061,18.471-23.724,20.837
							C734.305,620.169,736.463,593.972,745.485,583.489z"/>
						<g>
							<g>
								<path fill="#FDB9B1" d="M738.15,447.696c0.155,7.616,6.835,1.189,6.972-1.34c0.138-2.529-0.692-4.461-2.494-4.539
									S738.098,445.163,738.15,447.696z"/>
								<path fill="#FED0CA" d="M706.533,442.263c1.144,7.606,2.781,11.932,7.252,20.865c0.015,0.03,0.86,0.908,0.904,0.923
									c0.737,0.405,4.851-0.121,5.366-0.424c13.028-7.487,12.859-9.598,13.582-14.393c0.17-1.151,0.398-2.449,0.863-4.021
									c1.623-5.444,2.67-14.784-0.546-19.174c-1.623-2.221-4.22-3.969-7.229-3.969c-1.328,0.118-7.658-0.724-13.058,2.752
									C708.268,428.29,705.205,433.358,706.533,442.263z"/>
								<path fill="#FDB9B1" d="M711.979,448.271c-0.156,7.616-6.835,1.19-6.973-1.339c-0.137-2.53,0.692-4.462,2.494-4.54
									C709.302,442.314,712.03,445.737,711.979,448.271z"/>
								<path fill="#FDB9B1" d="M740.963,475.488c-4.569-1.505-7.262-6.559-27.646,1.38c2.207-3.498,2.495-13.151,1.931-20.986
									c-0.313-4.352,1.886,2.554,9.146-0.585c4.495-1.943,12.996-3.365,12.487-0.026
									C735.113,466.889,738.31,470.608,740.963,475.488z"/>
							</g>
							<linearGradient id="SVGID_79_" gradientUnits="userSpaceOnUse" x1="709.7256" y1="473.8457" x2="746.0107" y2="473.8457">
								<stop  offset="0" style="stop-color:#6B73FF"/>
								<stop  offset="1" style="stop-color:#000DFF"/>
							</linearGradient>
							<path fill="url(#SVGID_79_)" d="M746.011,476.706l-32.96,0.578l-3.325,0.063c0.117-0.163,0.244-0.325,0.379-0.488
								c0.877-1.039,2.16-2.078,3.796-3.01c0.217-0.117,0.434-0.243,0.669-0.352c3.471-1.826,8.342-3.154,14.107-3.154
								c4.284,0,7.872,1.012,10.646,2.232c3.416,1.5,5.612,3.308,6.39,3.922C745.875,476.624,745.975,476.697,746.011,476.706z"/>
						</g>
						<path fill="#1E1E1E" d="M763.778,601.703c-0.28,0.76-0.605,1.519-0.976,2.26c-0.263,0.516-0.533,1.021-0.841,1.519
							c-0.163,0.271-0.325,0.542-0.515,0.813c-0.326,0.515-0.688,1.021-1.066,1.518c-0.605,0.769-1.266,1.527-1.988,2.251
							c-3.182,3.235-7.528,5.929-13.078,7.663c-2.656,0.832-9.435,1.482-16.267,1.771c-0.904,0.045-1.817,0.082-2.703,0.108
							c-5.422,0.163-10.528,0.1-13.23-0.289c-5.648-0.804-15.563-3.108-23.353-7.935c-1.5-0.931-3.715-2.594-6.381-4.745
							c-3.344-2.711-7.393-6.199-11.631-10.004c-5.323-4.79-10.944-10.068-15.834-14.931c-0.308-0.298-0.605-0.596-0.903-0.895
							c-5.567-5.576-10.077-10.511-11.894-13.357c-2.043-3.19-3.362-6.724-3.94-10.104c0-0.01,0-0.01,0-0.01
							c-0.687-3.85-0.397-7.491,0.858-10.176c-0.009-0.009-0.009-0.018,0.009-0.018c0.858-1.862,2.179-3.263,3.977-3.959
							c0.615-0.234,1.283-0.434,2.024-0.578c3.598-0.75,8.794-0.38,17.298,1.897c0.389,0.1,0.597,0.163,0.597,0.163
							c1.536,0.416,3.172,0.903,4.935,1.445c4.654,1.446,10.122,3.345,16.565,5.775c0.217,0.081,0.425,0.163,0.642,0.244
							c1.536,0.578,3.109,1.238,4.718,1.952c0.361,0.163,0.723,0.334,1.084,0.506c0.362,0.163,0.732,0.335,1.094,0.516
							c12.057,5.738,25.359,14.957,33.61,25.178c1.021,1.257,1.952,2.521,2.802,3.806c0.569,0.849,1.094,1.708,1.582,2.566
							c2.178-1.555,4.455-3.001,6.769-4.248c4.356-2.377,8.803-4.076,12.743-4.519c3.732-0.425,7.013,0.28,9.345,2.603
							c0.65,0.724,1.238,1.491,1.771,2.277c0.361,0.543,0.705,1.103,1.012,1.682v0.009
							C765.478,589.711,765.911,595.946,763.778,601.703z"/>
						
							<linearGradient id="SVGID_80_" gradientUnits="userSpaceOnUse" x1="1120.8652" y1="435.1924" x2="1120.8652" y2="513.1719" gradientTransform="matrix(-0.998 0.0637 0.0637 0.998 1856.3298 -20.4209)">
							<stop  offset="0" style="stop-color:#6B73FF"/>
							<stop  offset="1" style="stop-color:#000DFF"/>
						</linearGradient>
						<path fill="url(#SVGID_80_)" d="M778.565,500.783c5.378,17.157,4.008,27.036,4.087,40.582
							c0.031,5.439,1.029,21.231-9.726,21.593c-9.365,0.314-14.385-11.813-15.736-17.25c-2.602-10.455-4.19-21.264-4.729-31.903
							c-0.407-8.04,3.521-23.554,12.893-28.303C768.907,483.701,775.636,491.434,778.565,500.783z"/>
						<linearGradient id="SVGID_81_" gradientUnits="userSpaceOnUse" x1="727.6934" y1="600.2539" x2="727.6934" y2="475.4551">
							<stop  offset="0" style="stop-color:#6B73FF"/>
							<stop  offset="1" style="stop-color:#000DFF"/>
						</linearGradient>
						<path fill="url(#SVGID_81_)" d="M771.379,524.099c-0.85,6.227-3.858,11.134-6.706,19.178c-0.542,1.536-1.084,3.19-1.6,4.988
							c-0.135,0.47-0.271,0.949-0.397,1.437c-0.479,1.754-0.931,3.651-1.338,5.712c-0.343,1.699-0.659,3.498-0.948,5.432
							c-0.344,2.313-0.091,5.134,0.343,8.179c0.435,2.992,1.049,6.2,1.465,9.327c0.271,2.097,0.451,4.166,0.415,6.109
							c-0.036,3.082-0.614,5.856-2.178,7.998c-0.045,0.063-0.1,0.127-0.153,0.189c-0.85,1.112-1.997,2.079-3.38,2.91
							c-1.491,0.904-3.263,1.654-5.242,2.269c-0.009,0-0.018,0.009-0.018,0.009c-3.76,1.157-8.288,1.835-13.159,2.169
							c-2.9,0.199-5.929,0.271-9.001,0.244c-0.371,0-0.741,0-1.112-0.009c-18.652-0.28-38.192-4.004-38.563-4.962
							c-1.328-3.397-1.519-6.886-1.13-10.401c0.153-1.438,0.416-2.884,0.732-4.32c1.916-8.703,5.991-17.325,3.786-24.699
							c-0.054-0.19-0.126-0.443-0.217-0.75c-0.198-0.724-0.479-1.754-0.813-3.019c-0.108-0.407-0.226-0.841-0.343-1.302
							c-0.425-1.636-0.922-3.561-1.455-5.685c-3.037-12.11-7.393-30.736-7.998-38.771c-0.335-4.401-0.497-7.953-0.434-10.846
							c0.107-5.413,1.039-8.549,3.189-10.718c1.636-1.646,3.968-2.729,7.194-3.832c5.151-1.753,11.261-3.136,17.785-4.076
							c1.121-0.162,2.251-0.308,3.398-0.442c0.027,0,0.045,0,0.072-0.01c0.442-0.045,0.895-0.099,1.338-0.153
							c10.303-1.103,21.247-1.103,30.8,0.244c10.809,1.536,19.828,4.799,24.121,10.186c0.009,0.009,0.009,0.009,0.009,0.018
							C776.196,494.718,772.49,515.946,771.379,524.099z"/>
						<linearGradient id="SVGID_82_" gradientUnits="userSpaceOnUse" x1="680.9482" y1="484.7383" x2="680.9482" y2="571.501">
							<stop  offset="0" style="stop-color:#6B73FF"/>
							<stop  offset="1" style="stop-color:#000DFF"/>
						</linearGradient>
						<path fill="url(#SVGID_82_)" d="M691.867,552.485c0.009,0.687,0.018,1.392,0.018,2.105c0.01,6.959-0.704,14.487-4.753,16.431
							c-0.822,0.397-1.79,0.56-2.919,0.442c-0.624-0.063-1.238-0.226-1.835-0.47c-7.303-2.919-12.318-17.885-13.502-24.881
							c-0.145-0.921-0.235-1.708-0.253-2.304c-0.217-7.067,0.958-15.626,2.286-23.66c0.127-0.741,0.244-1.474,0.37-2.196
							c0-0.009,0-0.009,0-0.019c0.19-1.139,0.389-2.269,0.579-3.371c0.361-2.069,0.705-4.075,1.021-5.964
							c1.32-7.963,4.193-15.672,9.752-22.432c0.605-0.723,1.482-1.274,2.494-1.4c1.121-0.145,2.386,0.234,3.57,1.473
							c2.142,2.215,4.039,7.212,4.347,16.928c0.442,13.592,0.497,23.172-1.328,39.431c-0.046,0.415-0.072,0.939-0.072,1.563
							c-0.01,1.672,0.108,4.013,0.181,6.624c0.018,0.552,0.026,1.111,0.045,1.69V552.485z"/>
						<linearGradient id="SVGID_83_" gradientUnits="userSpaceOnUse" x1="725.4824" y1="464.1963" x2="725.4824" y2="408.1475">
							<stop  offset="0" style="stop-color:#1E1E1E"/>
							<stop  offset="1" style="stop-color:#63322B"/>
						</linearGradient>
						<path fill="url(#SVGID_83_)" d="M743.995,442.372c-0.65,2.757-1.645,5.685-3.019,8.767c-0.009,0.009-0.009,0.027-0.018,0.036
							c-1.121,2.521-3.091,8.884-4.564,10.483c-0.009,0-0.009,0-0.018,0.01c-1.763,0.515-4.13,4.058-15.97,1.762
							c-1.111-0.217-2.313-0.488-3.605-0.813c-0.524-0.731-0.958-1.355-1.329-1.916c-2.159-3.253-2.123-4.103-4.419-9.245
							c-0.506-1.139-1.139-2.494-1.925-4.13c-0.813-1.682-1.455-3.317-1.943-4.898c-0.451-1.5-0.768-2.946-0.949-4.329
							c-0.298-2.15-0.279-4.166-0.018-6.046c0.344-2.422,1.094-4.609,2.151-6.553c-0.679-1.003-1.067-2.15-1.067-3.442
							c0-9.824,13.349-13.909,22.485-13.909c-2.539,3.968,0.127,4.465,5.775,7.438c2.757,1.446,3.958,3.245,4.094,5.115
							c1.672,1.663,3.001,3.679,3.904,6.02c0.019,0.063,0.036,0.117,0.055,0.172h0.009l0.009,0.036
							C745.188,431.111,745.433,436.317,743.995,442.372z"/>
					</g>
					<g>
						<g>
							<path d="M681.504,596.137c-0.484,4.956-0.668,9.941-1.254,14.888c-0.129,1.082-0.088,4.154-0.963,5.061
								c-2.717,2.816-8.668-4.263-10.5-5.884c-2.604-2.307-6.453,1.516-3.834,3.834c4.484,3.971,15.28,14.017,19.475,3.751
								c2.622-6.417,1.833-14.838,2.499-21.649C687.266,592.666,681.841,592.689,681.504,596.137L681.504,596.137z"/>
						</g>
						<g>
							<path d="M771.778,596.137c-0.249,2.212-2.552,20.709-5.958,19.548c-3.311-1.128-4.731,4.107-1.441,5.229
								c10.884,3.71,12.115-18.478,12.821-24.776C777.589,592.675,772.163,592.702,771.778,596.137L771.778,596.137z"/>
						</g>
						<path fill="#62667C" d="M772.791,600.423c-22.417,14.307-72.829,12.767-89.983,0.486c-1.419-1.016-4.16-2.348-3.935-4.008
							l5.622-41.486c0.964-7.114,8.067-14.778,15.282-12.819c18.128,4.923,53.321,7.421,67.295-0.489
							c6.506-3.684,15.47,8.485,14.506,15.599l-5.721,39.637C775.632,599.003,774.263,599.483,772.791,600.423z"/>
						<path fill="#434556" d="M773.196,601.104c-21.384,13.309-71.558,12.842-87.922,1.419c-1.354-0.944-2.841-1.266-2.626-2.811
							l6.319-40.474c0.92-6.617,7.696-13.747,14.578-11.925c17.293,4.58,53.361,5.603,66.69-1.755
							c6.206-3.426,12.167,7.473,11.247,14.09l-5.363,38.59C775.906,599.783,774.6,600.23,773.196,601.104z"/>
					</g>
				</g>
				<path opacity="0.2" fill="#ECF2FA" d="M743.634,426.928c0,0-0.009-0.01-0.018-0.036c-0.452-0.796-8.314-14.361-19.269-12.31
					c-11.278,2.105-18.12,17.487-18.12,17.487l-0.009-0.018c0.344-2.422,1.094-4.609,2.151-6.553
					c-0.679-1.003-1.067-2.15-1.067-3.442c0-9.824,13.349-13.909,22.485-13.909c-2.539,3.968,0.127,4.465,5.775,7.438
					c2.757,1.446,3.958,3.245,4.094,5.115c1.672,1.663,3.001,3.679,3.904,6.02l0.063,0.172L743.634,426.928z"/>
				<path opacity="0.2" fill="#ECF2FA" d="M714.325,474.438c-0.009,0.009-0.009,0.018-0.009,0.027
					c-0.153,0.389-0.406,1.003-0.813,1.952c-0.055,0.145-0.117,0.289-0.189,0.451c-0.063,0.146-0.145,0.28-0.263,0.416
					c-2.783,3.362-20.714,3.705-24.355,8.956c-1.392,1.997-3.986,5.305-6.76,9.245c-4.05,5.739-8.478,12.807-10.077,19.078
					c0.361-2.069,0.705-4.075,1.021-5.964c1.32-7.963,4.193-15.672,9.752-22.432c0.605-0.723,1.482-1.274,2.494-1.4
					c1.636-1.646,3.968-2.729,7.194-3.832c5.151-1.753,11.261-3.136,17.785-4.076c0.877-1.039,2.16-2.078,3.796-3.01h0.578
					C714.479,473.85,714.678,473.516,714.325,474.438z"/>
				<path opacity="0.2" fill="#ECF2FA" d="M663.345,544.505c-1.591-0.397-6.154-1.328-10.998-0.253
					c-5.134,1.13-10.593,4.492-13.168,13.086c0,0,0,0,0,0.01c-1.103,3.678-1.672,8.323-1.464,14.152v0.072
					c-0.958-7.654-1.537-13.782-1.112-16.385c0.452-2.757,1.79-5.693,3.435-8.025c-0.009-0.009-0.009-0.018,0.009-0.018
					c0.858-1.862,2.179-3.263,3.977-3.959c0.678-0.389,1.338-0.597,1.935-0.587c0.027,0,0.063,0,0.09,0.009
					C649.645,541.857,654.841,542.228,663.345,544.505z"/>
			</g>
			<g>
				<g>
					<g>
						<path fill="#FDB9B1" d="M731.91,694.084c0.6-0.34,1.2-0.721,1.801-1.121c1.591-1.062,13.065-16.692,13.294-23.627
							c-0.04-5.229,0.161-10.401,0.286-15.674c0.271-11.398,0.471-23.034-0.011-34.475c-0.503-11.962,1.654-35.552,8.204-38.408
							c4.528-1.975,8.041-2.526,11.81,0.999c6.693,6.262,6.784,15.696,7.085,24.131c0.436,12.222-2.248,26.395-6.263,37.995
							c-2.08,6.012-6.107,22.619-4.664,31.576c0.592,3.657,1.599,7.013,2.29,10.804c1.201,6.58-4.701,4.863-9.563,6.924
							c-6.018,2.55-10.513,7.458-17.511,7.816c-4.112,0.212-10.021-2.583-14.321-4.788
							C726.918,695.353,729.47,695.469,731.91,694.084z"/>
						
							<linearGradient id="SVGID_84_" gradientUnits="userSpaceOnUse" x1="1096.3848" y1="623.6445" x2="1098.5386" y2="596.0314" gradientTransform="matrix(-0.997 -0.0778 -0.0778 0.997 1885.4786 167.778)">
							<stop  offset="0" style="stop-color:#B85D40"/>
							<stop  offset="1" style="stop-color:#EF7432"/>
						</linearGradient>
						<path fill="url(#SVGID_84_)" d="M731.592,693.909c0.689-0.008,1.519-0.546,2.119-0.946c1.613,1.451,2.618,1.991,5.148,0.592
							c2.467-1.364,4.917-3.847,7.06-5.535c5.206-4.101,10.844-9.975,18.358-8.413c0.496,2.177,1.045,4.375,1.465,6.679
							c1.201,6.579-4.701,4.862-9.563,6.923c-6.018,2.55-8.659,8.498-15.657,8.856c-4.112,0.211-16.666-2.312-18.696-6.459
							C723.17,692.517,728.528,693.944,731.592,693.909z"/>
						<linearGradient id="SVGID_85_" gradientUnits="userSpaceOnUse" x1="742.1396" y1="694.998" x2="765.6611" y2="694.998">
							<stop  offset="0" style="stop-color:#1E1E1E"/>
							<stop  offset="1" style="stop-color:#63322B"/>
						</linearGradient>
						<path fill="url(#SVGID_85_)" d="M759.869,688.551c-6.718,2.829-6.437,3.135-9.104,5.091c-2.52,1.848-5.862,6.84-8.626,8.23
							c1.592-0.302,1.776-0.31,6.849-1.793c6.604-1.931,1.25-5.008,12.591-9.183c1.17-0.431,4.05-1.076,4.05-1.076
							C766.145,687.303,760.393,688.247,759.869,688.551z"/>
						<linearGradient id="SVGID_86_" gradientUnits="userSpaceOnUse" x1="754.5684" y1="700.8477" x2="766.3489" y2="689.0671">
							<stop  offset="0" style="stop-color:#1E1E1E"/>
							<stop  offset="1" style="stop-color:#63322B"/>
						</linearGradient>
						<path fill="url(#SVGID_86_)" d="M765.797,688.526c-1.709-1.347-3.852-0.571-5.928,0.024c-1.232,0.354-4.195,1.358-4.679,1.875
							c3.051,3.873,1.908,11.039,1.77,12.813c3.323,1.878,3.674,0.993,4.667,0.349C762.726,698.216,766.352,688.63,765.797,688.526z
							"/>
					</g>
					<g>
						<path fill="#FED0CA" d="M724.431,704.064c0.532-0.438,1.059-0.916,1.582-1.414c1.384-1.319,10-18.69,9.032-25.562
							c-0.938-5.144-1.63-10.274-2.413-15.489c-1.692-11.274-3.497-22.772-5.938-33.959c-2.553-11.698-4.483-35.307,1.478-39.247
							c4.121-2.724,7.487-3.871,11.805-1.047c7.671,5.018,9.383,14.296,11.13,22.554c2.53,11.964,2.324,26.388,0.363,38.506
							c-1.015,6.28-2.127,23.333,0.836,31.908c1.211,3.501,2.779,6.633,4.113,10.249c2.314,6.275-3.795,5.599-8.229,8.465
							c-5.49,3.547-9.074,9.154-15.906,10.711c-4.015,0.915-10.316-0.821-14.932-2.254
							C719.731,706.172,722.265,705.849,724.431,704.064z"/>
						
							<linearGradient id="SVGID_87_" gradientUnits="userSpaceOnUse" x1="1143.5811" y1="600.1367" x2="1102.3225" y2="581.2828" gradientTransform="matrix(-0.9955 0.0948 0.0948 0.9955 1798.9838 6.0973)">
							<stop  offset="0" style="stop-color:#B85D40"/>
							<stop  offset="1" style="stop-color:#EF7432"/>
						</linearGradient>
						<path fill="url(#SVGID_87_)" d="M724.087,703.946c0.678-0.126,1.402-0.798,1.926-1.296c1.839,1.151,2.921,1.511,5.173-0.303
							c2.195-1.768,4.182-4.635,6.003-6.666c4.423-4.935,8.966-11.691,16.638-11.444c0.863,2.06,1.781,4.13,2.592,6.326
							c2.314,6.275-3.795,5.599-8.229,8.465c-5.49,3.547-7.069,9.86-13.901,11.417c-4.016,0.915-16.815,0.588-19.528-3.148
							C715.552,704.023,721.075,704.508,724.087,703.946z"/>
						<path fill="#F8DEBB" d="M751.022,693.807c-6.132,3.941-5.802,4.194-8.094,6.58c-2.164,2.253-4.599,7.745-7.082,9.591
							c1.517-0.571,1.696-0.611,6.438-2.944c6.174-3.037,0.37-5.148,10.824-11.211c1.078-0.625,3.805-1.756,3.805-1.756
							C756.988,691.497,751.484,693.416,751.022,693.807z"/>
						<linearGradient id="SVGID_88_" gradientUnits="userSpaceOnUse" x1="747.2188" y1="700.9336" x2="756.9609" y2="700.9336">
							<stop  offset="0" style="stop-color:#1E1E1E"/>
							<stop  offset="1" style="stop-color:#63322B"/>
						</linearGradient>
						<path fill="url(#SVGID_88_)" d="M756.857,692.763c-1.915-1.032-3.893,0.1-5.835,1.044c-1.154,0.561-3.416,1.959-3.804,2.552
							c3.315,2.275,3.725,10.794,3.894,12.564c3.596,1.279,3.359,0.199,4.228-0.605C755.497,702.836,757.421,692.77,756.857,692.763
							z"/>
					</g>
					<g>
						<g>
							<path d="M691.005,639.119c6.706,5.444,13.892,11.163,17.857,19.032c4.777,9.479,4.519,21.343,5.564,31.696
								c2.108,20.873,4.155,41.753,5.975,62.654c0.3,3.449,5.726,3.481,5.422,0c-1.97-22.634-4.206-45.243-6.5-67.847
								c-0.996-9.822-1.217-20.26-5.778-29.24c-4.208-8.285-11.628-14.385-18.705-20.13
								C692.153,633.104,688.294,636.918,691.005,639.119L691.005,639.119z"/>
						</g>
						<g>
							<path d="M798.991,639.119c6.706,5.444,13.892,11.163,17.857,19.032c4.777,9.479,4.519,21.343,5.564,31.696
								c2.108,20.873,4.155,41.753,5.975,62.654c0.3,3.449,5.726,3.481,5.422,0c-1.97-22.634-4.206-45.243-6.5-67.847
								c-0.997-9.822-1.218-20.26-5.778-29.24c-4.208-8.285-11.628-14.385-18.706-20.13
								C800.139,633.104,796.28,636.918,798.991,639.119L798.991,639.119z"/>
						</g>
						<g>
							<path d="M689.483,634.241c-4.58,2.436-6.727,6.981-7.686,11.906c-1.274,6.542-1.42,13.394-2.04,20.019
								c-1.464,15.616-3.047,31.222-4.484,46.841c-0.319,3.474,5.105,3.452,5.423,0c1.23-13.365,2.564-26.721,3.843-40.081
								c0.624-6.521,1.226-13.042,1.808-19.566c0.458-5.135,0.75-11.711,5.874-14.437
								C695.303,637.283,692.566,632.601,689.483,634.241L689.483,634.241z"/>
						</g>
						<g>
							<path d="M786.664,634.241c-4.58,2.436-6.727,6.981-7.686,11.906c-1.274,6.542-1.42,13.394-2.041,20.019
								c-1.463,15.616-3.046,31.222-4.483,46.841c-0.319,3.474,5.105,3.452,5.423,0c1.229-13.365,2.564-26.721,3.843-40.081
								c0.624-6.521,1.226-13.042,1.808-19.566c0.458-5.135,0.75-11.711,5.873-14.437
								C792.482,637.283,789.746,632.601,786.664,634.241L786.664,634.241z"/>
						</g>
						<g>
							<path fill="#434556" d="M695.836,624.634l83.979,8.1c7.861-0.058,18.588,3.589,23.957,8.144l18.801,10.345
								c5.716,4.85,0.913,17.913-11.137,22.046c-15.448,5.299-37.108,6.162-62.987,1.527c-7.946-1.424-21.5-6.191-27.217-11.041
								l-26.03-22.083C686.843,636.249,687.975,624.691,695.836,624.634z"/>
							<path fill="#62667C" d="M699.199,624.014l80.102-0.586c7.861-0.058,18.588,3.589,23.957,8.145l16.979,14.403
								c5.716,4.85,2.734,13.854-9.315,17.987c-15.448,5.298-37.107,6.162-62.987,1.526c-7.945-1.423-21.5-6.191-27.216-11.041
								l-26.03-22.082C689.318,627.811,691.338,624.071,699.199,624.014z"/>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path fill="#FDB9B1" d="M801.115,488.421c-0.489,8.369,7.131,1.873,7.494-0.891c0.362-2.764-0.35-4.953-2.249-5.188
									C804.46,482.108,801.278,485.638,801.115,488.421z"/>
								<path fill="#FED0CA" d="M800.868,483.674c-0.397,1.582-0.858,3.055-1.328,4.32c-0.136,0.361-0.263,0.704-0.371,1.039
									c-0.207,0.623-0.37,1.202-0.523,1.753c-0.1,0.397-0.19,0.777-0.28,1.139c-0.027,0.145-0.063,0.271-0.091,0.407
									c-0.198,0.867-0.316,1.906-0.461,3.019c0,0,0.009,0,0,0.009c-0.262,2.187-0.605,4.672-1.753,6.787
									c-1.302,2.413-3.651,4.365-8.116,4.862c-1.5,0.172-3.244,0.181-5.277-0.019c-1.274-0.126-2.468-0.695-3.579-1.6
									c0-0.009,0-0.009,0-0.009h-0.009c-0.036-0.036-0.072-0.063-0.108-0.1c-5.143-4.347-8.323-15.933-8.775-22.847
									c-0.027-0.425-0.045-0.831-0.055-1.238c-0.045-1.5,0.019-2.883,0.172-4.166c0.552-4.482,2.286-7.736,4.881-10.212
									c1.184-1.121,2.539-2.088,4.049-2.929c6.01-3.362,12.643-1.916,14.053-1.934c3.19,0.244,5.784,2.386,7.312,4.952
									C802.975,470.886,802.314,477.98,800.868,483.674z"/>
								<path fill="#FDB9B1" d="M778.047,487.754c-0.819,8.343-7.335,0.738-7.263-2.048s1.116-4.838,3.029-4.773
									S778.32,484.979,778.047,487.754z"/>
								<path fill="#FDB9B1" d="M803.797,516.679c-0.949-0.406-1.799-0.967-2.738-1.546c-3.398-2.087-7.953-4.383-22.143-0.551
									c-1.4,0.37-2.892,0.805-4.491,1.311c0.659-0.913,1.355-1.935,2.006-3.154c0.732-1.364,1.429-2.982,1.979-5.007
									c0.153-0.569,0.299-1.166,0.425-1.798c0-0.01,0.009-0.01,0.009-0.019c0.036-0.172,0.072-0.353,0.108-0.533
									c0.01-0.036,0.01-0.072,0.019-0.1c0.344-1.816,0.578-3.895,0.669-6.326c0.045-1.156,0.054-2.395,0.026-3.724
									c-0.009-0.325-0.018-0.65-0.026-0.984c-0.01-0.235-0.019-0.479-0.027-0.724c-0.136-3.208-0.263-2.72,2.296-1.762
									c0.939,0.353,2.241,0.768,4.03,1.084c0.624,0.117,1.311,0.217,2.061,0.299c1.355,0.145,2.829-0.1,4.248-0.488
									c1.771-0.479,3.461-1.185,4.744-1.573c0.687-0.217,1.256-0.334,1.654-0.298c0.542,0.055,0.776,0.416,0.578,1.274
									c-0.081,0.37-0.163,0.732-0.226,1.094c-0.118,0.542-0.208,1.084-0.289,1.608c-0.019,0.163-0.046,0.325-0.063,0.479
									c-0.316,2.367-0.344,4.5-0.172,6.435c0.117,1.247,0.316,2.422,0.569,3.516c0.723,3.055,1.897,5.576,3.01,7.835
									c0.47,0.958,0.931,1.862,1.337,2.738C803.534,516.073,803.67,516.381,803.797,516.679z"/>
								<linearGradient id="SVGID_89_" gradientUnits="userSpaceOnUse" x1="790.2637" y1="497.6904" x2="790.2637" y2="450.7412">
									<stop  offset="0" style="stop-color:#B85D40"/>
									<stop  offset="1" style="stop-color:#EF7432"/>
								</linearGradient>
								<path fill="url(#SVGID_89_)" d="M808.532,484.641c-0.389,0.877-0.831,1.627-1.311,2.232
									c-0.597,0.75-1.816,2.124-3.389,3.643c-0.561,0.542-1.175,1.102-1.816,1.662c-0.868,0.75-1.799,1.51-2.757,2.196
									c-0.181,0.136-0.371,0.263-0.551,0.389c-0.29,0.208-0.588,0.397-0.877,0.588c-0.01,0-0.019,0.009-0.019,0.009
									c-0.533,0.334-1.066,0.65-1.6,0.931c-0.406,0.208-0.813,0.407-1.22,0.569c-1.229,0.524-2.431,0.831-3.561,0.831
									c-2.269-0.315-4.176-0.668-5.784-1.057c-2.485-0.597-4.284-1.302-5.657-2.151c-0.118-0.081-0.235-0.153-0.353-0.234
									c-1.247-0.841-2.133-1.835-2.901-3.019c-1.338-2.034-2.322-4.654-4.212-8.089c-0.226-0.416-0.442-0.841-0.642-1.266
									c-0.487-1.057-0.876-2.133-1.184-3.208v-0.009c-0.153-0.543-0.28-1.085-0.389-1.627c-1.617-8.143,1.546-16.403,5.802-20.461
									c3.146-3.471,7.492-6.254,12.996-5.775c6.046,0.524,10.267,2.088,13.34,4.221c1.058,0.741,1.979,1.537,2.792,2.386
									C812.057,464.496,811.542,478.034,808.532,484.641z"/>
								<linearGradient id="SVGID_90_" gradientUnits="userSpaceOnUse" x1="793.7148" y1="508.6563" x2="793.7148" y2="479.1162">
									<stop  offset="0" style="stop-color:#B85D40"/>
									<stop  offset="1" style="stop-color:#EF7432"/>
								</linearGradient>
								<path fill="url(#SVGID_90_)" d="M789.112,484.584c-3.353-1.513-9.418,2.188-6.919,7.964
									c-6.267,2.598-0.746,14.202,4.274,12.177c0.735,4.677,12.077,5.673,11.581,0.28c6.067,1.657,10.221-5.585,7.428-10.468
									c2.624-2.428,3.271-4.848,1.745-7.66c-1.939-3.574-5.048-4.545-6.492-3.073C801.704,479.664,788.59,475.402,789.112,484.584
									z"/>
								<linearGradient id="SVGID_91_" gradientUnits="userSpaceOnUse" x1="790.3672" y1="487.1357" x2="798.7173" y2="501.5985">
									<stop  offset="0" style="stop-color:#B85D40"/>
									<stop  offset="1" style="stop-color:#EF7432"/>
								</linearGradient>
								<path fill="url(#SVGID_91_)" d="M805.393,494.372c-0.48-0.639-0.353-2.403-1.096-3.357c-0.959-1.23-3.956-3.16-5.441-1.757
									c-1.762-1.609-4.172-3.816-6.363-3.171c-1.488,0.438-5.375,2.63-3.38,6.099c-3.621-0.954-5.377,3.006-2.763,5.235
									c-1.626,4.115,2.702,5.491,5.479,3.222c1.018,2.086,5.789,1.722,7.311,0.705c2.083-1.393,2.861-5.521-0.229-7.194
									c0.691-2.459-2.156-3.318-3.528-3.442c-1.484-0.134-3.81,1.635-3.469,3.7c0.307,1.861,4.174,3.842,4.57,1.112
									L805.393,494.372z"/>
							</g>
							<linearGradient id="SVGID_92_" gradientUnits="userSpaceOnUse" x1="769.96" y1="513.9268" x2="809.0742" y2="513.9268">
								<stop  offset="0" style="stop-color:#B7D2F7"/>
								<stop  offset="1" style="stop-color:#D5F9FF"/>
							</linearGradient>
							<path fill="url(#SVGID_92_)" d="M809.074,517.935h-0.234l-35.807-0.479l-3.073-0.036c1.247-1.536,3.507-3.271,6.471-4.682
								c0.642-0.308,1.311-0.588,2.007-0.858c3.615-1.392,8.025-2.25,12.806-1.871c4.393,0.344,8.008,1.6,10.81,3.019
								C806.463,515.251,808.857,517.872,809.074,517.935z"/>
						</g>
						
							<linearGradient id="SVGID_93_" gradientUnits="userSpaceOnUse" x1="1031.7207" y1="623.793" x2="1150.6191" y2="623.793" gradientTransform="matrix(-1 0 0 1 1851.1836 0)">
							<stop  offset="0" style="stop-color:#1E1E1E"/>
							<stop  offset="1" style="stop-color:#63322B"/>
						</linearGradient>
						<path fill="url(#SVGID_93_)" d="M735.088,581.623c5.36,3.707,10.582-15.765,31.709,0.34
							c14.395,10.973,40.587,40.325,47.055,47c10.739,12.49,6.308,33.93-15.407,40.97c-5.457,1.77-27.262,2.778-34.182,1.755
							c-5.997-0.887-16.52-3.424-24.787-8.733c-5.043-3.239-13.566-13.834-22.664-26.311c-7.524-10.319-16.818-3.542-16.22-36.972
							C700.809,587.538,718.884,570.419,735.088,581.623z"/>
						
							<linearGradient id="SVGID_94_" gradientUnits="userSpaceOnUse" x1="1031.7881" y1="543.876" x2="1031.7881" y2="629.7432" gradientTransform="matrix(-0.9999 -0.0145 -0.0145 0.9999 1857.1432 -4.4044)">
							<stop  offset="0" style="stop-color:#B7D2F7"/>
							<stop  offset="1" style="stop-color:#D5F9FF"/>
						</linearGradient>
						<path fill="url(#SVGID_94_)" d="M831,542.613c2.694,20.351-0.871,32.191-1.952,47.06c-0.435,5.97,2.051,21.182-9.361,20.687
							c-9.938-0.432-14.206-14.154-15.17-20.23c-1.854-11.687-2.605-23.678-2.262-35.395c0.26-8.855,3.14-25.653,13.466-30.086
							C819.636,522.968,829.589,531.957,831,542.613z"/>
						<linearGradient id="SVGID_95_" gradientUnits="userSpaceOnUse" x1="783.3594" y1="514.0762" x2="783.3594" y2="650.459">
							<stop  offset="0" style="stop-color:#A3BEE3"/>
							<stop  offset="1" style="stop-color:#C1E5F4"/>
						</linearGradient>
						<path fill="url(#SVGID_95_)" d="M819.034,582.96c-0.353,1.78-0.715,3.452-1.085,5.007c-0.822,3.452-1.689,6.271-2.539,8.17
							c-1.248,2.774-1.185,7.429-0.705,12.733c0.705,7.845,2.286,17.126,1.816,23.922c-0.199,2.956-0.795,5.432-2.016,7.131
							c-0.181,0.244-0.361,0.488-0.551,0.724c-1.112,1.392-2.423,2.62-3.896,3.687c-0.145,0.108-0.289,0.217-0.442,0.316
							c-7.104,4.898-17.759,6.335-28.514,5.648c-4.854-0.307-9.716-1.048-14.288-2.097c-5.495-1.256-10.565-2.964-14.641-4.898
							c-0.507-0.234-1.004-0.487-1.482-0.731c-1.238-0.642-2.286-1.311-3.154-2.007c-3.922-3.136-4.284-6.814-3.019-11.242
							c0.506-1.763,1.266-3.643,2.16-5.657c0.226-0.497,0.461-1.013,0.705-1.528c1.319-2.883,2.847-6.019,4.247-9.453
							c0.669-1.608,1.302-3.28,1.871-5.024c1.925-5.865,3.172-12.499,2.449-20.036c-0.027-0.316-0.045-0.597-0.055-0.85
							c-0.072-1.744,0.316-2.178,0.435-4.248c0.036-0.56,0.054-1.247,0.026-2.114c-0.026-1.094-0.099-2.467-0.262-4.257
							c-0.226-2.494-0.605-5.784-1.229-10.203c-1.392-9.869-2.973-19.937-2.386-28.297c0.452-6.461,2.196-11.911,6.317-15.48
							c1.681-1.455,3.769-2.604,6.317-3.371c1.663-0.497,3.28-0.967,4.844-1.383c3.091-0.832,6.01-1.51,8.775-2.024
							c0.009,0,0.019-0.009,0.019-0.009c8.703-1.646,15.915-1.754,22.305-0.254c0.786,0.182,1.563,0.398,2.331,0.633
							c1.88,0.569,3.688,1.293,5.45,2.169c3.516,1.735,6.841,4.076,10.112,7.022l0.027,0.027
							C825.197,530.687,822.947,563.032,819.034,582.96z"/>
						<linearGradient id="SVGID_96_" gradientUnits="userSpaceOnUse" x1="750.9219" y1="522.1553" x2="750.9219" y2="610.626">
							<stop  offset="0" style="stop-color:#B7D2F7"/>
							<stop  offset="1" style="stop-color:#D5F9FF"/>
						</linearGradient>
						<path fill="url(#SVGID_96_)" d="M763.725,540.113c-0.28,12.742-0.741,21.689-3.57,36.674c-0.045,0.235-0.09,0.542-0.135,0.895
							c-0.063,0.47-0.127,1.03-0.19,1.672c-0.117,1.058-0.244,2.341-0.389,3.769c-0.136,1.319-0.298,2.774-0.488,4.293
							c-0.867,7.122-2.386,15.789-5.449,20.244c-0.705,1.03-1.491,1.835-2.377,2.341c-1.103,0.65-2.368,0.822-3.796,0.38
							c-0.009,0-0.027-0.01-0.036-0.01c-5.53-1.726-7.854-8.151-8.73-15.183c-0.37-2.946-0.487-5.992-0.47-8.848
							c0.01-0.705,0.019-1.392,0.036-2.069c0.019-0.913,0.055-1.799,0.091-2.64c0.162-3.425,0.415-6.172,0.442-7.492
							c0.127-5.485,0.822-10.898,1.898-16.249c0.876-4.365,2.015-8.685,3.308-12.978c2.964-9.842,4.88-16.792,12.933-21.943
							c0.642-0.416,1.319-0.723,1.997-0.795c1.563-0.181,3.1,0.895,4.04,4.826C763.498,529.765,763.859,533.949,763.725,540.113z"/>
					</g>
					<g>
						<g>
							<path d="M729.111,641.316c-0.56,5.694-0.76,11.423-1.452,17.104c-0.244,2.006-0.136,5.108-2.084,6.102
								c-3.489,1.779-8.997-4.952-11.411-7.088c-2.605-2.305-6.454,1.517-3.834,3.835c4.864,4.302,16.993,15.686,21.458,4.213
								c2.823-7.255,1.994-16.512,2.745-24.166C734.874,637.846,729.449,637.869,729.111,641.316L729.111,641.316z"/>
						</g>
						<g>
							<path d="M830.991,641.316c-0.266,2.38-2.805,23.883-7.165,22.396c-3.311-1.128-4.731,4.107-1.441,5.229
								c5.06,1.725,8.335-2.231,9.805-6.604c2.274-6.765,3.436-13.949,4.225-21.021C836.8,637.854,831.374,637.881,830.991,641.316
								L830.991,641.316z"/>
						</g>
						<path fill="#62667C" d="M831.786,646.152c-25.299,16.147-82.192,14.409-101.553,0.55c-1.602-1.146-4.694-2.649-4.44-4.523
							l6.345-46.82c1.088-8.028,9.104-16.679,17.247-14.467c20.459,5.556,60.178,8.375,75.947-0.553
							c7.342-4.156,17.459,9.576,16.371,17.604l-6.457,44.732C834.992,644.551,833.446,645.093,831.786,646.152z"/>
						<path fill="#434556" d="M832.243,646.921c-24.134,15.02-80.758,14.493-99.226,1.602c-1.527-1.066-3.205-1.428-2.963-3.172
							l7.132-45.678c1.038-7.468,8.685-15.514,16.452-13.457c19.517,5.168,60.221,6.323,75.264-1.981
							c7.005-3.866,13.731,8.434,12.694,15.901l-6.054,43.552C835.301,645.431,833.827,645.936,832.243,646.921z"/>
					</g>
				</g>
				<path opacity="0.2" fill="#ECF2FA" d="M802.45,455.016l-0.28,0.443c0,0-17.731-3.163-26.977,11.36
					c-1.962,3.081-3.543,6.958-4.492,11.839v0.009c-0.181,0.939-0.37,1.78-0.561,2.53c-0.045-1.5,0.019-2.883,0.172-4.166
					c-1.617-8.143,1.546-16.403,5.802-20.461c3.146-3.471,7.492-6.254,12.996-5.775C795.156,451.319,799.377,452.883,802.45,455.016
					z"/>
				<path opacity="0.4" fill="#ECF2FA" d="M777.163,515.677c0,0-1.546,0.596-4.13,1.779c-3.877,1.798-8.931,4.116-10.358,8.698
					c0,0-5.873-3.186-22.113,31.735c0.876-4.365,2.015-8.685,3.308-12.978c2.964-9.842,4.88-16.792,12.933-21.943
					c0.642-0.416,1.319-0.723,1.997-0.795c1.681-1.455,3.769-2.604,6.317-3.371c1.663-0.497,3.28-0.967,4.844-1.383
					c1.247-1.536,3.507-3.271,6.471-4.682c0.732-1.364,1.429-2.982,1.979-5.007C778.266,508.744,777.1,515.433,777.163,515.677z"/>
			</g>
		</g>
	</g>
	<g>
		<linearGradient id="SVGID_97_" gradientUnits="userSpaceOnUse" x1="487.7876" y1="556.4209" x2="595.8159" y2="556.4209">
			<stop  offset="0" style="stop-color:#A3BEE3"/>
			<stop  offset="1" style="stop-color:#C1E5F4"/>
		</linearGradient>
		<ellipse opacity="0.6" fill="url(#SVGID_97_)" cx="541.802" cy="556.421" rx="54.014" ry="9.427"/>
		<g>
			<linearGradient id="SVGID_98_" gradientUnits="userSpaceOnUse" x1="508.9224" y1="561.7988" x2="508.9224" y2="541.4492">
				<stop  offset="0" style="stop-color:#1E1E1E"/>
				<stop  offset="0.1001" style="stop-color:#2E2321"/>
				<stop  offset="0.2914" style="stop-color:#452925"/>
				<stop  offset="0.4955" style="stop-color:#562E29"/>
				<stop  offset="0.7193" style="stop-color:#60312A"/>
				<stop  offset="1" style="stop-color:#63322B"/>
			</linearGradient>
			<path fill="url(#SVGID_98_)" d="M518.563,559.29c1.324-4.771-2.466-10.185-3.101-12.341c-0.405-1.374-0.721-2.712-0.972-4.022
				c-3.768-0.373-8.075-0.861-12.376-1.478c0.032,1.456,0.044,2.909,0.005,4.347c-0.123,4.566-3.522,9.092-3.074,13.032
				C505.295,562.628,512.254,562.782,518.563,559.29z"/>
			<path fill="#1E1E1E" d="M499.003,558.678c0.08,0.703,0.268,1.391,0.633,2.054c0,0,3.783,2.821,9.359,2.986
				c2.637,0.078,6.681-0.334,9.126-3.29c0.211-0.421,0.358-0.852,0.479-1.286C512.267,562.647,505.28,562.492,499.003,558.678z"/>
			<linearGradient id="SVGID_99_" gradientUnits="userSpaceOnUse" x1="566.1724" y1="558.2383" x2="575.402" y2="542.2521">
				<stop  offset="0" style="stop-color:#1E1E1E"/>
				<stop  offset="0.286" style="stop-color:#362522"/>
				<stop  offset="0.747" style="stop-color:#572E29"/>
				<stop  offset="1" style="stop-color:#63322B"/>
			</linearGradient>
			<path fill="url(#SVGID_99_)" d="M584.676,550.244c-13.117-4.326-14.51-10.885-14.751-11.154
				c-6.038,3.021-9.068,3.648-15.712,2.441c0.027,0.855,0.037,1.72,0.017,2.599c-0.042,1.882-0.658,4.592-0.099,7.156
				c5.458,1.242,10.779,1.7,16.345,4.298c2,0.934,4.643,0.639,6.83,0.648c4.203,0.019,8.155-0.624,12.67-1.799
				C590.33,552.413,589.134,551.715,584.676,550.244z"/>
			<path d="M553.965,550.244c0.013,1.608,0.441,3.198,0.605,4.367c1.457,0.527,6.766,1.001,8.831,0.712
				c0.15-0.227-0.229-1.618,0.112-1.638c3.403-0.187,4.615,2.814,8.331,3.125c7.332,0.614,21.094,0.854,18.13-2.377
				c-0.127-0.138-0.107,0.114,0,0c-7.369,1.664-10.858,1.385-18.01,0.802c-1.855-0.15-6.12-2.515-8.028-3.042
				C562.127,551.693,557.022,552.59,553.965,550.244z"/>
			<g>
				<path fill="#FED0CA" d="M611.439,324.917c-0.655-2.051,0.359-4.499,0.755-5.17c1.539-2.609,1.932-1.951,3.227-5.795
					c1.998-5.928,3.382-4.125,3.097-1.459c-0.146,1.355-0.612,3.486-1.458,5.87c2.459,0.44,8.189-5.243,13.259-7.414
					c2.882-1.234,1.914,1.255,0.276,2.186c-1.798,1.021-3.972,2.539-5.568,4.028c1.488-0.104,9.526-5.929,10.622-3.671
					c0.718,1.48-7.774,5.02-8.867,6.623c1.166-0.303,7.881-5.804,8.628-4.264c0.649,1.339-5.616,5.529-7.813,7.286
					c2.077-0.678,7.346-4.825,7.588-4.037c0.404,1.316-2.211,3.234-3.306,3.938c-4.515,2.9-10.522,7.287-12.193,7.441
					c-2.108,0.195-2.757,2.603-4.325,3.925c-2.583,2.179-7.393-2.645-7.219-5.679C608.207,327.571,611.462,325.201,611.439,324.917z
					"/>
				<path fill="#DFEAF7" d="M611.057,324.479c2.218,1.888,5.875,6.321,6.713,7.273c-1.255,1.334-1.486,2.301-3.521,3.199
					c-1.846,0.813-5.772-5.578-6.649-7.329C608.092,327.294,610.185,324.893,611.057,324.479z"/>
				
					<linearGradient id="SVGID_100_" gradientUnits="userSpaceOnUse" x1="789.4307" y1="343.8965" x2="845.4717" y2="343.8965" gradientTransform="matrix(0.9999 -0.0102 0.0102 0.9999 -231.0733 5.755)">
					<stop  offset="0" style="stop-color:#6B73FF"/>
					<stop  offset="1" style="stop-color:#000DFF"/>
				</linearGradient>
				<path fill="url(#SVGID_100_)" d="M570.511,326.905c3.671,5.199,9.807,16.133,15.34,18.182
					c7.704-6.735,16.582-14.024,22.435-19.756c4.985,4.341,5.516,4.537,9.483,10.533c-7.167,7.87-7.197,9.521-14.923,17.395
					c-4.985,5.08-15.603,14.142-21.416,10.542c-8.455-5.236-13.82-9.422-18.595-14.1c2.51-11.847,2.46-23.845-1.287-31.425
					C562.425,318.573,566.276,320.908,570.511,326.905z"/>
			</g>
			<path fill="#C5D9F1" d="M500.539,327.976c0.231,10.101,3.328,19.681,6.567,29.183c9.611,28.173,5.904,25.109,8.191,35.723
				c0,0,0.587,2.666,0.64,4.008c0.027,0.569-0.011,1.023-0.153,1.307c10.981,4.742,34.824,2.925,41.929,0.109
				c3.872-28.084,2.485-22.806,3.511-34.58c0.087-1.006,0.191-2.111,0.323-3.352c0.024-0.236,1.185-10.462,1.29-10.674
				c0.119-0.951,1.139-27.906-0.071-30.293c-0.005-0.005-0.005-0.017-0.011-0.022c-0.558-1.104-3.39-2.827-6.66-4.347
				c-0.826-0.826-8.566-3.104-9.684-3.423c-0.021,0-0.138-0.038-0.159-0.044h-0.005c-11.927-3.3-15.282-4.481-26.651-1.011
				C510.403,313.355,500.343,319.655,500.539,327.976z"/>
			<path fill="#FDB9B1" d="M544.445,309.609c0.683,11.965-20.091,5.597-19.277,1.345c0.629-3.284,1.726-14.524,0.944-17.826
				c2.269,0.74,6.409,6.214,8.766,6.7c1.86,0.384,4.656,0.321,6.32,1.103c1.298,0.61,2.227-0.754,2.542,1.32
				C544.027,304.135,544.345,307.852,544.445,309.609z"/>
			<g>
				<path fill="#FED0CA" d="M546.726,281.937c1.355,5.285,2.626,17.191-0.115,21.501c-1.126,1.771-5.159,2.111-6.532,1.79
					c-2.746-0.643-7.797-1.113-10.223-3.759c-1.989-2.169-3.371-6.32-4.528-8.271c-2.042-3.444-3.089-7.549-1.548-11.063
					c0.779-1.777,4.676-7.843,6.8-8.335C536.907,272.333,545.08,275.521,546.726,281.937z"/>
				
					<ellipse transform="matrix(0.9942 0.1078 -0.1078 0.9942 34.3781 -57.325)" fill="#FED0CA" cx="547.199" cy="289.187" rx="2.619" ry="3.573"/>
				<path fill="#FDB9B1" d="M547.087,284.755c-0.778-1.382-1.078-3.736-2.682-4.982c-3.201-2.488-8.507-0.421-11.535,1.461
					c-0.743,1.902-0.241,3.302-3.136,5.708c-0.248,0.206-2.421,4.464-2.768,4.435c-1.24-0.107-1.574-3.202-1.507-3.965
					c0.558-6.322,4.987-12.212,11.401-13.542c2.179-0.452,5.047-0.296,6.609,1.482c1.033,1.176,1.96,3.025,2.474,4.504
					c0.332,0.954,0.634,1.964,0.861,2.948C546.918,283.292,546.973,283.738,547.087,284.755z"/>
				<linearGradient id="SVGID_101_" gradientUnits="userSpaceOnUse" x1="539.1831" y1="278.9287" x2="548.6489" y2="278.9287">
					<stop  offset="0" style="stop-color:#1E1E1E"/>
					<stop  offset="1" style="stop-color:#63322B"/>
				</linearGradient>
				<path fill="url(#SVGID_101_)" d="M543.548,271.836c8.349,1.454,3.961,12.535,3.854,14.344c-0.753-1.814,0.095-7.241-5.078-9.762
					C537.64,274.134,538.315,270.925,543.548,271.836z"/>
			</g>
			<linearGradient id="SVGID_102_" gradientUnits="userSpaceOnUse" x1="517.478" y1="279.7881" x2="544.498" y2="279.7881">
				<stop  offset="0" style="stop-color:#1E1E1E"/>
				<stop  offset="1" style="stop-color:#63322B"/>
			</linearGradient>
			<path fill="url(#SVGID_102_)" d="M543.401,277.039c0,0-6.231,0.59-13.08,3.51c-0.067,2.169-0.466,4.709-1.132,5.356
				c-1.246,1.218-2.788,6.84-2.92,8.724c-5.613-3.605-8.295-9.447-8.295-9.447s-2.208-5.127,2.702-11.387
				c1.341-1.712,3.206-3.5,5.793-5.299c7.211-5.004,12.985-4.167,16.068-0.866c1.284,1.37,1.798,2.883,1.931,4.271
				C544.714,274.688,543.401,277.039,543.401,277.039z"/>
			<path d="M534.944,423.725c0.738,3.953,1.607,8,2.515,11.909c2.302,9.843,4.899,18.843,6.496,23.513
				c2.86,8.35,3.527,10.668,3.292,15.666c-0.005,0.137-0.011,0.278-0.021,0.421c-0.077,1.443-0.225,3.117-0.41,5.211
				c-1.214,13.659,1.859,29.276,3.91,40.639c0.492,2.75-0.973,3.663-0.459,6.195c0.672,3.28,2.286,5.987,2.865,8.853
				c0.268,1.346-0.722,3.117-0.498,4.353c0.263,1.405,1.279,1.832,1.465,3.09c0.443,0.142,0.897,0.246,1.356,0.322
				c5.047,0.826,11.166-2.023,15.994-3.593h0.005c-0.06-0.711-0.382-1.323-0.727-1.946c0.005-0.005,0-0.005,0-0.005
				c-0.087-0.164-0.175-0.323-0.262-0.487c-0.307-0.585-0.574-1.203-0.613-1.93c-0.077-1.493,1.154-3.593,1.094-5.195
				c-0.055-1.449-1.411-2.663-1.46-4.188c-0.038-1.164,1.225-2.624,1.181-3.827c-0.367-10.794-0.88-22.944-2.822-33.974
				c-0.76-4.313-0.388-11.586-0.394-17.656c0-2.537-0.071-4.871-0.317-6.687c-1.094-8.038,0.47-23.639-2.526-43.985
				c-0.76-5.167-1.815-10.641-3.286-16.371c-0.946-3.686-1.225-6.628-3.101-8.798c-0.147-0.169-0.301-0.328-0.465-0.481
				c-0.569-0.558-1.269-1.05-2.133-1.487c-1.317-0.667-4.046,0.64-6.233,1.318c-0.476,0.148-0.924,0.263-1.329,0.323
				c-0.651,0.099-1.323,0.148-2.001,0.164c-1.214,0.043-2.45-0.011-3.658-0.087c-3.68-0.213-7.059-0.542-8.486,1.427
				c-0.208,0.273-0.366,0.59-0.486,0.957c-0.131,0.399-0.246,0.837-0.339,1.307C531.98,404.056,533.047,413.608,534.944,423.725z"/>
			<path fill="#1E1E1E" d="M500.196,546.043c0.279,4.553,13.98,2.532,16.628,0.605c0.031-0.719-0.682-1.188-0.545-2.432
				c0.061-0.539,1.445-0.119,2.015-3.94c0.078-0.546-1.018-3.164-0.938-3.776c0.093-0.683,0.503-1.136,1.667-4.685
				c0.834-2.548-0.455-9.372-0.373-9.951c2.391-16.846,6.823-32.001,6.85-35.073c0.13-14.412,9.588-36.652,15.227-56.835
				c6.989-1.331,6-13.686,5.325-24.423c-0.495-7.626-0.288-7.956-0.333-8.907c-0.027-2.652-14.019-1.929-20.364-2.686
				c-3.819-0.462-7.446-2.145-10.948,1.713c-1.393,1.535-4.363,11.407-4.816,14.128c-2.189,13.129-4.357,32.759-3.741,42.165
				c1.779,27.592-6.954,27.113-5.442,62.858c0.35,8.241-0.765,14.037,0.127,16.626c0.604,1.723,1.939,2.736,0.6,3.792
				c-1.023,0.805-1.201,4.131-1.236,4.658c-0.021,0.346,1.314,1.354,1.131,1.653C499.546,543.934,500.345,544.486,500.196,546.043z"
				/>
			<path fill="#6B402F" d="M514.937,396.786c16.317,1.61,29.484,4.266,43.404,0.913l-0.109-4.254
				c-14.243,3.122-26.996,1.441-42.938-0.56L514.937,396.786z"/>
			<linearGradient id="SVGID_103_" gradientUnits="userSpaceOnUse" x1="539.4155" y1="354.04" x2="551.8433" y2="354.04">
				<stop  offset="0" style="stop-color:#F8E388"/>
				<stop  offset="1" style="stop-color:#EF7432"/>
			</linearGradient>
			<path fill="url(#SVGID_103_)" d="M539.416,322.145l2.93-0.571c0,0,12.041,16.013,9.008,59.017l-4.225,5.915l-5.957-6.449
				C541.172,380.057,540.541,336.584,539.416,322.145z"/>
			<linearGradient id="SVGID_104_" gradientUnits="userSpaceOnUse" x1="535.6924" y1="318.9287" x2="542.9961" y2="318.9287">
				<stop  offset="0" style="stop-color:#F8E388"/>
				<stop  offset="1" style="stop-color:#EF7432"/>
			</linearGradient>
			<path fill="url(#SVGID_104_)" d="M535.692,316.081l3.555,5.916l3.099-0.423c0,0,1.037-5.985,0.493-5.704
				C542.295,316.152,535.692,316.081,535.692,316.081z"/>
			<path fill="#DFEAF7" d="M523.198,309.609c0.12,0.394,0.427,1.061,0.837,1.854c0.438,0.848,0.995,1.848,1.563,2.811
				c0.848,1.438,1.711,2.799,2.242,3.488c0.738,0.952,1.46,1.771,2.209,2.515c1.575,1.564,3.281,2.8,5.55,4.282
				c0.601-3.607,3.221-7.387,4.495-8.492c0.076-0.066,5.173,3.249,6.332,6.605c3.508-4.04-0.328-11.658-0.465-11.947
				c-0.273-0.569-0.557-1.799-1.5-2.126c-0.207-0.149-0.217,2.902-0.293,3.11c-1.842,5.058-7.048,4.09-10.438,2.641
				c-1.039-0.443-3.035-1.755-4.91-3.259c-0.858-0.689-3.242-3.007-3.56-3.417v-0.006
				C524.215,306.306,523.275,309.56,523.198,309.609z"/>
			<linearGradient id="SVGID_105_" gradientUnits="userSpaceOnUse" x1="525.0527" y1="397.3433" x2="583.025" y2="339.371">
				<stop  offset="0" style="stop-color:#6B73FF"/>
				<stop  offset="1" style="stop-color:#000DFF"/>
			</linearGradient>
			<path fill="url(#SVGID_105_)" d="M545.125,403.533c0.333,1.685,0.646,3.232,0.94,4.653c0,0.006,0,0.006,0,0.006
				c0.531,2.548,1.006,4.697,1.455,6.501v0.006c2.515,10.296,4.052,9.662,9.093,9.662c0,0,5.206-0.41,8.115-1.531
				c1.252-0.486,1.887-1.075,1.892-1.87c0.027-4.64-3.108-23.453-3.545-27.784c-0.104-1.011-2.376-8.595-2.082-19.586
				c0.011-0.394,0.022-0.787,0.039-1.192c0.087-2.783,0.224-5.758,0.438-8.858c0.071-1.093,0.153-2.209,0.246-3.335
				c0.169-2.089,0.377-4.232,0.618-6.397c0.099-0.93,0.213-1.859,0.328-2.8c0.049-0.41,0.164-1.257,0.164-1.274
				c0.005-0.011,0.005-0.022,0.005-0.033c0.005,0,0.005,0,0.005,0c0.006-0.044,0.022-0.099,0.039-0.164
				c0.596-2.012,4.402-17.011,1.955-25.33c-0.057-0.192-1.219-4.103-2.255-5.282c-0.907-1.033-6.256-3.884-6.268-3.89
				c-3.368-1.728-7.562-3.479-10.219-4.272c-0.043-0.017-0.087-0.027-0.125-0.038c-0.011-0.005-0.027-0.005-0.039-0.011
				c0.104,0.246,0.213,0.531,0.323,0.858h0.005c0.525,1.509,1.159,3.839,1.783,6.704c0,0.005,0,0.011,0.005,0.016
				c0.044,0.202,0.087,0.41,0.131,0.624c1.755,8.268,3.412,20.538,2.603,30.173c-0.093,1.093-0.213,2.154-0.377,3.166
				c-0.443,2.821-1.154,5.309-2.215,7.245c-0.284,1.345-0.541,2.635-0.782,3.882v0.005c-0.542,2.838-0.962,5.43-1.274,7.885
				c0,0.017,0,0.027-0.006,0.039v0.005c-0.016,0.137-0.038,0.268-0.049,0.404c-0.569,4.561-0.76,8.651-0.662,12.927
				c0.082,3.259,0.328,6.633,0.71,10.411c0.011,0.147,0.027,0.301,0.044,0.454c0.065,0.624,0.065,1.296,0.016,1.985
				c-0.027,0.454-0.071,0.913-0.131,1.367c0,0,0,0,0,0.005c-0.017,0.137-0.033,0.273-0.055,0.41
				c-0.066,0.465-0.142,0.913-0.224,1.34C545.464,402.232,545.109,403.468,545.125,403.533z"/>
			<linearGradient id="SVGID_106_" gradientUnits="userSpaceOnUse" x1="487.8569" y1="407.7148" x2="554.5779" y2="340.9939">
				<stop  offset="0" style="stop-color:#6B73FF"/>
				<stop  offset="1" style="stop-color:#000DFF"/>
			</linearGradient>
			<path fill="url(#SVGID_106_)" d="M550.185,382.216c-0.086,4.062-0.399,8.115-0.59,12.586c-0.038,0.723-0.066,1.456-0.085,2.207
				c-0.019,0.685-0.038,1.379-0.048,2.083c-0.009,0.361-0.009,0.723-0.019,1.094c-0.038,4.12-0.219,6.754-0.257,6.993
				c-0.561,2.92-1.113,5.413-1.665,7.515c-0.514,1.95-1.028,3.577-1.542,4.947c-3.063,8.02-6.307,6.593-10.911,6.488
				c-0.59-0.01-1.199,0-1.827,0.057l-26.466-1.674l-2.264-0.143c-0.009-0.704,0.01-1.437,0.048-2.198
				c0.428-9.779,4.128-24.334,5.413-27.607c2.198-5.575,3.301-15.173-0.571-22.27c-3.71-6.792-6.459-14.964-8.248-23.231
				c-0.362-1.693-0.694-3.396-0.98-5.089c-0.342-2.055-0.628-4.11-0.856-6.126c-0.133-1.132-0.248-2.255-0.333-3.368
				c-0.162-1.903-0.257-3.767-0.305-5.575c-0.085-3.644,2.729-7.363,6.677-10.674c4.262-3.586,9.933-6.688,16.801-8.372
				c0.343-0.095,0.685-0.171,1.037-0.248c0.352,0.438,0.704,0.895,1.056,1.36c0.637,0.875,1.274,1.779,1.902,2.73
				c1.341,2.045,2.645,4.252,3.891,6.574c3.977,7.354,7.449,15.801,10.332,23.479c1.494,3.967,2.816,7.725,3.967,11.016
				c1.227,3.51,2.255,6.498,3.054,8.609c0.638,1.665,1.132,2.806,1.475,3.253c0.048,0.2,0.085,0.399,0.133,0.6
				c0.181,0.941,0.343,1.864,0.485,2.768c0.219,1.398,0.38,2.769,0.485,4.1C550.213,376.879,550.251,379.552,550.185,382.216z"/>
			<g>
				
					<linearGradient id="SVGID_107_" gradientUnits="userSpaceOnUse" x1="507.6816" y1="395.4517" x2="527.0303" y2="395.4517" gradientTransform="matrix(0.9988 -0.0481 0.0481 0.9988 -14.6269 25.0734)">
					<stop  offset="0" style="stop-color:#6B73FF"/>
					<stop  offset="1" style="stop-color:#000DFF"/>
				</linearGradient>
				<path fill="url(#SVGID_107_)" d="M514.003,399.165l13.708,1.278c1.512,0.141,2.852-0.97,2.993-2.482l-0.055-5.722
					c0.047-0.505-0.05-1.338-0.556-1.385l-17.069-0.909c-0.505-0.047-0.953,0.325-1,0.83l-0.503,5.397
					C511.38,397.684,512.492,399.023,514.003,399.165z"/>
				
					<linearGradient id="SVGID_108_" gradientUnits="userSpaceOnUse" x1="507.104" y1="394.2002" x2="527.1753" y2="394.2002" gradientTransform="matrix(0.9988 -0.0481 0.0481 0.9988 -14.6269 25.0734)">
					<stop  offset="0" style="stop-color:#6B73FF"/>
					<stop  offset="1" style="stop-color:#000DFF"/>
				</linearGradient>
				<path fill="url(#SVGID_108_)" d="M512.862,397.885l14.868,1.386c1.191,0.111,2.248-0.765,2.358-1.957l0.643-6.892l-18.881-1.76
					l-0.945,6.864C510.794,396.718,511.67,397.774,512.862,397.885z"/>
			</g>
			<g>
				<linearGradient id="SVGID_109_" gradientUnits="userSpaceOnUse" x1="558.8647" y1="395.3926" x2="563.7808" y2="395.3926">
					<stop  offset="0" style="stop-color:#6B73FF"/>
					<stop  offset="1" style="stop-color:#000DFF"/>
				</linearGradient>
				<path fill="url(#SVGID_109_)" d="M563.581,399.054l-3.511,0.323c-0.387,0.036-0.792-0.928-0.905-2.154l-0.273-4.606
					c-0.038-0.409-0.054-1.075,0.075-1.086l3.921-0.123c0.129-0.012,0.692,5.262,0.692,5.262
					C563.693,397.895,563.969,399.018,563.581,399.054z"/>
				<linearGradient id="SVGID_110_" gradientUnits="userSpaceOnUse" x1="558.7788" y1="394.5811" x2="564.2485" y2="394.5811">
					<stop  offset="0" style="stop-color:#6B73FF"/>
					<stop  offset="1" style="stop-color:#000DFF"/>
				</linearGradient>
				<path fill="url(#SVGID_110_)" d="M563.357,398.124l-2.893,0.266c-0.558,0.051-1.067-0.527-1.138-1.292l-0.548-5.949l4.142-0.381
					l1.32,5.878C564.311,397.411,563.915,398.072,563.357,398.124z"/>
			</g>
			<g>
				<linearGradient id="SVGID_111_" gradientUnits="userSpaceOnUse" x1="545.6147" y1="374.4756" x2="548.8984" y2="374.4756">
					<stop  offset="0" style="stop-color:#6B73FF"/>
					<stop  offset="1" style="stop-color:#000DFF"/>
				</linearGradient>
				<ellipse fill="url(#SVGID_111_)" cx="547.257" cy="374.476" rx="1.642" ry="2.164"/>
				<linearGradient id="SVGID_112_" gradientUnits="userSpaceOnUse" x1="545.1245" y1="389.2837" x2="548.4082" y2="389.2837">
					<stop  offset="0" style="stop-color:#6B73FF"/>
					<stop  offset="1" style="stop-color:#000DFF"/>
				</linearGradient>
				<ellipse fill="url(#SVGID_112_)" cx="546.766" cy="389.284" rx="1.642" ry="2.165"/>
			</g>
			<linearGradient id="SVGID_113_" gradientUnits="userSpaceOnUse" x1="483.6611" y1="401.2725" x2="536.1646" y2="348.769">
				<stop  offset="0" style="stop-color:#6B73FF"/>
				<stop  offset="1" style="stop-color:#000DFF"/>
			</linearGradient>
			<path opacity="0.5" fill="url(#SVGID_113_)" d="M525.141,380.17c-2.34,1.988-2.06,2.892-2.868,5.946
				c-1.303,4.899-3.339,10.771-6.288,15.005c-2.236,3.215-3.663,6.076-5.755,9.368c-1.503,2.359-2.845,4.595-4.053,7.125
				c-0.685,1.427-0.914,3.149-1.617,4.557c0.428-9.779,4.128-24.334,5.413-27.607c2.198-5.575,3.301-15.173-0.571-22.27
				c-3.71-6.792-6.459-14.964-8.248-23.231c1.284-1.893,2.597-3.767,3.9-5.66c0.885-1.294,1.846-3.729,3.187-4.557
				c0.875-0.551,0.99-5.179,1.846-5.788c4.229,3.25,11.152,17.251,18.525,12.252c1.457-0.988,11.751,6.676,11.875,8.15
				C540.985,359.596,529.67,376.312,525.141,380.17z"/>
			<g>
				<g>
					
						<rect x="546.158" y="323.579" transform="matrix(0.9808 -0.1952 0.1952 0.9808 -54.625 113.4492)" fill="#1E1E1E" width="4.514" height="20.708"/>
					<path fill="#1E1E1E" d="M548.227,324.092l-3.406,0.678c-1.184,0.235-2.333-0.533-2.569-1.716l-1.385-6.958
						c-0.235-1.183,0.533-2.333,1.716-2.569l3.407-0.678c1.183-0.235,2.333,0.533,2.569,1.716l1.385,6.958
						C550.179,322.706,549.411,323.856,548.227,324.092z"/>
					<path fill="#62667C" d="M543.252,324.258l6.223-1.235c0,0,0.017,2.195-1.036,2.595c-1.053,0.399-3.192,0.642-3.192,0.642
						S544.111,326.709,543.252,324.258z"/>
				</g>
				<path fill="#FED0CA" d="M532.778,341.8c0.99-2.186,2.328-3.899,4.031-5.847c1.849-2.115,6.207-7.037,10.246-8.26
					c1.143-0.346,3.091-1.249,3.621-0.084c0.537,1.18-2.298,2.147-3.501,2.75c-1.072,0.537-3.285,2.406-4.369,3.841
					c2.592-1.742,4.444-2.533,5.312-2.852c4.098-1.508,6.071-2.931,6.308-0.989c0.088,0.721-2.027,1.989-5.368,3.684
					c-2.104,1.068-3.582,2.425-4.071,2.964c2.936-2.14,9.748-4.927,10.367-3.784c0.843,1.555-2.044,2.186-4.356,3.694
					c-2.407,1.57-2.969,2.134-4.191,3.385c5.112-2.792,7.537-3.992,8.073-2.955c0.437,0.846-1.71,2.506-4.764,4.225
					c-2.788,1.568-5.889,4.368-8.318,6.497c-2.886,2.53-5.349,8.144-9.85,5.376c-1.829-1.125-2.385-2.529-2.199-4.606
					C529.863,347.583,532.097,343.304,532.778,341.8z"/>
				<path fill="#DFEAF7" d="M528.097,348.724c-0.395-0.812,2.348-4.173,3.32-4.944c2.938,1.785,6.287,4.615,9.183,6.345
					c-1.034,1.978-2.089,5.866-4.817,5.274C528.196,353.754,528.308,349.157,528.097,348.724z"/>
				
					<linearGradient id="SVGID_114_" gradientUnits="userSpaceOnUse" x1="689.3691" y1="534.8057" x2="730.3896" y2="550.8187" gradientTransform="matrix(0.9315 -0.3636 0.3636 0.9315 -340.147 105.5636)">
					<stop  offset="0" style="stop-color:#000DFF"/>
					<stop  offset="0.113" style="stop-color:#1E29FF"/>
					<stop  offset="0.2477" style="stop-color:#3A44FF"/>
					<stop  offset="0.3931" style="stop-color:#5059FF"/>
					<stop  offset="0.5524" style="stop-color:#5F68FF"/>
					<stop  offset="0.7361" style="stop-color:#6870FF"/>
					<stop  offset="1" style="stop-color:#6B73FF"/>
				</linearGradient>
				<path fill="url(#SVGID_114_)" d="M513.87,382.148c13.783-4.518,21.508-19.263,26.618-28.689
					c-4.712-1.413-8.66-4.435-11.875-8.15c-5.26,8.693-10.158,10.367-13.173,17.189c0.663-2.854,0.264-3.114,0.292-6.783
					c0.589-5.571-5.391-31.833-15.28-32.028c-7.756,7.433-3.213,33.118,5.015,54.616
					C506.853,381.926,510.914,383.118,513.87,382.148z"/>
			</g>
			<path fill="#FED0CA" d="M527.196,292.033c-0.687-0.852,2.878,3.173,0.244,5.296c-1.348,1.087-3.642,0.478-5.125-1.361
				s-1.592-4.21-0.244-5.297C523.419,289.584,525.713,290.194,527.196,292.033z"/>
			<linearGradient id="SVGID_115_" gradientUnits="userSpaceOnUse" x1="510.876" y1="337.5889" x2="511.1328" y2="337.5889">
				<stop  offset="0" style="stop-color:#6B73FF"/>
				<stop  offset="1" style="stop-color:#000DFF"/>
			</linearGradient>
			<path fill="url(#SVGID_115_)" d="M510.876,337.485c0.086,0.057,0.171,0.124,0.257,0.19
				C511.009,337.713,510.905,337.704,510.876,337.485z"/>
			<linearGradient id="SVGID_116_" gradientUnits="userSpaceOnUse" x1="511.1328" y1="337.666" x2="511.2378" y2="337.666">
				<stop  offset="0" style="stop-color:#6B73FF"/>
				<stop  offset="1" style="stop-color:#000DFF"/>
			</linearGradient>
			<path fill="url(#SVGID_116_)" d="M511.238,337.637c-0.029,0.019-0.057,0.038-0.085,0.057c0,0-0.01-0.019-0.02-0.019
				C511.171,337.666,511.2,337.656,511.238,337.637z"/>
			<linearGradient id="SVGID_117_" gradientUnits="userSpaceOnUse" x1="511.0811" y1="366.1011" x2="516.1016" y2="366.1011">
				<stop  offset="0" style="stop-color:#6B73FF"/>
				<stop  offset="1" style="stop-color:#000DFF"/>
			</linearGradient>
			<path opacity="0.6" fill="url(#SVGID_117_)" d="M515.588,361.858c-0.55,1.591-2.657,2.892-3.83,4.048
				c-0.324,0.32-1.27,1.76-0.132,1.644c0.629-0.064,1.228-1.636,1.488-2.101c0.118,0.779-0.265,1.586-0.303,2.376
				c-0.023,0.475-0.076,2.213,0.476,2.521c0.688,0.384,0.697-0.877,0.718-1.345c0.055-1.237-0.044-2.522,0.395-3.707
				c0.327,0.38,0.492,1.457,1.173,1.467c0.82,0.012,0.481-0.949,0.331-1.45c-0.363-1.211-0.886-2.271-0.536-3.526
				C515.49,361.806,515.37,361.859,515.588,361.858z"/>
			<linearGradient id="SVGID_118_" gradientUnits="userSpaceOnUse" x1="582.8135" y1="348.3501" x2="587.6367" y2="348.3501">
				<stop  offset="0" style="stop-color:#6B73FF"/>
				<stop  offset="1" style="stop-color:#000DFF"/>
			</linearGradient>
			<path opacity="0.6" fill="url(#SVGID_118_)" d="M585.827,345.13c0.083,0.576-0.448,1.632-0.786,2.221
				c-0.511,0.892-2.394,2.571-2.216,3.721c0.148,0.966,1.118,0.324,1.497-0.226c0.509-0.738,0.916-1.832,1.186-2.676
				c0.362,0.802,0.143,1.888,0.888,2.535c1.117,0.971,1.381-0.478,1.179-1.394c-0.216-0.977-0.567-1.951-1.073-2.803
				C586.267,346.115,585.618,345.453,585.827,345.13z"/>
			<path fill="#FDB9B1" d="M616.349,325.232c1.005-0.929,1.12-2.775,1.313-4.103c0.154-1.066-0.04-1.014,0.758-1.497
				c0.595-0.359,1.358-0.657,1.949-1.042c1.404,1.391,3.586,3.775,4.051,5.669c-1.894,0.126-3.753,0.224-5.643,0.47
				C618.268,324.796,615.56,325.267,616.349,325.232z"/>
			<linearGradient id="SVGID_119_" gradientUnits="userSpaceOnUse" x1="520.6748" y1="269.3711" x2="544.4673" y2="269.3711">
				<stop  offset="0" style="stop-color:#1E1E1E"/>
				<stop  offset="1" style="stop-color:#63322B"/>
			</linearGradient>
			<path opacity="0.6" fill="url(#SVGID_119_)" d="M544.467,271.901c-2.226,2.122-7.858,1.351-10.075,1.056
				c-3.073-0.419-5.813-1.589-8.971-0.99c-1.494,0.286-2.873,0.771-4.214,1.494c-0.19,0.105-0.361,0.209-0.533,0.333
				c1.341-1.712,3.206-3.5,5.793-5.299c7.211-5.004,12.985-4.167,16.068-0.866C543.82,269,544.334,270.513,544.467,271.901z"/>
		</g>
	</g>
</g>
</svg>
				
				</div>
				
				
			</div>

		</div>
	</section>
	
	<section id="paticka">
		<div class="container">
			<div class="row">

				<div class="col-12 text-center">
					<p class="">
						<!--<a href="https://pp.sk" target="_blank">-->
							<img v-if="event.confFileLogo" class="img-fluid" :src='"/images/"+event.confFileLogo' alt="Poradca podnikatela" title="Poradca podnikatela">
							<img v-else class="img-fluid" src="/images/PP2017_black.svg" alt="Poradca podnikatela" title="Poradca podnikatela">
						<!--</a>-->
						<br>
						</p><div v-if="!event.confFileLogo" class="subtitle" v-on:click="loadAlerts()"><p>{{ $t('message.30yearsWithYou') }}</p></div>
						
					<p></p>				
				</div>
			</div>
		</div>
	</section>

	<section v-if="event.isReg && alerts.length>0" id="alerts">
		<div class="container">
			<!--<div class="row">
				<div class="col-12">
					<h3><font-awesome-icon :icon="['fas', 'times']" /></h3>
				</div>
			</div>-->
			
			<div class="row">
				<div v-for="(item,index) in alerts" :key="item" class="col-12 col-md-4 offset-md-4 alertType" :class="item.typeName">
					<div class="row">
						<div class="col-10">
							<p :class="item.typeName">{{item.title}}</p>
						</div>
						<div class="col-2 box-flex">
							<span class="closeAlert" v-on:click="closeAlert(item.id,item.eventId,index)"><font-awesome-icon :icon="['fas', 'times']" /></span>
						</div>
					</div>
				</div>
			</div>
			
		</div>
	</section>

	<div id="myModalLogin" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog" style="">
			<div class="modal-content">
				
				<div class="modal-header">
					<h6 class="modal-title"><span>{{ $t('message.Admin') }}</span></h6>
					<div class="modal-button-close" v-on:click="closeModalBoxSecure('myModalLogin')" ><font-awesome-icon :icon="['fas', 'times']" /></div>
					
				</div>
		
				<div class="modal-body">
					<div class="row">
						<div class="col-12">
						
							<div class="form-row">
								<div class="form-group col-12">
									<input class="form-control form-control-sm" type="text" v-model="user.email" :placeholder="$t('message.Email')"/>
								</div>
							</div>
							
							<div class="form-row">
								<div class="form-group col-12" >
									<input :type="user.showInput ? 'text' : 'password'" autocomplete="new-password" class="form-control form-control-sm" v-model="user.password" :placeholder="$t('message.Password')">
									
								</div>
							</div>
							
							<div class="form-row">
								<div class="form-group col-12 text-right" >
									
									<span class="btn btn-link btn-sm" v-on:click="showPswd()">
										
										<span v-if="!user.showInput">{{ $t('message.showPassword') }} <font-awesome-icon :icon="['fas', 'eye']" /></span>
										
										<span v-else>{{ $t('message.hidePassword') }}<font-awesome-icon  :title="$t('message.hidePassword')" :icon="['fas', 'eye-slash']" /></span>
									</span>
								</div>
							</div>
							
							<div class="row">
								
								<div class="col-12 text-right">
									<button v-on:click="signMe()" class="btn btn-warning btn-sm">{{ $t('message.Login') }}</button>
								</div>
								
							</div>
								
						</div>
					</div>	
				</div>
				
			</div>
		</div>
	</div>
	
	
	
	<div id="myModalLogOutEvent" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog" style="">
			<div class="modal-content">
				
				<div class="modal-header">
					<h6 class="modal-title"><span>{{ $t('message.doYouWantRealyLogOutEvent') }}</span></h6>
					<div class="modal-button-close" v-on:click="closeModalBoxSecure('myModalLogOutEvent')" ><font-awesome-icon :icon="['fas', 'times']" /></div>
				</div>
		
				<div class="modal-body">
					<div class="row">
						<div class="col-12">
						
							<div class="row">
								<div class="col-12 text-right">
									<button v-on:click="logoutEvent()" class="btn btn-danger btn-sm">{{ $t('message.LogOut') }}</button>
								</div>
							</div>

						</div>
					</div>	
				</div>
				
			</div>
		</div>
	</div>

</template>

<style lang="scss">
	@import "./assets/scss/style.scss";
</style>