<script>

import { library } from '@fortawesome/fontawesome-svg-core';
import { faGlobe, faVideo } from '@fortawesome/free-solid-svg-icons';
import { faLinkedinIn, faInstagram, faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons'
library.add(faLinkedinIn, faInstagram, faFacebookF, faTwitter, faGlobe, faVideo)
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import $ from 'jquery'; 


//import { VideoPlayer } from 'vue-video-player'

//general functions
import MixinGeneralFunct from '../mixins/GeneralFunctions';

import axios from 'axios';

export default {
	name: 'Exhibitor',
	mixins: [ MixinGeneralFunct ],
	components: {
		"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
	},
	props: ["myUserReg","myEventReg","myEventCounts"],
	data() {
		return {
			exhibitorId:null,
			exhibitorData:[],
			exhibitorDocs:[],
			//bckLink:null,
		}
	},
	
	mounted:function(){
		
		this.exhibitorId = this.$route.params.id;
	//	this.bckLink = this.$route.params.bcklnk;
	//	console.log(this.bckLink);
		this.loadDefaultValues();
		
	},
	
	methods: {
		
		//default data
		loadDefaultValues:function(){
			//console.log(this.exhibitorId);
			var self = this;
			axios.get('/app/includeData/Exhibitors/getExhibitorData.php',{
				params:{
					id:this.exhibitorId,
				}
			})
			.then(function (response){
				//console.log("loadExhibitorData");
				//console.log(response.data);
				
				//hide loader
				$('#preloader').fadeOut(200);
				
				self.exhibitorData = response.data.exhibitorData;
				self.exhibitorDocs = response.data.exhibitorDocs;
				
			})
				.catch(function (error) {
				console.log(error);
			});
		}		
	},	
	
			
	watch: {
		myEventReg() {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
}

</script>

<template>
	
	<div id="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
	
		<section id="head-buttons" class="">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12" >
						<router-link :to="'/exhibitors'"><button class="btn btn-secondary btn-sm">{{ $t('message.Back') }}</button></router-link>
					</div>
				</div>	
			</div>
		</section>	
		
		<section v-if="exhibitorData.resCode==1" id="exhibitor" class="">
			<div class="container">
				<div class="row">
					
					<div class="col-12 col-md-4 offset-md-4 logo" >
						
						<div class="row">
							<div class="col-12 box-flex">
							
								<div class=" box-flex">	
									<div class="row">
										<div class="col-12">
											
											<span v-if="exhibitorData.urlWeb">
												<a :href="exhibitorData.urlWeb" target="_blank" ><img :src="exhibitorData.photoDataOrigo" :alt="exhibitorData.title" class="img-fluid"></a>
											</span>
											<span v-else>
												<img :src="exhibitorData.photoDataOrigo" :alt="exhibitorData.firstname+' '+exhibitorData.surname" class="img-fluid">
											</span>
										</div>
										
									</div>		
								</div>
								
							</div>					
						</div>	
						
					</div>
					
					
					<div v-if="exhibitorData.urlVideo" class="col-12 text-center col-md-6 offset-md-3" >
						<div class="embed-responsive embed-responsive-16by9">
							<iframe class="embed-responsive-item" :src="exhibitorData.urlVideo" allowfullscreen=""></iframe>
						</div>
					</div>
					
					<div v-if="exhibitorData.youtubeCode" class="col-12 text-center col-md-6 offset-md-3" >
						<div class="embed-responsive embed-responsive-16by9">
							<iframe class="embed-responsive-item" :src="'https://www.youtube.com/embed/'+exhibitorData.youtubeCode" allowfullscreen=""></iframe>
						</div>
					</div>
					
					
					<div class="col-12 text-center col-md-8 offset-md-2" >
						<div class="row">
								
							<div class="col-12">
								<div class="description">
									
									<span class="title">{{exhibitorData.title}}<br></span>
									<span class="subTitle">{{exhibitorData.subTitle}}<br><br></span>
									<span class="descriptionExhibitor"><p>{{exhibitorData.description}}</p></span>
									
								<span v-if="exhibitorData.surname || exhibitorData.email || exhibitorData.phone || exhibitorData.urlLiveCall">	
									<span class="name"><b>{{ $t('message.Contact') }}</b><br></span>
									<span class="name">{{exhibitorData.titleBefore}} {{exhibitorData.firstname}} {{exhibitorData.surname}} {{exhibitorData.titleAfter}}<br></span>
									<span v-if="exhibitorData.email" class="email"><a :href="'mailto:'+exhibitorData.email" target="_blank" >{{exhibitorData.email}}</a><br></span>
									<span v-if="exhibitorData.phone" class="phone"><a :href="'tel:'+exhibitorData.phone" target="_blank" >{{exhibitorData.phone}}</a><br></span>
									<span v-if="exhibitorData.urlLiveCall" class="video"><a :href="exhibitorData.urlLiveCall" target="_blank" ><font-awesome-icon :icon="['fas', 'video']" /> {{ $t('message.VideoCall') }}</a><br></span>
								</span>
								
								</div>
							</div>
							
						</div>
					</div>
					
					
					
					<div v-if="exhibitorDocs.length > 0" class="col-12 text-center col-md-8 offset-md-2" >
						<div class="row">

							<div class="col-md-12 col-12">
								<span class="text-center"><b>{{ $t('message.DocumentsToDownload') }}</b></span>
							</div>
							
							<div class="col-md-12 col-12 table-responsive">
							
								<table class="table table-sm">
									
									<tr v-for="item in exhibitorDocs" v-bind:key="item">
										
										<td class="text-left"><a :href="item.fileUrl">{{item.title}}</a></td>
										<td>{{item.extension}}</td>
										<td>{{item.size}}</td>
									</tr>

								</table>

							</div>
						
						</div>
					</div>
					
					

					<div class="col-12 text-center col-md-12" >
						
						<div class="row">
							<div class="col-12">
								<div class="row box-flex">
									<div class="col-2 social">
										<a v-if="exhibitorData.urlLinkedIn" :href="exhibitorData.urlLinkedIn" target="_blank" ><font-awesome-icon :icon="['fab', 'linkedin-in']" /></a>
									</div>
									
									<div class="col-2 social">
										<a v-if="exhibitorData.urlFacebook" :href="exhibitorData.urlFacebook" target="_blank" ><font-awesome-icon :icon="['fab', 'facebook-f']" /></a>
									</div>
									
									<div class="col-2 social">
										<a v-if="exhibitorData.urlWeb" :href="exhibitorData.urlWeb" target="_blank" ><font-awesome-icon :icon="['fas', 'globe']" /></a>
									</div>
									
									<div class="col-2 social">
										<a v-if="exhibitorData.urlInstagram" :href="exhibitorData.urlInstagram" target="_blank" ><font-awesome-icon :icon="['fab', 'instagram']" /></a>
									</div>
									
									<div class="col-2 social">
										<a v-if="exhibitorData.urlTwitter" :href="exhibitorData.urlTwitter" target="_blank" ><font-awesome-icon :icon="['fab', 'twitter']" /></a>
									</div>
								</div>
							</div>		
						</div>
						
					</div>
				
				</div>
			</div>
		</section>
		
		<section v-else-if="exhibitorData.resCode==2">
			<div class="container">
			<div class="row">
				<div class="col-12 text-center">
					<h5 style="padding: 50px 0;">Pre zobrazenie tejto sekcie musíte byť prihlásený</h5>
				</div>
			</div>
			</div>
		</section>
			
		<section v-else-if="exhibitorData.resCode==3">
			<div class="container">
			<div class="row">
				<div class="col-12 text-center">
					<h5 style="padding: 50px 0;">Chýbajú vstupné údaje</h5>
				</div>
			</div>
			</div>
		</section>
				
		<section v-else-if="exhibitorData.resCode==0">
			<div class="container">
			<div class="row">
				<div class="col-12 text-center">
					<h5 style="padding: 50px 0;">K záznamu nemáte prístup</h5>
				</div>
			</div>
			</div>
		</section>

</template>


