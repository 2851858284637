<script>

//import { library } from '@fortawesome/fontawesome-svg-core';
//import { faClock } from '@fortawesome/free-solid-svg-icons';
//import { faLinkedinIn, faInstagram, faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons'
//library.add(faClock)
//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import $ from 'jquery'; 


//import { VideoPlayer } from 'vue-video-player'

//general functions
import MixinGeneralFunct from '../mixins/GeneralFunctions';

import axios from 'axios';

export default {
	name: 'Program',
	mixins: [ MixinGeneralFunct ],
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
	},
	props: ["myUserReg","myEventReg","myEventCounts"],
	data() {
		return {
			programData: []
		}
	},
	
	mounted:function(){
		
		this.loadDefaultValues();
		
	},
	
	methods: {
		
		//default data
		loadDefaultValues:function(){
			var self = this;
			axios.get('/app/includeData/Program/getProgramData.php')
			.then(function (response) {
				//console.log("loadProgramData");
				//console.log(response.data);
				
				//hide loader
				$('#preloader').fadeOut(200);
				
				self.programData = response.data.programData;
				
			})
				.catch(function (error) {
				console.log(error);
			});
		}		
	},	
	
			
	watch: {
		myEventReg() {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
}

</script>

<template>
	
	<div id="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
	
		<section v-if="programData.resCode==1" id="program" class="">
			
			<div class="container">
				<div class="row">
					<div class="col-12">
					
						<ul class="nav nav-tabs" role="tablist">
							
							<li  v-for="(prog,index) in programData.arrProgram" v-bind:key="prog.id" class="nav-item">
								<a class="nav-link" :class="{'active':index==0}" :id="'menu' + index + '-tab'" data-toggle="tab" :href="'#menu'+index" role="tab" :aria-controls="'menu'+index" aria-selected="true">{{prog.name}}</a>
							</li>

						</ul>
					
					</div>
					
					<div class="tab-content" id="myTabContent">
						<div v-for="(prog,index) in programData.arrProgram" v-bind:key="prog.id" :id="'menu'+index" class="tab-pane fade" :class="{'active':index==0,'show':index==0}" role="tabpanel">
						
							<div class="row block">
								<div class="col-12">
								
									<div class="people">
																			
										<table v-if="prog.items.length > 0" class="table">
											
												<tr v-for="item in prog.items" v-bind:key="item.id">
													<td v-if="item.begin_timeView">{{item.begin_timeView}}&nbsp;-&nbsp;{{item.end_timeView}}</td> <td v-else></td>
													<td>
														<span v-if="item.title_block" :class="{'titleBlock':item.title_block}">{{item.title_block}}</span>
														<span v-if="item.subtitle_block" :class="{'subtitleBlock':item.subtitle_block}">{{item.subtitle_block}}</span>
														<span v-if="item.title_block || item.subtitle_block"><br></span>
														
														<span v-if="item.title_lecture" :class="{'titleLecture':item.title_lecture}">{{item.title_lecture}}</span>
														<span v-else-if="item.title_non_lecture" :class="{'titleNonLecture':item.title_non_lecture}">{{item.title_non_lecture}}</span>
														
														
														
														<br><span :class="{'description':item.description}">{{item.description}}</span>
														
														<span v-if="item.speakers.length>0">
														
															<span v-for="spk in item.speakers" v-bind:key="spk.speakerId" class="speaker">
																<router-link :to="'/speaker/' + spk.speakerId + '/program'" class="mySpeaker">
																
																<br><span class="name">{{spk.firstname}} {{spk.surname}}</span> | {{spk.position}} | {{spk.company}}
																</router-link>
															</span>
														</span>
													</td>
												</tr>
											
										</table>
										<!--<router-link :to="'/speaker/' + item.id" class="mySpeaker">

											<div class="row">
												<div class="col-12">
													<img :src="item.photoDataOrigo" :alt="item.firstname+' '+item.surname" class="img-fluid img-speaker" >
												</div>
											
												<div class="col-12">
													<div class="description">
														<span class="name">{{item.firstname}} {{item.surname}}<br></span>
														<span class="position">{{item.position}}<br></span>
														<span class="company">{{item.company}}<br></span>
													</div>
												</div>
											</div>	
										</router-link>-->
									
									</div>
								</div>		
							</div>
						
						</div>
					</div>
					
				</div>
			</div>
		</section>
		
		<section v-else-if="programData.resCode==2">
			<div class="container">
			<div class="row">
				<div class="col-12 text-center">
					<h5 style="padding: 50px 0;">Pre zobrazenie tejto sekcie musíte byť prihlásený</h5>
				</div>
			</div>
			</div>
		</section>
			
		<section v-else-if="programData.resCode==3">
			<div class="container">
			<div class="row">
				<div class="col-12 text-center">
					<h5 style="padding: 50px 0;">Chýbajú vstupné údaje</h5>
				</div>
			</div>
			</div>
		</section>
				
		<section v-else-if="programData.resCode==0">
			<div class="container">
			<div class="row">
				<div class="col-12 text-center">
					<h5 style="padding: 50px 0;">Program je prázdny</h5>
				</div>
			</div>
			</div>
		</section>
	
</template>


