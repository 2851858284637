<script>

//import { library } from '@fortawesome/fontawesome-svg-core';
//import { faPen, faArrowAltCircleRight, faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment } from '@fortawesome/free-solid-svg-icons';
//library.add(faPen,faArrowAltCircleRight,faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment)
//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import $ from 'jquery'; 

//import videojs from 'video.js';
//import 'video.js/dist/video-js.css';
//import sk from 'video.js/dist/lang/sk.json';

//import { VideoPlayer } from 'vue-video-player'

//general functions
import MixinGeneralFunct from '../mixins/GeneralFunctions';

import axios from 'axios';

export default {
	name: 'Documents',
	mixins: [ MixinGeneralFunct ],
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
	},
	props: ["myUserReg","myEventReg","myEventCounts"],
	data() {
		return {
			prefix:'',
						
			accData: [],
			secData: [],
			auth: [],
			menuItems: [],
			cookiesTime: 259200,
			
			eventIsRegistered:false,
			eventRegCode:'',
			selectedAccessCode:'',
			
			loadInterval:'',
			
			isLogged:true,
			
			question:[],
			questionEdit:[],
			myEvent:[],
			myUser:[],
			countCharQuestion:250,
			countCharQuestionEdit:250,
			countCharQuestionLimit:250,
			
			streamData: [],
			
			streamId:'',
			
			helpboxData: [],
			
			questionsCount:0,
			questions:[],
			sysQuestions:[],
			
			timeToRefresh:10000,
			
			firstLoading:true,
			tmpQuestions:[],
	
			countPages:0,
			selectedPage:null,
			selectedRecords:null,
			limit_from:0,
			limit_to:0,
			
			isSelectedQuestion:false,
			curSelectedQuestionID:'',
			
			selectedCol:'created',
			selectedSort:'desc',
		
			filterExtended:true,
			showFilter:false,
			
			options: {
				type: Object,
				default() {
					return {};
				}
			}
			
		}
	},
	
	mounted:function(){
		
				
		//this.loadDefaultQuestion();
		//if(this.eventIsRegistered){
			
			this.loadDocuments();
			
			//self.loadQuestions();
			//			self.loadLiveStreams();
			//			self.resetLoadingInterval();
			
		//}	
		
	},
	
	methods: {
		
			
		
		//natiahnutie streamu
		loadDocuments:function(){
						
			var self = this;
			axios.get('/app/includeData/Documents/getDocumentsData.php',{
				params:{
					id:this.streamId,
				}
			})
			.then(function (response) {
				//console.log("loadLDocs");
				//console.log(response.data);
				
				//hide loader
				$('#preloader').fadeOut(200);
				
				self.streamData = response.data.streamData;
				
								
				/*if(self.streamData.countRecords>0){
					for(var i=0;i<self.recPlayer.length;i++){
						setTimeout(self.runRecord, 300,self.streamData.arrRecords[i]);
					}
				}
				
				for(var i=0;i<self.recPlayer.length;i++){
					setTimeout(self.runRecord, 300,self.streamData.arrRecords[i]);
				}
				.dispose()*/
				
				
			})
				.catch(function (error) {
				console.log(error);
			});
		},
		
		
		
		
		//messagebox v1.1
		createMessageBoxNoTrans: function(kind,object,message) {
			var content = "";
			content += "<div class='alert alert-"+kind+"' role='alert'>";
			content += message;
			content += "</div>";
			
			var rand = this.getRandomInt(600000);
			$('#msg').append("<div class='msg"+rand+"'></div>");
			$('.msg'+rand).html("").append(content).fadeIn(100).delay(6000).fadeOut(300);
		},
		
		//messagebox v1.1
		createMessageBoxTrans: function(kind,object,message) {
			var content = "";
			content += "<div class='alert alert-"+kind+"' role='alert'>";
			content += this.$t('message.'+message);
			content += "</div>";
			
			var rand = this.getRandomInt(600000);
			$('#msg').append("<div class='msg"+rand+"'></div>");
			$('.msg'+rand).html("").append(content).fadeIn(100).delay(6000).fadeOut(300);
		},
			
	},	
	
			
	watch: {
		myEventReg() {
			this.loadDocuments();
		}
	},

	computed:{
		
	},
	
}






</script>

<template>
	
	<div id="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
	
	<div id="msg"></div>
	<div id="msgForm"></div>

	
		
	<section v-if="streamData.resCode==1" id="documents">
	
		<div class="container">
			<div class="row">

				<div class="col-md-12 col-12">
					<h3 class="text-left">{{ $t('message.ConferenceMaterials') }}</h3>
				</div>
				
				<div class="col-md-12 col-12 table-responsive">
				
					<table class="table table-sm">
													
						<thead>
							<tr>
								<th width="100">{{ $t('message.PorCislo') }}</th>
								<th>{{ $t('message.Name') }}</th>
								<th width="150">{{ $t('message.TypSuboru') }}</th>
								<th width="100">{{ $t('message.Size') }}</th>
							</tr>
						</thead>

						<tr v-for="item in streamData.arrDocs" v-bind:key="item">
							<td><span v-if="item.porCislo!=0">{{item.porCislo}}</span></td>
							<td><a :href="item.fileUrl">{{item.title}}</a></td>
							<td>{{item.extension}}</td>
							<td>{{item.size}}</td>
						</tr>

					</table>

				</div>
			</div>
			
			<div class="row">
				<div class="col-md-12 col-12 text-center">
					<p>{{ $t('message.MaterialAutorstvo') }}</p>
				</div>
			</div>
			
		</div>
		
	</section>	
	
	
	<section v-else-if="streamData.resCode==2">
			<div class="row">
				<div class="col-12 text-center">
					<h5 style="padding: 50px 0;">Pre náhľad do materiálov ku konferencií musíte byť prihlásený</h5>
				</div>
			</div>
		
	</section>
		
	<section v-else-if="streamData.resCode==3">
			<div class="row">
				<div class="col-12 text-center">
					<h5 style="padding: 50px 0;">Chýbajú vstupné údaje</h5>
				</div>
			</div>
		
	</section>
	
		
	<section v-else-if="streamData.resCode==0">
			<div class="row">
				<div class="col-12 text-center">
					<h5 style="padding: 50px 0;">Materiály konferencie neexistujú</h5>
				</div>
			</div>
		
	</section>
		
	
	
	
	
	
</template>


