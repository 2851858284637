<script>

//import { library } from '@fortawesome/fontawesome-svg-core';
//import { faPen, faArrowAltCircleRight, faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment } from '@fortawesome/free-solid-svg-icons';
//library.add(faPen,faArrowAltCircleRight,faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment)
//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import $ from 'jquery'; 


//import { VideoPlayer } from 'vue-video-player'

//general functions
import MixinGeneralFunct from '../mixins/GeneralFunctions';

import axios from 'axios';

export default {
	name: 'Uvod',
	mixins: [ MixinGeneralFunct ],
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
	},
	props: ["myUserReg","myEventReg","myEventCounts"],
	data() {
		return {
			event:[],
		}
	},
	
	mounted:function(){
		
		this.loadDefaultValues();
		
	},
	
	methods: {
		
		//na fontende aj preview aj tabula, zober povodne idcka a idcka po aktualizacii a tie naviac zobraz na par sekund inou farbou, alebo ako nove.
		
		//default data
		
		loadDefaultValues:function(){
			var self = this;
			axios.get('/app/includeData/Uvod/getDefaultData.php')
			.then(function (response) {
				//console.log("loadDefData");
				//console.log(response.data);
				
				//hide loader
				$('#preloader').fadeOut(200);
				
				
				self.event = response.data.event;
				
			})
				.catch(function (error) {
				console.log(error);
			});
		}	
		
	},	
	
			
	watch: {
		myEventReg() {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
}

</script>

<template>
	
	<div id="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
	
		<div>
			<section id="intro" class="box-flex">
				<div class="container-fluid">
					
					<div v-if="!event.isReg" class="row">
						<div class="col-12 col-md-8 offset-md-2 box-flex text-center">
						
							<h4>{{ $t('message.WelcomeInExpoPP') }}<br><span>{{ $t('message.byCompanyPP') }}</span><br><br></h4>
						</div>
					</div>
					
					<div  v-if="event.isReg" class="row">
					
						
							
								<div v-if="myEventCounts.streams>0" class="col-6 col-md-2 uvodBox content box-flex">
									<router-link to="/streams"><div class="uvodBox-content box-flex text-center"><span class="text">{{ $t('message.LiveStream') }}</span></div></router-link>
								</div>
								<div v-else class="col-6 col-md-2 uvodBox empty box-flex">
									<div class="uvodBox-empty box-flex text-center"><span class="text">{{ $t('message.LiveStream') }}</span></div>
								</div>
								
								<div v-if="myEventCounts.documents>0" class="col-6 col-md-2 uvodBox content box-flex">
									<router-link to="/documents"><div class="uvodBox-content box-flex text-center"><span class="text">{{ $t('message.Documents') }}</span></div></router-link>
								</div>
								<div v-else class="col-6 col-md-2 uvodBox empty box-flex">
									<div class="uvodBox-empty box-flex text-center"><span class="text">{{ $t('message.Documents') }}</span></div>
								</div>
								
								<div v-if="myEventCounts.expo>0" class="col-6 col-md-2 uvodBox content box-flex">
									<router-link to="/exhibitors"><div class="uvodBox-content box-flex text-center"><span class="text">{{ $t('message.Expo') }}</span></div></router-link>
								</div>
								<div v-else class="col-6 col-md-2 uvodBox empty box-flex">
									<div class="uvodBox-empty box-flex text-center"><span class="text">{{ $t('message.Expo') }}</span></div>
								</div>
								
								<div v-if="myEventCounts.program>0" class="col-6 col-md-2 uvodBox content box-flex">
									<router-link to="/program"><div class="uvodBox-content box-flex text-center"><span class="text">{{ $t('message.Program') }}</span></div></router-link>
								</div>
								<div v-else class="col-6 col-md-2 uvodBox empty box-flex">
									<div class="uvodBox-empty box-flex text-center"><span class="text">{{ $t('message.Program') }}</span></div>
								</div>
								
								<div v-if="myEventCounts.speakers>0" class="col-6 col-md-2 uvodBox content box-flex">
									<router-link to="/speakers"><div class="uvodBox-content box-flex text-center"><span class="text">{{ $t('message.Speakers') }}</span></div></router-link>
								</div>
								<div v-else class="col-6 col-md-2 uvodBox empty box-flex">
									<div class="uvodBox-empty box-flex text-center"><span class="text">{{ $t('message.Speakers') }}</span></div>
								</div>
								
								<div v-if="myEventCounts.records>0" class="col-6 col-md-2 uvodBox content box-flex">
									<router-link to="/records"><div class="uvodBox-content box-flex text-center"><span class="text">{{ $t('message.Records') }}</span></div></router-link>
								</div>
								<div v-else class="col-6 col-md-2 uvodBox empty box-flex">
									<div class="uvodBox-empty box-flex text-center"><span class="text">{{ $t('message.Records') }}</span></div>
								</div>
						
							
						
					</div>
					
				</div>
			</section>
		</div>

</template>


